
export default {
  GET_ALL_NOTIFICATIONS: state => state.notifications,
  GET_LAST_NOTIFICATION: state => state.notifications && state.notifications.length > 0 ? state.notifications[0] : null,
  IS_REQUEST_LOADING: state => state.loading_request > 0,
  IS_BLOCKING_REQUEST_LOADING: state => state.loading_blocking_request > 0,
  GET_WELCOME_DIALOG: state => state.dialog_welcome,
  GET_CGU_DIALOG: state => state.dialog_cgu,
  GET_PRIVACY_DIALOG: state => state.dialog_privacy,
  IS_MOBILE: () => (process.env.VUE_APP_WEB !== 1 && process.env.VUE_APP_WEB !== '1') || (window.device && window.device.platform !== 'browser'),
  IS_APPLE: () => ((process.env.VUE_APP_WEB !== 1 && process.env.VUE_APP_WEB !== '1') || (window.device && window.device.platform !== 'browser')) && ((process.env.VUE_APP_ANDROID !== 1 && process.env.VUE_APP_ANDROID !== '1') || (window.device && window.device.platform === 'iOS'))
}
