<template>
  <div class="vlove-chat">
    <v-container>
      <v-row>
        <v-pagination v-if="CURRENT_CHAT_MESSAGES_PAGINATION"
          v-model="current_page_message"
          :length="parseInt(CURRENT_CHAT_MESSAGES_PAGINATION.total)"
          :total-visible="Math.min(CURRENT_CHAT_MESSAGES_PAGINATION.total , 3)"
          circle
        ></v-pagination>
      </v-row>
      <v-row>
        <v-col>
          <q-chat-message v-for="(message, index) in CURRENT_CHAT_MESSAGES" :key="index"
            :name="message.redactor && message.redactor.username === username ? 'me' : message.redactor.username"
            :avatar="(message.redactor.username !== username ? message.redactor.medias : U_MEDIAS) | filterProfilePic(message.redactor.username !== username ? message.redactor.gender :U_GENDER)"
            :text="[message.body]"
            :sent="message.redactor && message.redactor.username === username"
            :stamp="message.date_create | formatDateMessage"
            :bg-color="(message.redactor.username !== username ? 'secondary' : '#2e2e2e')"
            :text-color="(message.redactor.username !== username ? 'white' : 'black')"
          />
          <q-chat-message
            v-if="CURRENT_CHAT && CURRENT_CHAT_IS_WRITING && CURRENT_CHAT.user_init_chat && CURRENT_CHAT.user_respond_chat"
            :name="CURRENT_CHAT && CURRENT_CHAT.user_init_chat && CURRENT_CHAT.user_init_chat.username === username ? CURRENT_CHAT.user_respond_chat.username : CURRENT_CHAT.user_init_chat.username"
            :avatar="(CURRENT_CHAT && CURRENT_CHAT.user_init_chat && CURRENT_CHAT.user_init_chat.username === username ? CURRENT_CHAT.user_respond_chat.medias : CURRENT_CHAT.user_init_chat.medias) | filterProfilePic(CURRENT_CHAT && CURRENT_CHAT.user_init_chat && CURRENT_CHAT.user_init_chat.username === username ? CURRENT_CHAT.user_respond_chat.gender : CURRENT_CHAT.user_init_chat.gender)"
            bg-color="primary"
            text-color="white"
          >
            <q-spinner-dots size="2rem" />
          </q-chat-message>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            id="text-chat-send"
            ref="textchatsend"
            v-model="message_to_send"
            auto-grow
            color="deep-purple"
            label="Message"
            rows="1"
            v-on:keyup.enter.exact="sendMessage"
            v-on:click:append-outer="sendMessage"
            v-on:keydown="onInput"
            append-outer-icon="send"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <vlove-report v-if="CURRENT_CHAT" type="chat" :typeId="CURRENT_CHAT.id"></vlove-report>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vuex from 'vuex'
import moment from 'moment'
import VLoveReport from '@/components/partial/Report'

export default {
  name: 'vlove-chat',
  components: {
    'vlove-report': VLoveReport
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'U_MEDIAS',
      'U_GENDER',
      'CURRENT_CHAT_MESSAGES',
      'CURRENT_CHAT_MESSAGES_PAGINATION',
      'CURRENT_MESSAGES_ARE_LOADING',
      'VISITING_U_USERNAME',
      'CURRENT_CHAT_IS_WRITING',
      'CURRENT_CHAT'
    ])
  },
  data () {
    return {
      disabled_reload: false,
      is_writing: false,
      message_to_send: '',
      current_page_message: 1
    }
  },
  methods: {
    sendMessage () {
      if (this.message_to_send.trim().length > 0) {
        var payload = {
          body: this.message_to_send
        }
        this.$store.dispatch('SEND_MESSAGE', payload)
        this.message_to_send = ''
        this.current_page_message = 1
      }
      this.is_writing = false
    },
    reloadCurrentMessage () {
      this.$store.dispatch('RELOAD_CURRENT_CHAT_MESSAGES', {'page': this.current_page_message})
    },
    loopLoadCurrentMessage () {
      this.reloadCurrentMessage()
      setTimeout(() => {
        this.loopLoadCurrentMessage()
      }, 5000);
    },
    onInput () {
      if (this.is_writing) {
        return
      }
      this.$store.dispatch('WRITING_MESSAGE')
      this.is_writing = true
    },
    onScroll () {
      console.log('test')
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    formatDateMessage: function (_date) {
      let date = moment(_date, 'YYYY-MM-DD[T]HH:mm:ssZ')
      if (date.isBefore(moment().subtract(1, 'days'))) {
        return date.format('LLL')
      }
      return date.fromNow()
    }
  },
  watch: {
    CURRENT_CHAT_MESSAGES_PAGINATION: function (newVal) {
      if (newVal && newVal.page !== this.current_page_message) {
        this.current_page_message = newVal.page
      }
    },
    current_page_message: function () {
        this.reloadCurrentMessage ()
    }
  },
  mounted: function () {
    this.disabled_reload = true
    this.$vuetify.goTo(this.$refs.textchatsend, {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    })
    this.loopLoadCurrentMessage()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pre-formatted {
  white-space: pre;
}
</style>
