import Vue from 'vue'
// import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './quasar'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueLazyload from 'vue-lazyload';

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  lazyComponent: true
});

router.beforeEach((to, from, next) => {
  // console.log('switch page ', to)
  if (to.meta.Auth && to.path === '/logout') {
    store.dispatch('USER_LOGOUT')
    next({ path: '/login' })
    return
  }
  if ((to.path === '/register' || to.path === '/login' || to.path === '/') && store.getters.IS_LOGGED_IN) {
    if (!store.getters.USER_HAS_PAYED) {
      const queriesParams = to.query
      next({ path: '/complete', query: queriesParams })
      return
    } else {
      if (to.query.redirect) {
        const redirectPath = to.query.redirect
        const queriesParams = to.query
        delete queriesParams.redirect
        next({ path: redirectPath, query: queriesParams })
        return
      }
      next({ path: '/matching' })
      return
    }
  }
  if (!to.meta.Auth) {
    next()
    return
  }
  if (!store.getters.IS_LOGGED_IN) {
    const queriesRedirect = to.query
    queriesRedirect.redirect = to.path
    next({ path: '/login', query: queriesRedirect })
  } else {
    if (!store.getters.USER_HAS_PAYED && to.path !== '/complete') {
      const queriesRedirect = to.query
      next({ path: '/complete', query: queriesRedirect })
    } else {
      next()
    }
  }
})

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
