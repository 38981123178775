<template>
  <div class="profil">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="3" >
          <v-avatar
            center
            :size="200"
            tile
            aspect-ratio="1"
            color="grey lighten-4"
            class="myprofile-avatar"
          >
            <v-img cover :src="U_MEDIAS | filterProfilePic(U_GENDER)" alt="avatar" class="d-flex justify-center"></v-img>
            <v-btn
              absolute
              dark
              fab
              bottom
              right
              small
              color="grey white--text text--lighten-1"
              v-on:click.stop="picdialog = true"
            >
              <v-icon>mdi-image-edit</v-icon>
            </v-btn>
          </v-avatar>
        </v-col>
        <v-col cols="12" md="9">
          <v-card>
            <v-card-title><h1 class="display-2">{{ username }}</h1></v-card-title>
            <v-card-subtitle> <v-btn icon small color="grey--text text--lighten-1" v-on:click.stop="openSetInfo('mood', U_MOOD)"><v-icon>edit</v-icon></v-btn>
                <em>{{ $t('views.myprofile.label_mood') }} 
                  <span v-if="U_MOOD">{{ U_MOOD }}</span><span v-else>{{ $t('views.myprofile.placeholder_mood') }}</span>
                </em></v-card-subtitle>
            <v-card-text>
              <p>
                <v-btn icon small color="grey--text text--lighten-1" v-on:click.stop="openSetInfo('wine_professionnal', U_IS_A_WINE_PROFESSIONNAL)"><v-icon>edit</v-icon></v-btn>
                <em v-if="U_IS_A_WINE_PROFESSIONNAL">{{ $t('views.myprofile.label_wineworker_ok') }}</em><em v-else>{{ $t('views.myprofile.label_wineworker_nok') }}</em>
              </p>
              <p>
                <v-btn icon small color="grey--text text--lighten-1" v-on:click.stop="openSetInfo('relations', {'love_relations': U_IS_LOOKING_FOR_LOVE_RELATIONS, 'professionnal_relations': U_IS_LOOKING_FOR_PRO_RELATIONS, 'friendship_relations': U_IS_LOOKING_FOR_FRIEND_RELATIONS})"><v-icon>edit</v-icon></v-btn>
                <em v-if="U_IS_LOOKING_FOR_LOVE_RELATIONS">{{ $t('user.relations.love') }}<span v-if="U_IS_LOOKING_FOR_PRO_RELATIONS || U_IS_LOOKING_FOR_FRIEND_RELATIONS">, </span></em>
                <em v-if="U_IS_LOOKING_FOR_PRO_RELATIONS">{{ $t('user.relations.professionnal') }}<span v-if="U_IS_LOOKING_FOR_FRIEND_RELATIONS">, </span></em>
                <em v-if="U_IS_LOOKING_FOR_FRIEND_RELATIONS">{{ $t('user.relations.friendship') }}</em>
                <em v-if="!U_IS_LOOKING_FOR_LOVE_RELATIONS && !U_IS_LOOKING_FOR_PRO_RELATIONS && !U_IS_LOOKING_FOR_FRIEND_RELATIONS">{{ $t('user.relations.unknown') }}</em>
              </p>
              <p><v-btn icon small color="grey--text text--lighten-1" v-on:click.stop="openSetInfo('description', U_DESCRIPTION)"><v-icon>edit</v-icon></v-btn>
                <em>{{ U_DESCRIPTION }}</em>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row
      >
        <v-col cols="12" md="4" class="text-center">
          <v-btn tile outlined color="primary" block @click="dialog_wall = true">{{ $t('views.myprofile.tab_title_0') }}
            <v-icon
              right
              dark
            >
              mdi-information-variant
            </v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <v-btn tile outlined color="primary" block @click="dialog_photos = true">{{ $t('views.myprofile.tab_title_1') }}
            <v-icon
              right
              dark
            >
              mdi-image
            </v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <v-btn tile outlined color="primary" block @click="dialog_friends = true">{{ $t('views.myprofile.tab_title_2') }}
            <v-icon
              right
              dark
            >
              mdi-account-heart
            </v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-center">
          <v-btn  tile outlined color="primary" block @click="dialog_fans = true">{{ $t('views.myprofile.tab_title_3') }}
            <v-icon
              right
              dark
            >
              mdi-glass-wine
            </v-icon></v-btn>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <v-btn  tile outlined color="primary" block @click="dialog_viewedme = true">{{ $t('views.myprofile.tab_title_4') }}
            <v-icon
              right
              dark
            >
              mdi-eye
            </v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" >
        <v-list>
          <v-list-item v-on:click.stop="openSetInfo('gender', U_GENDER)">
            <v-list-item-action>
                <v-icon v-if="U_GENDER === 'woman'">fa-female</v-icon>
                <v-icon v-else-if="U_GENDER === 'man'">fa-male</v-icon>
                <v-icon v-else-if="U_GENDER === 'both'">fa-transgender</v-icon>
                <v-icon v-else>fa-question</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('user.genders.' + U_GENDER) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('lookingfor', U_LOOKING_FOR)">
            <v-list-item-action>
                <v-icon v-if="U_LOOKING_FOR === 'woman'">fa-female</v-icon>
                <v-icon v-else-if="U_LOOKING_FOR === 'man'">fa-male</v-icon>
                <v-icon v-else-if="U_LOOKING_FOR === 'both'">fa-transgender</v-icon>
                <v-icon v-else>fa-question</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('user.looking_for_genders.' + U_LOOKING_FOR) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('birthday', U_BIRTHDAY)">
            <v-list-item-action>
              <v-icon>fa-birthday-cake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ U_OLD_AGE }}</v-list-item-title>
              <v-list-item-subtitle>{{ $t('views.myprofile.label_age') }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('size', U_SIZE)">
            <v-list-item-action>
              <v-icon>fa-arrows-alt-v</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="U_SIZE && !isNaN(parseInt(U_SIZE)) && parseInt(U_SIZE) > 0">{{ U_SIZE }} </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle>cm</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('weight', U_WEIGHT)">
            <v-list-item-action>
              <v-icon>fa-weight</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="U_WEIGHT && !isNaN(parseInt(U_WEIGHT)) && parseInt(U_WEIGHT) > 0">{{ U_WEIGHT }} </v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
              <v-list-item-subtitle>Kg</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('haircolor', U_HAIR_COLOR)">
            <v-list-item-action>
              <v-icon>fa-cut</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user.haircolors.' + (U_HAIR_COLOR ? U_HAIR_COLOR : 'none')) }} </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('eyescolors', U_EYES_COLOR)">
            <v-list-item-action>
              <v-icon>fa-eye</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user.eyescolors.' + (U_EYES_COLOR ? U_EYES_COLOR : 'none')) }} </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="6" >
        <v-list>
          <v-list-item v-on:click.stop="openSetInfo('silhouette', U_SILHOUETTE)">
            <v-list-item-action>
              <v-icon>fa-running</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user.silhouettes.' + (U_SILHOUETTE ? U_SILHOUETTE : 'none')) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('style', U_STYLE)">
            <v-list-item-action>
              <v-icon>fa-tshirt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t('user.styles.' + (U_STYLE ? U_STYLE: 'none')) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('city', U_CITY)">
            <v-list-item-action>
              <v-icon>fa-map-marker-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="U_CITY || U_COUNTRY">{{ U_CITY }} <span class="text-uppercase" v-if="U_COUNTRY">({{ U_COUNTRY }})</span></v-list-item-title>
              <v-list-item-title v-else>-</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('hobbies', U_HOBBIES)">
            <v-list-item-action>
              <v-icon>fa-grin-hearts</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><span v-for="(value, index) in U_HOBBIES" :key="index"><span v-if="index > 0">, </span>{{ $t('user.hobbies.' + value) }}</span></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('winecolors', U_WINE_COLORS)">
            <v-list-item-action>
              <v-avatar :size="38"><img style="width: 70%;" src="@/assets/wine.svg" /></v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><span v-for="(value, index) in U_WINE_COLORS" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winecolors.' + value) }}</span></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('wineregions', U_WINE_REGIONS)">
            <v-list-item-action>
              <v-avatar :size="38"><img style="width: 70%;" src="@/assets/pin.svg" /></v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><span v-for="(value, index) in U_WINE_REGIONS" :key="index"><span v-if="index > 0">, </span>{{ $t('user.wineregions.' + value) }}</span></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-on:click.stop="openSetInfo('winevarieties', U_WINE_VARIETIES)">
            <v-list-item-action>
              <v-avatar :size="38"><img style="width: 70%;" src="@/assets/grapes.svg" /></v-avatar>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title><span v-for="(value, index) in U_WINE_VARIETIES" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winevarieties.' + value) }}</span></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action >
              <v-icon small color="grey--text text--lighten-1">edit</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-layout row justify-center>
      <!-- Info edition Dialog -->
      <v-dialog v-model="edit" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline" v-if="edit_type">{{ $t('views.myprofile.title_edit_info_' + edit_type) }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12>
                  <v-date-picker v-if="edit && ['birthday'].indexOf(edit_type) !== -1" v-model="newval" 
                    :locale="$i18n.locale"
                    :max="maxdate"
                    min="1900-01-01"
                  :disabled="edit_loading" ref="myprofilepicker"></v-date-picker>
                  <v-autocomplete
                    v-else-if="['city'].indexOf(edit_type) !== -1"
                    v-model="newval"
                    :items="cities"
                    :loading="isLoadingCities"
                    :search-input.sync="searchCities"
                    auto-select-first
                    outlined
                    clearable
                    hide-selected
                    color="white"
                    :label="$t('views.myprofile.label_location')"
                    :placeholder="$t('views.myprofile.placeholder_location')"
                    :hint="$t('views.myprofile.hint_location')"
                    :filter="v => v"
                    autocomplete="none"
                    Xautocomplete="off"
                    xbrowser-autocomplete="none"
                  ></v-autocomplete>
                  <v-textarea v-else-if="['description'].indexOf(edit_type) !== -1" v-model="newval" :disabled="edit_loading" :loading="edit_loading" :errors="errors"></v-textarea>
                  <v-select v-else-if="['style', 'silhouette', 'gender', 'lookingfor', 'eyescolors', 'haircolor'].indexOf(edit_type) !== -1" v-model="newval" :items="getItems()" :label="edit_type" :error-messages="errors.style" solo ></v-select>
                  <v-autocomplete v-else-if="['hobbies', 'winecolors', 'wineregions', 'winevarieties'].indexOf(edit_type) !== -1" v-model="newval" :items="getItems()" multiple :label="edit_type" :error-messages="errors.style" solo chips ></v-autocomplete>
                  <v-switch v-else-if="['wine_professionnal'].indexOf(edit_type) !== -1"
                    :label="newval ? $t('views.myprofile.label_wineworker_ok') : $t('views.myprofile.label_wineworker_nok')"
                    v-model="newval"
                  ></v-switch>
                  <div v-else-if="['relations'].indexOf(edit_type) !== -1">
                    <p>{{ $t('views.myprofile.title_edit_info_relations') }}</p>
                    <v-checkbox v-model="newval['love_relations']" :label="$t('user.relations.love')"></v-checkbox>
                    <v-checkbox v-model="newval['professionnal_relations']" :label="$t('user.relations.professionnal')"></v-checkbox>
                    <v-checkbox v-model="newval['friendship_relations']" :label="$t('user.relations.friendship')"></v-checkbox>
                  </div>
                  <v-text-field v-else v-model="newval" :disabled="edit_loading" :loading="edit_loading" :errors="errors"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>
              {{ $t('views.myprofile.tooltip_required') }}
            </small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="cancelSetInfo()">{{ $t('views.myprofile.label_edit_cancel') }}</v-btn>
            <v-btn color="#bda273" text @click="setInfo()">{{ $t('views.myprofile.label_edit_close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="picdialog"
        max-width="500px"
        scrollable
      >
        <v-card>
          <div v-if="!this.isPictureWithCamera">
            <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
            <v-card-text>
              <v-file-input accept="image/*" @click:clear="removeImage" prepend-icon="mdi-camera" name="mypicture" show-size v-model="fileinput" :rules="rules"></v-file-input>
            </v-card-text>
          </div>
          <div v-else>
            <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
            <v-card-text>
              <p><v-file-input v-if="!isMobileWithGallery" prepend-icon="mdi-camera" @click:clear="removeImage" accept="image/*" show-size name="mypicture" @change="onFileChange" :rules="rules"></v-file-input></p>
              <p><v-btn v-on:click="getPictureFromCamera" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_camera') }}</v-btn></p>
              <p><v-btn v-if="isMobileWithGallery" v-on:click="getPictureFromGallery" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_gallery') }}</v-btn></p>
            </v-card-text>
          </div>
          <div v-if="newProfilePicture">
            <v-img :src="newProfilePicture" height="125px" contain></v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <button @click="removeImage"><v-icon>delete_forever</v-icon></button>
            </v-card-actions>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text v-on:click.native="picdialog = !picdialog">{{ $t('views.myprofile.label_edit_cancel') }}</v-btn>
            <v-btn color="#bda273" :disabled="!newProfilePicture" text @click="savePicture()">{{ $t('views.myprofile.label_edit_close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

  </v-container>

    <v-dialog v-model="dialog_wall" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_wall = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('views.myprofile.tab_title_0') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-wall></vlove-wall>
      </v-card>
  </v-dialog>

    <v-dialog v-model="dialog_photos" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_photos = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('views.myprofile.tab_title_1') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-photos :pictures="U_MEDIAS" :isMine="true"></vlove-photos>
      </v-card>
  </v-dialog>

    <v-dialog v-model="dialog_friends" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_friends = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('views.myprofile.tab_title_2') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-friends></vlove-friends>
      </v-card>
  </v-dialog>

    <v-dialog v-model="dialog_fans" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_fans = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('views.myprofile.tab_title_3') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-fans></vlove-fans>
      </v-card>
  </v-dialog>

  <v-dialog v-model="dialog_viewedme" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.native="dialog_viewedme = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('views.myprofile.tab_title_2') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <vlove-viewed-me ></vlove-viewed-me>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>
import moment from 'moment'
import api from '@/api/api'
import VLoveFriends from '@/components/partial/Friends'
import VLovePhotos from '@/components/partial/Photos'
import VLoveWall from '@/components/partial/Wall'
import VLoveFans from '@/components/partial/Fans'
import ViewedMe from '@/components/partial/ViewedMe'
import Vuex from 'vuex'
import genders from '@/const/genders'
import lookinggenders from '@/const/lookinggenders'
import styles from '@/const/styles'
import silhouettes from '@/const/silhouettes'
import hobbies from '@/const/hobbies'
import eyescolors from '@/const/eyescolors'
import haircolors from '@/const/haircolors'
import winecolors from '@/const/winecolors'
import wineregions from '@/const/wineregions'
import winevarieties from '@/const/winevarieties'

export default {
  name: 'myprofile',
  components: {
    'vlove-wall': VLoveWall,
    'vlove-photos': VLovePhotos,
    'vlove-friends': VLoveFriends,
    'vlove-fans': VLoveFans,
    'vlove-viewed-me': ViewedMe
  },
  computed: {
    ...Vuex.mapGetters([
      'IS_LOGGED_IN',
      'username',
      'U_GENDER',
      'U_LOOKING_FOR',
      'U_OLD_AGE',
      'U_SIZE',
      'U_WEIGHT',
      'U_SILHOUETTE',
      'U_STYLE',
      'U_HOBBIES',
      'U_CITY',
      'U_COUNTRY',
      'U_MOOD',
      'U_DESCRIPTION',
      'U_MEDIAS',
      'U_BIRTHDAY',
      'U_IS_A_WINE_PROFESSIONNAL',
      'U_IS_LOOKING_FOR_LOVE_RELATIONS',
      'U_IS_LOOKING_FOR_PRO_RELATIONS',
      'U_IS_LOOKING_FOR_FRIEND_RELATIONS',
      'U_EYES_COLOR',
      'U_HAIR_COLOR',
      'U_WINE_COLORS',
      'U_WINE_REGIONS',
      'U_WINE_VARIETIES',
      'token'
    ])
  },
  data () {
    return {
      tab: null,
      cheers: false,
      edit: false,
      edit_loading: false,
      edit_type: '',
      newval: '',
      errors: [],
      genders: Object.keys(genders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': genders.getValues(this.$root.$i18n)[val] } }),
      lookinggenders: Object.keys(lookinggenders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': lookinggenders.getValues(this.$root.$i18n)[val] } }),
      silhouettes: Object.keys(silhouettes.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': silhouettes.getValues(this.$root.$i18n)[val] } }),
      styles: Object.keys(styles.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': styles.getValues(this.$root.$i18n)[val] } }),
      available_hobbies: Object.keys(hobbies.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': hobbies.getValues(this.$root.$i18n)[val] } }),
      available_eyes_colors: Object.keys(eyescolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': eyescolors.getValues(this.$root.$i18n)[val] } }),
      available_hair_colors: Object.keys(haircolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': haircolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_regions: Object.keys(wineregions.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': wineregions.getValues(this.$root.$i18n)[val] } }),
      available_wine_colors: Object.keys(winecolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winecolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_varieties: Object.keys(winevarieties.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winevarieties.getValues(this.$root.$i18n)[val] } }),
      cities: [],
      countCities: 0,
      isLoadingCities: false,
      current_city: null,
      searchCities: null,
      newProfilePicture: null,
      preloadpic: false,
      picdialog: false,
      isPictureWithCamera: false,
      isMobileWithGallery: false,
      fileinput: null,
      maxdate: null,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      dialog_wall: false,
      dialog_photos: false,
      dialog_friends: false,
      dialog_fans: false,
      dialog_viewedme: false
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    filterOthersPic: function (_medias) {
      if (_medias && _medias.length > 0) {
        return _medias.filter((val) => !val.profile)
      }
      return []
    }
  },
  mounted: function () {
    this.maxdate = moment().subtract(18, 'years').format('YYYY-MM-DD')
    this.isPictureWithCamera = window.cordova && navigator && navigator.camera
    this.isMobileWithGallery = window.cordova && window.device && window.device.platform !== 'browser' && navigator && navigator.camera
  },
  methods: {
    updateSelectors () {
      this.genders = Object.keys(genders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': genders.getValues(this.$root.$i18n)[val] } })
      this.lookinggenders = Object.keys(lookinggenders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': lookinggenders.getValues(this.$root.$i18n)[val] } })
      this.silhouettes = Object.keys(silhouettes.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': silhouettes.getValues(this.$root.$i18n)[val] } })
      this.styles = Object.keys(styles.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': styles.getValues(this.$root.$i18n)[val] } })
      this.available_hobbies = Object.keys(hobbies.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': hobbies.getValues(this.$root.$i18n)[val] } })
      this.available_eyes_colors = Object.keys(eyescolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': eyescolors.getValues(this.$root.$i18n)[val] } })
      this.available_hair_colors = Object.keys(haircolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': haircolors.getValues(this.$root.$i18n)[val] } })
      this.available_wine_regions = Object.keys(wineregions.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': wineregions.getValues(this.$root.$i18n)[val] } })
      this.available_wine_colors = Object.keys(winecolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winecolors.getValues(this.$root.$i18n)[val] } })
      this.available_wine_varieties = Object.keys(winevarieties.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winevarieties.getValues(this.$root.$i18n)[val] } })
    },
    getItems () {
      switch (this.edit_type) {
        case 'style':
          return this.styles
        case 'hobbies':
          return this.available_hobbies
        case 'wineregions':
          return this.available_wine_regions
        case 'winecolors':
          return this.available_wine_colors
        case 'winevarieties':
          return this.available_wine_varieties
        case 'eyescolors':
          return this.available_eyes_colors
        case 'haircolor':
          return this.available_hair_colors
        case 'silhouette':
          return this.silhouettes
        case 'gender':
          return this.genders
        case 'lookingfor':
          return this.lookinggenders
      }
      return []
    },
    cancelSetInfo () {
      this.edit = false
      this.newval = ''
    },
    openSetInfo (curinfo, curval) {
      this.edit_type = curinfo
      if (['birthday'].indexOf(this.edit_type) !== -1) {
        this.newval = moment(curval).format('YYYY-MM-DD')
      } else {
        this.newval = curval
      }
      setTimeout(() => {
        this.edit = true
        if (['birthday'].indexOf(this.edit_type) !== -1) {
          setTimeout(() => {
            this.$refs.myprofilepicker.activePicker = 'YEAR'
          }, 100)
        }
      }, 0)
    },
    async setInfo () {
      this.edit_loading = true
      let payload = {}
      if (['size', 'weight'].indexOf(this.edit_type) !== -1) {
        if(isNaN(parseInt(this.newval))) {
          this.newval = 0
        }
        else if (parseInt(this.newval) < 0) {
          this.newval = parseInt(this.newval) * -1
        }
      }
      if (['relations'].indexOf(this.edit_type) !== -1) {
        payload['love_relations'] = this.newval ? this.newval.love_relations : undefined
        payload['professionnal_relations'] = this.newval ? this.newval.professionnal_relations : undefined
        payload['friendship_relations'] = this.newval ? this.newval.friendship_relations : undefined
      }
      else if (['city'].indexOf(this.edit_type) !== -1) {
        console.log('edit city', this.newval)
        payload['address'] = this.newval ? this.newval.formatted_address : undefined
        payload['postalCode'] = this.newval ? this.newval.postalCode : undefined
        payload['city'] = this.newval ? this.newval.city : undefined
        payload['country'] = this.newval ? this.newval.country : undefined
        payload['latitude'] = this.newval ? this.newval.lat : undefined
        payload['longitude'] = this.newval ? this.newval.lon : undefined
        console.log('edit city', payload)
      // } else if (0) {
      } else {
        payload[this.edit_type] = this.newval
        console.log(payload)
      }
      try {
        await this.$store.dispatch('USER_SET_INFORMATIONS', payload)
        this.edit = false
        this.edit_type = ''
      } catch (e) {
        if (e.data.errors && e.data.errors.children) {
          for (let key in e.data.errors.children) {
            if (e.data.errors.children[key] && e.data.errors.children[key].errors) {
              this.errors = e.data.errors.children[key].errors
            }
          }
        }
      }
      this.edit_loading = false
    },
    getFileContentAsBase64 (path, callback, callbackError) {
      window.resolveLocalFileSystemURL(path, function (fileEntry) {
        fileEntry.file(function (file) {
          var reader = new FileReader()
          reader.onloadend = function () {
            var content = this.result
            callback(content)
          }
          // The most important point, use the readAsDatURL Method from the file plugin
          reader.readAsDataURL(file)
        })
      }, function () {
        callbackError()
      })
    },
    getPictureFromCamera () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.CAMERA,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE,
          correctOrientation: true
        })
        /* eslint-enable */
      }
    },
    getPictureFromGallery () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture galery error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE
        })
        /* eslint-enable */
      }
    },
    onFileChange: function (e) {
      if (!e) {
        return
      }
      this.preloadpic = true
      var files = e.target ? e.target.files : e.dataTransfer ? e.dataTransfer.files : [e]
      if (!files.length) {
        this.preloadpic = false
        return
      }
      this.createImage(files[0])
    },
    createImage: function (file) {
      // var image = new Image()
      var reader = new FileReader()

      reader.onload = (e) => {
        this.newProfilePicture = e.target.result
      }
      reader.readAsDataURL(file)
      this.preloadpic = false
    },
    removeImage: function () {
      this.newProfilePicture = ''
      this.fileinput = null
    },
    savePicture: function () {
      if (this.newProfilePicture) {
        var payload = {}
        var refthis = this
        if (this.newProfilePicture.indexOf('data:image/') !== -1 && this.newProfilePicture.indexOf(';base64,') !== -1) {
          // Si l'image est en base64 on envoie direct
          payload.picture = this.newProfilePicture
          this.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
            refthis.picdialog = false
            refthis.newProfilePicture = null
            refthis.fileinput = null
          })
        } else if (this.newProfilePicture.indexOf('http://') === -1 && this.announcepicture.indexOf('https://') === -1) {
          // Si l'image est une url interne on converti en base64 et on envoie
          this.getFileContentAsBase64(this.newProfilePicture, function (datafile) {
            payload.picture = datafile
            refthis.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
              refthis.picdialog = false
              refthis.newProfilePicture = null
              refthis.fileinput = null
            })
          }, function () {
            // TODO display an internal error
          })
        }
        else {
          // l'image est une url http,
          // do nothing
        }
      }
    }
  },
  watch: {
    "$i18n.locale": function (val, oldVal) {
      if (val !== oldVal) {
        this.updateSelectors()
      }
    },
    searchCities: function (newval) {
      if (!(newval && (this.current_city === null || newval.length <= 0 || newval !== this.current_city.formatted_address))) {
        return
      }
      this.isLoadingCities = true
      api.get_request({}, '/api/geocode', this.token, {'term': newval})
        .then(res => {
          var arr = []
          for (let i = 0; i < res.length; i++) {
            arr.push({
              'text': res[i]['formatted_address'],
              'value': res[i]
            })
          }
          this.countCities = res.length
          this.cities = arr
        })
        .catch(() => {
          // console.log(err)
        })
        .finally(() => (this.isLoadingCities = false))
    },
    fileinput: function (newval) {
      this.onFileChange(newval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.myprofile-avatar {
  overflow: visible;
}

div.cordova-camera-capture[style] {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    text-align: center;
}

.cordova-camera-capture video {
    margin-top: 10vh;
}

.cordova-camera-capture button {
  color: #fff;
  background-color: #b7214e !important;
  border-color: #b7214e !important;
  height: 44px;
  min-width: 78px;
  padding: 0 19.5555555556px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
