export default {
  getValues (_translator) {
    return {
      'woman': _translator.t('user.looking_for_genders.woman'),
      'man': _translator.t('user.looking_for_genders.man'),
      'both': _translator.t('user.looking_for_genders.both')
      // ,
      // 'unknown': _translator.t('user.looking_for_genders.unknown')
    }
  }
}
