<template>
  <div class="vlove-pic-user-sheet" style="margin-top: 20px; height: 80vh; width: 100%;">
    <vlove-picture
      height="80vh"
      contain
      v-if="picture"
      :src="picture"
      :aspect-ratio="1"
    ></vlove-picture>
  </div>
</template>

<script>
import VLovePicture from '@/components/partial/Picture'


export default {
  name: 'vlove-pic-user-sheet',
  components:{
    'vlove-picture': VLovePicture
  },
  props: {
    picture: String
  },
  data () {
    return {
    }
  },
  mounted: function () {},
  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
