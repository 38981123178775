<template>
  <div class="friends-page">
      <vlove-friends></vlove-friends>
  </div>
</template>

<script>
import VLoveFriends from '@/components/partial/Friends'

export default {
  name: 'vlove-friends-page',
  components: {
    'vlove-friends': VLoveFriends
  },
  mounted: function () {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>