import Vue from 'vue'

export default {
  GLOBAL_ADD_NOTIFICATION: (state, payload) => {
    var toadd = {title: payload.title, body: payload.body, type: payload.type}
    Vue.set(state.notifications, state.notifications.length, toadd)
  },
  GLOBAL_SHIFT_ONCE_NOTIFICATION: (state) => {
    if (state.notifications.length > 0) {
      state.notifications.splice(0, 1)
    }
  },
  GLOBAL_RESET_REQUEST: (state) => {
    state.loading_request = 0
  },
  GLOBAL_START_REQUEST: (state) => {
    state.loading_request++
  },
  GLOBAL_END_REQUEST: (state) => {
    state.loading_request--
  },
  GLOBAL_START_BLOCKING_REQUEST: (state) => {
    state.loading_blocking_request++
    state.loading_request++
  },
  GLOBAL_END_BLOCKING_REQUEST: (state) => {
    state.loading_blocking_request--
    state.loading_request--
  },
  GLOBAL_SHOW_WELCOME: (state) => {
    state.dialog_welcome = true
  },
  GLOBAL_HIDE_WELCOME: (state) => {
    state.dialog_welcome = false
  },
  GLOBAL_SHOW_CGU: (state) => {
    state.dialog_cgu = true
  },
  GLOBAL_HIDE_CGU: (state) => {
    state.dialog_cgu = false
  },
  GLOBAL_SHOW_PRIVACY: (state) => {
    state.dialog_privacy = true
  },
  GLOBAL_HIDE_PRIVACY: (state) => {
    state.dialog_privacy = false
  }
}
