import api from '@/api/api'
import i18n from '../../i18n.js'
// import moment from 'moment'

const state = {
  current: [],
  page_current: 0,
  total_current: 0,
  waiting: [],
  page_waiting: 0,
  total_waiting: 0,
  asked: [],
  page_asked: 0,
  total_asked: 0,
  blocked: [],
  page_blocked: 0,
  total_blocked: 0,
  refused: [],
  page_refused: 0,
  total_refused: 0,
  last_invitations: []
}

const getters = {
  FRIENDS_LIST: state => state.current && state.current.length > 0 ? state.current : [],
  FRIENDS_PAGINATION: state => { return { 'page': state.page_current, 'total': state.total_current } },
  WAITING_LIST: state => state.waiting && state.waiting.length > 0 ? state.waiting : [],
  WAITING_PAGINATION: state => { return { 'page': state.page_waiting, 'total': state.total_waiting } },
  ASKED_LIST: state => state.asked && state.asked.length > 0 ? state.asked : [],
  ASKED_PAGINATION: state => { return { 'page': state.page_asked, 'total': state.total_asked } },
  LAST_INVITATIONS: state => state.last_invitations && state.last_invitations.length > 0 ? state.last_invitations : []
}

const actions = {
  ASK_AS_FRIEND: async (context, payload) => {
    let ret = false
    context.commit('GLOBAL_START_REQUEST')
    try {
      // var response_data =
      await api.get_request(context, '/api/friendship/ask/' + payload.uid, context.rootState.user.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.invitation_sent'), body: '', type: 'success' })
      ret = true
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.invitation_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    return ret
  },
  ACCEPT_FRIENDSHIP: async (context, payload) => {
    let ret = false
    context.commit('GLOBAL_START_REQUEST')
    try {
      // var response_data =
      await api.get_request(context, '/api/friendship/accept/' + payload.uid, context.rootState.user.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.accept'), body: '', type: 'success' })
      ret = true
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.accept_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    return ret
  },
  REFUSE_FRIENDSHIP: async (context, payload) => {
    let ret = false
    context.commit('GLOBAL_START_REQUEST')
    try {
      // var response_data =
      await api.get_request(context, '/api/friendship/refuse/' + payload.uid, context.rootState.user.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.refuse'), body: '', type: 'success' })
      ret = true
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.refuse_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    return ret
  },
  REMOVE_FRIENDSHIP: async (context, payload) => {
    let ret = false
    context.commit('GLOBAL_START_REQUEST')
    try {
      // var response_data =
      await api.get_request(context, '/api/friendship/remove/' + payload.uid, context.rootState.user.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.remove'), body: '', type: 'success' })
      ret = true
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.remove_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    return ret
  },
  LOAD_LAST_INVITATIONS: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/friendships', context.rootState.user.token, {
        page: 0,
        limit: 5,
        type: 'received',
        status: 'pending'
      })
      var friendships = []
      for (let k = 0; k < response_data.items.length; k++) {
        var friend = {
          accepted: response_data.items[k].accepted,
          date_demand: response_data.items[k].date_demand,
          date_accept_or_refuse: response_data.items[k].date_accept_or_refuse,
          block: response_data.items[k].block
        }
        if (response_data.items[k].user_request.id !== context.rootState.user.user_data.id) {
          friend.user = response_data.items[k].user_request
        } else {
          friend.user = response_data.items[k].user_requested
        }
        friendships.push(friend)
      }
      context.commit('LOAD_LAST_INVITATIONS', { friends: friendships, context: context })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.loading_last_error'), body: 'error : ' + error.message, type: 'error' })
    }
    context.commit('RESTART_TIMEOUT_INVITATIONS', { context: context })
    context.commit('GLOBAL_END_REQUEST')
  },
  RELOAD_CURRENT_FRIENDS: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_current
    var limit = 10
    var type = 'all'
    var status = 'active'
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    // if (payload.type && ['all', 'asked', 'received'].indexOf(type) !== -1) {
    //   type = payload.type
    // }
    // if (payload.status && ['active', 'pending', 'refused', 'blocked'].indexOf(type) !== -1) {
    //   status = payload.status
    // }
    try {
      var response_data = await api.get_request(context, '/api/friendships', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit,
        type: type,
        status: status
      })
      var friendships = []
      for (let k = 0; k < response_data.items.length; k++) {
        var friend = {
          accepted: response_data.items[k].accepted,
          date_demand: response_data.items[k].date_demand,
          date_accept_or_refuse: response_data.items[k].date_accept_or_refuse,
          block: response_data.items[k].block
        }
        if (response_data.items[k].user_request.id !== context.rootState.user.user_data.id) {
          friend.user = response_data.items[k].user_request
        } else {
          friend.user = response_data.items[k].user_requested
        }
        friendships.push(friend)
      }
      context.commit('FRIENDSHIP_LOAD_FRIENDS', { page: current_page, total: response_data.total, friends: friendships })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.loading_error'), body: 'error : ' + error.message, type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_FRIENDS: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_current
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_FRIENDS(context, payload)
  },
  LOAD_PREVIOUS_FRIENDS: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_current
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_FRIENDS(context, payload)
  },
  RELOAD_CURRENT_INVITATIONS: async (context, payload) => {
    console.log('Reload sync')
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_waiting
    var limit = 10
    var type = 'received'
    var status = 'pending'
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/friendships', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit,
        type: type,
        status: status
      })
      var friendships = []
      for (let k = 0; k < response_data.items.length; k++) {
        var friend = {
          accepted: response_data.items[k].accepted,
          date_demand: response_data.items[k].date_demand,
          date_accept_or_refuse: response_data.items[k].date_accept_or_refuse,
          block: response_data.items[k].block
        }
        if (response_data.items[k].user_request.id !== context.rootState.user.user_data.id) {
          friend.user = response_data.items[k].user_request
        } else {
          friend.user = response_data.items[k].user_requested
        }
        friendships.push(friend)
      }
      context.commit('FRIENDSHIP_LOAD_INVITATIONS', { page: current_page, total: response_data.total, invitations: friendships })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.loading_invitations_error'), body: 'error : ' + error.message, type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_INVITATIONS: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_current
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_INVITATIONS(context, payload)
  },
  LOAD_PREVIOUS_INVITATIONS: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_current
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_INVITATIONS(context, payload)
  },
  RELOAD_CURRENT_INVITATIONS_SEND: async (context, payload) => {
    console.log('Reload sync')
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_waiting
    var limit = 10
    var type = 'asked'
    var status = 'pending'
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/friendships', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit,
        type: type,
        status: status
      })
      var friendships = []
      for (let k = 0; k < response_data.items.length; k++) {
        var friend = {
          accepted: response_data.items[k].accepted,
          date_demand: response_data.items[k].date_demand,
          date_accept_or_refuse: response_data.items[k].date_accept_or_refuse,
          block: response_data.items[k].block
        }
        if (response_data.items[k].user_request.id !== context.rootState.user.user_data.id) {
          friend.user = response_data.items[k].user_request
        } else {
          friend.user = response_data.items[k].user_requested
        }
        friendships.push(friend)
      }
      context.commit('FRIENDSHIP_LOAD_INVITATIONS_SEND', { page: current_page, total: response_data.total, invitations: friendships })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.friendship.loading_invitations_sent_error'), body: 'error : ' + error.message, type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  }
}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.current = []
    state.page_current = 0
    state.total_current = 0
    state.waiting = []
    state.page_waiting = 0
    state.total_waiting = 0
    state.asked = []
    state.page_asked = 0
    state.total_asked = 0
    state.blocked = []
    state.page_blocked = 0
    state.total_blocked = 0
    state.refused = []
    state.page_refused = 0
    state.total_refused = 0
    state.last_invitations = []
    if (state.last_invitations_timer !== null) {
      clearTimeout(state.last_invitations_timer)
      state.last_invitations_timer = null
    }
  },
  LOAD_LAST_INVITATIONS: (state, payload) => {
    if (typeof payload.friends !== 'undefined' && payload.friends.length >= 0) {
      state.last_invitations = payload.friends
    }
  },
  RESTART_TIMEOUT_INVITATIONS: (state, payload) => {
    if (state.last_invitations_timer !== null) {
      clearTimeout(state.last_invitations_timer)
      state.last_invitations_timer = null
    }
    var localcontext = payload.context
    state.last_invitations_timer = setTimeout(function () {
      localcontext.dispatch('LOAD_LAST_INVITATIONS')
    }, 15000)
  },
  FRIENDSHIP_LOAD_FRIENDS: (state, payload) => {
    if (typeof payload.friends !== 'undefined' && payload.friends.length >= 0) {
      state.page_current = parseInt(payload.page)
      state.current = payload.friends
      state.total_current = parseInt(payload.total)
    }
  },
  FRIENDSHIP_LOAD_INVITATIONS: (state, payload) => {
    if (typeof payload.invitations !== 'undefined' && payload.invitations.length >= 0) {
      state.page_waiting = parseInt(payload.page)
      state.waiting = payload.invitations
      state.total_waiting = parseInt(payload.total)
    }
  },
  FRIENDSHIP_LOAD_INVITATIONS_SEND: (state, payload) => {
    if (typeof payload.invitations !== 'undefined' && payload.invitations.length >= 0) {
      state.page_asked = parseInt(payload.page)
      state.asked = payload.invitations
      state.total_asked = parseInt(payload.total)
    }
  },
  FRIENDSHIP_LOAD_INVITATIONS_REFUSED: (state, payload) => {
    if (typeof payload.invitations !== 'undefined' && payload.invitations.length >= 0) {
      state.page_refused = payload.page
      state.refused = payload.invitations
      state.total_refused = parseInt(payload.total)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
