export default {
  getValues (_translator) {
    return {
      'woman': _translator.t('user.genders.woman'),
      'man': _translator.t('user.genders.man')
      // ,
      // 'unknown': _translator.t('user.genders.unknown')
    }
  }
}
