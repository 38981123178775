<template>
  <div class="vlove-chatlist">
    <v-list two-line>
        <!-- <v-subheader
          v-if="item.header"
        >
        </v-subheader> -->
      <template v-for="(item, index) in CHATS" :keys="index">
        <v-divider v-if="index > 0" :inset="true" :key="'_' + index" ></v-divider>
        <v-list-item v-if="item.user_init_chat && item.user_init_chat.username !== username" :to="{ name: 'User', query: { uid: item.user_init_chat.id, 'tab_user': 'chat'} }" :key="index">
          <div v-if="item.new_message">
            <v-badge
               color="primary"
               left
               overlap
             >
               <v-icon
                 slot="badge"
                 dark
                 small
               >fa-wine-bottle</v-icon>
              <v-list-item-avatar>
                <img :src="item.user_init_chat.medias | filterProfilePic(item.user_init_chat.gender)">
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div v-else>
           <v-list-item-avatar>
             <img :src="item.user_init_chat.medias | filterProfilePic(item.user_init_chat.gender)">
           </v-list-item-avatar>
          </div>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
            <v-list-item-subtitle><span class='text--primary'>{{ item.user_init_chat.username }}</span> &mdash; {{ item.user_init_chat.mood }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="item.user_respond_chat && item.user_respond_chat.username !== username" :to="{ name: 'User', query: { uid: item.user_respond_chat.id, 'tab_user': 'chat' } }" :key="index">
          <div v-if="item.new_message">
            <v-badge
               color="primary"
               left
               overlap
             >
               <v-icon
                 slot="badge"
                 dark
                 small
               >fa-wine-bottle</v-icon>
              <v-list-item-avatar>
                <img :src="item.user_respond_chat.medias | filterProfilePic(item.user_respond_chat.gender)">
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div v-else>
           <v-list-item-avatar>
             <img :src="item.user_respond_chat.medias | filterProfilePic(item.user_respond_chat.gender)">
           </v-list-item-avatar>
          </div>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
            <v-list-item-subtitle><span class='text--primary'>{{ item.user_respond_chat.username }}</span> &mdash; {{ item.user_respond_chat.mood }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-pagination v-if="CHATS_PAGINATION && typeof CHATS_PAGINATION.total !== 'undefined'"
      v-model="current_page_chats"
      :length="parseInt(Math.floor(CHATS_PAGINATION.total / 10)) + 1"
      :total-visible="Math.min(CHATS_PAGINATION.total , 7)"
      circle
    ></v-pagination>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'vlove-chatlist',
  data () {
    return {
      current_page_chats: 1
    }
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'IS_LOGGED_IN',
      'CHATS',
      'CHATS_PAGINATION'
    ])
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    }
  },
  mounted: function () {
    this.$store.dispatch('RELOAD_CURRENT_CHATS')
  },
  watch: {
    current_page_chats: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_CHATS', {'page': val})
    }
  },
  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
