export default {
  getValues (_translator) {
    return {
      'france': _translator.t('user.wineregions.france'),
      'france_bordeaux': _translator.t('user.wineregions.france_bordeaux'),
      'france_burgundy': _translator.t('user.wineregions.france_burgundy'),
      'france_north_rhone': _translator.t('user.wineregions.france_north_rhone'),
      'france_south_rhone': _translator.t('user.wineregions.france_south_rhone'),
      'france_languedoc': _translator.t('user.wineregions.france_languedoc'),
      'france_loire': _translator.t('user.wineregions.france_loire'),
      'france_roussillon': _translator.t('user.wineregions.france_roussillon'),
      'france_provence': _translator.t('user.wineregions.france_provence'),
      'france_alsace': _translator.t('user.wineregions.france_alsace'),
      'france_corse': _translator.t('user.wineregions.france_corse'),
      'france_southwest': _translator.t('user.wineregions.france_southwest'),
      'spain': _translator.t('user.wineregions.spain'),
      'italy': _translator.t('user.wineregions.italy'),
      'usa': _translator.t('user.wineregions.usa'),
      'usa_california': _translator.t('user.wineregions.usa_california'),
      'usa_washington_state': _translator.t('user.wineregions.usa_washington_state'),
      'usa_oregon': _translator.t('user.wineregions.usa_oregon'),
      'usa_new_york_state': _translator.t('user.wineregions.usa_new_york_state'),
      'usa_virginia': _translator.t('user.wineregions.usa_virginia'),
      'usa_texas': _translator.t('user.wineregions.usa_texas'),
      'usa_pennsylvania': _translator.t('user.wineregions.usa_pennsylvania'),
      'usa_ohio': _translator.t('user.wineregions.usa_ohio'),
      'usa_michigan': _translator.t('user.wineregions.usa_michigan'),
      'usa_missouri': _translator.t('user.wineregions.usa_missouri'),
      'australia': _translator.t('user.wineregions.australia'),
      'south_africa': _translator.t('user.wineregions.south_africa'),
      'new_zealand': _translator.t('user.wineregions.new_zealand'),
      'chile': _translator.t('user.wineregions.chile'),
      'argentina': _translator.t('user.wineregions.argentina'),
      'germany': _translator.t('user.wineregions.germany'),
      'austria': _translator.t('user.wineregions.austria'),
      'hungary': _translator.t('user.wineregions.hungary'),
      'others': _translator.t('user.wineregions.others')
    }
  }
}
