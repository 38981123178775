
<template>
  <div class="vlove-report">
    <v-btn text small color="primary" v-on:click="warnModal = true" >Report abuse</v-btn>
    <v-row justify="center">
        <v-dialog v-model="warnModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Report abusive behaviour</v-card-title>
                <v-card-text>
                    <p>You can report any abusive behaviour.</p>
                    <v-textarea
                        label="Add comment"
                        v-model="description"
                        :persist-hint="true"
                        hint="Add some comment to help us to understand the problem (optionnal)"
                        ></v-textarea>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="warnModal = false">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="report()">Send report</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'vlove-report',
  props: {
    type: String,
    typeId: Number
  },
  data () {
    return {
        warnModal: false,
        description: null
    }
  },
  mounted: function () {
  },
  methods: {
    async report () {
      await this.$store.dispatch('GLOBAL_REPORT_MESSAGE', {type: this.type, id: this.typeId, description: this.description ? this.description : undefined})
      this.warnModal = false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>