import api from '@/api/api'
import moment from 'moment'
import i18n from '../../i18n.js'

const state = {
  // current_visit: {},
  visited: [],
  page_visited: 0,
  total_visited: 0,
  visited_me: [],
  page_visited_me: 0,
  total_visited_me: 0,
  visiting_user_data: {}
}

const getters = {
  VISITED_LIST: state => state.visited && state.visited.length > 0 ? state.visited : [],
  VISITED_PAGINATION: state => { return { 'page': state.page_visited, 'total': state.total_visited } },
  VISITED_ME_LIST: state => state.visited_me && state.visited_me.length > 0 ? state.visited_me : [],
  VISITED_ME_PAGINATION: state => { return { 'page': state.page_visited_me, 'total': state.total_visited_me } },
  VISITING_U_ID: state => state.visiting_user_data && state.visiting_user_data.id ? state.visiting_user_data.id : '',
  VISITING_U_USERNAME: state => state.visiting_user_data && state.visiting_user_data.username ? state.visiting_user_data.username : '',
  VISITING_U_GENDER: state => state.visiting_user_data && state.visiting_user_data.gender ? state.visiting_user_data.gender : 'unknown',
  VISITING_U_LOOKING_FOR: state => state.visiting_user_data && state.visiting_user_data.lookingfor ? state.visiting_user_data.lookingfor : 'unknown',
  VISITING_U_IS_A_WINE_PROFESSIONNAL: state => state.visiting_user_data && state.visiting_user_data.wine_professionnal ? state.visiting_user_data.wine_professionnal : false,
  VISITING_U_IS_LOOKING_FOR_LOVE_RELATIONS: state => state.visiting_user_data && state.visiting_user_data.love_relations ? state.visiting_user_data.love_relations : false,
  VISITING_U_IS_LOOKING_FOR_PRO_RELATIONS: state => state.visiting_user_data && state.visiting_user_data.professionnal_relations ? state.visiting_user_data.professionnal_relations : false,
  VISITING_U_IS_LOOKING_FOR_FRIEND_RELATIONS: state => state.visiting_user_data && state.visiting_user_data.friendship_relations ? state.visiting_user_data.friendship_relations : false,
  VISITING_U_HOBBIES: state => state.visiting_user_data && state.visiting_user_data.hobbies ? state.visiting_user_data.hobbies : [],
  VISITING_U_OLD_AGE: state => state.visiting_user_data && state.visiting_user_data.birthday ? moment().diff(moment(state.visiting_user_data.birthday), 'years') : 18,
  VISITING_U_SIZE: state => state.visiting_user_data && state.visiting_user_data.size ? state.visiting_user_data.size : '-',
  VISITING_U_WEIGHT: state => state.visiting_user_data && state.visiting_user_data.weight ? state.visiting_user_data.weight : '-',
  VISITING_U_HAIR_COLOR: state => state.visiting_user_data && state.visiting_user_data.haircolor ? state.visiting_user_data.haircolor : '-',
  VISITING_U_EYES_COLOR: state => state.visiting_user_data && state.visiting_user_data.eyescolors ? state.visiting_user_data.eyescolors : '-',
  VISITING_U_SILHOUETTE: state => state.visiting_user_data && state.visiting_user_data.silhouette ? state.visiting_user_data.silhouette : '-',
  VISITING_U_STYLE: state => state.visiting_user_data && state.visiting_user_data.style ? state.visiting_user_data.style : '-',
  VISITING_U_CITY: state => state.visiting_user_data && state.visiting_user_data.city ? state.visiting_user_data.city : '-',
  VISITING_U_COUNTRY: state => state.visiting_user_data && state.visiting_user_data.country ? state.visiting_user_data.country : '-',
  VISITING_U_MOOD: state => state.visiting_user_data && state.visiting_user_data.mood ? state.visiting_user_data.mood : '',
  VISITING_U_DESCRIPTION: state => state.visiting_user_data && state.visiting_user_data.description ? state.visiting_user_data.description : '',
  VISITING_U_MEDIAS: state => state.visiting_user_data && state.visiting_user_data.medias ? state.visiting_user_data.medias : [],
  VISITING_U_LIKED: state => state.visiting_user_data && state.visiting_user_data.cheered_by_me,
  VISITING_U_FRIEND: state => state.visiting_user_data && state.visiting_user_data.friendship_with_me,
  VISITING_U_WINE_COLORS: state => state.visiting_user_data && state.visiting_user_data.winecolors ? state.visiting_user_data.winecolors : [],
  VISITING_U_WINE_REGIONS: state => state.visiting_user_data && state.visiting_user_data.wineregions ? state.visiting_user_data.wineregions : [],
  VISITING_U_WINE_VARIETIES: state => state.visiting_user_data && state.visiting_user_data.winevarieties ? state.visiting_user_data.winevarieties : [],
  VISITING_U_DISTANCE: state => state.visiting_user_data && state.visiting_user_data.distance ? state.visiting_user_data.distance : '',
  VISITING_U_FRIEND_PENDING: state => state.visiting_user_data && state.visiting_user_data.friendship_pending_with_me,
  VISITING_U_FRIEND_INVITATION_PENDING: state => state.visiting_user_data && state.visiting_user_data.invitation_pending_with_me
}

const actions = {

  RELOAD_CURRENT_VISITED: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_visited
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/visited', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var visited = []
      for (let k = 0; k < response_data.items.length; k++) {
        visited.push(response_data.items[k].user_visited)
      }
      context.commit('LOAD_VISITED', { page: current_page, total: response_data.total, visited: visited })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.visit.loading_visited_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_VISITED: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_visited
    current_page = current_page + 1
    payload.page = current_page
    context.dispatch('RELOAD_CURRENT_VISITED', payload)
  },
  LOAD_PREVIOUS_VISITED: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_visited
    current_page = ((current_page - 1) >= 1 ? (current_page - 1) : 1)
    payload.page = current_page
    context.dispatch('RELOAD_CURRENT_VISITED', payload)
  },

  RELOAD_CURRENT_VISITED_ME: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_visited_me
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/visitors', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var visited_me = []
      for (let k = 0; k < response_data.items.length; k++) {
        visited_me.push(response_data.items[k].user_visitor)
      }
      context.commit('LOAD_VISITED_ME', { page: current_page, total: response_data.total, visited_me: visited_me })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.visit.loading_visitors_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_VISITED_ME: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_visited_me
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_VISITED_ME(context, payload)
  },
  LOAD_PREVIOUS_VISITED_ME: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_visited_me
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_VISITED_ME(context, payload)
  },

  VISIT_AND_LOAD_PROFILE: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    context.commit('LOAD_PROFILE', {})
    try {
      var response_data = await api.get_request(context, '/api/visit/' + payload.uid, context.rootState.user.token)
      context.commit('LOAD_PROFILE', response_data)
      context.dispatch('RELOAD_CURRENT_CHAT_MESSAGES')
      context.dispatch('USER_REFRESH')
    } catch (error) {
      context.commit('USER_LOGGED_OUT')
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.visit.visit_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
  }
}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.visited = []
    state.page_visited = 0
    state.total_visited = 0
    state.visited_me = []
    state.page_visited_me = 0
    state.total_visited_me = 0
    state.visiting_user_data = {}
  },
  LOAD_VISITED: (state, payload) => {
    if (payload.visited && payload.visited.length > 0) {
      state.page_visited = payload.page
      state.visited = payload.visited
      state.total_visited = payload.total
    }
  },
  LOAD_VISITED_ME: (state, payload) => {
    if (payload.visited_me && payload.visited_me.length >= 0) {
      state.page_visited_me = payload.page
      state.visited_me = payload.visited_me
      state.total_visited_me = payload.total
    }
  },
  LOAD_PROFILE: (state, payload) => {
    state.visiting_user_data = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
