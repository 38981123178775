<template>
  <v-app id="vinealove" light class="background">

    <v-app-bar
      app
      dark fixed 
      color="primary"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <span class="title ml-3 mr-5 font-weight-light">Vinealove</span>
      <v-spacer />
      <!-- <v-select v-model="$i18n.locale" :items="langs">
      </v-select> -->
      <v-btn icon v-if="IS_REQUEST_LOADING">
        <v-progress-circular
          indeterminate
          color="background"
        ></v-progress-circular>
      </v-btn> 
      <v-menu
        left
        bottom
        offset-y
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
          {{ $i18n.locale }}
          <v-icon>arrow_drop_down</v-icon>
          <!-- <v-icon v-else>arrow_drop_up</v-icon> -->
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(lvalue, lkey) in langs"
            :key="lkey"
            v-on:click="updateLocale(lvalue)"
          >
            <v-list-item-title>{{ lvalue }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

      <v-navigation-drawer
        class="background"
        v-model="drawer"
        fixed
        app
      >
        <vlove-menu></vlove-menu>
      </v-navigation-drawer>
      <v-content>
          <router-view></router-view>
      </v-content>
      <!-- <v-footer color="primary" app>
        <span class="white--text">&copy; Vinealove 2020</span>
      </v-footer> -->
       <v-snackbar
          v-model="pop_notification"
          :bottom="false"
          :left="false"
          :multi-line="true"
          :right="false"
          :timeout="10000"
          :top="true"
          :vertical="false"
          >
          <div v-if="notification" >
            {{ notification.title }}<br />
            {{ notification.body }}
          </div>
        <v-btn
          color="pink"
          text
          @click="pop_notification = false"
        >
          {{ $t('notifications.close') }}
        </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="IS_BLOCKING_REQUEST_LOADING"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please wait...
          <v-progress-linear
            indeterminate
            color="background"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_welcome"
      width="550"
    >
      <v-card>
        <v-img
          src="./assets/verres-vinealove.jpg"
          height="300px"
          cover
          top
        ></v-img>

        <v-card-title class="headline grey lighten-2 wraptext">
          {{ $t('partials.dialog_welcome.title') }}
        </v-card-title>

        <v-card-text>
          <p>
            <strong>{{ $t('partials.dialog_welcome.paragraph_strong') }}</strong>
            {{ $t('partials.dialog_welcome.paragraph_1') }}
          </p>
          <ul>
            <li><router-link :to="{ name: 'Matching' }">{{ $t('menu.matching') }}</router-link> : {{ $t('partials.dialog_welcome.paragraph_matching') }}
            </li>
            <li><router-link :to="{ name: 'VineaLovers' }">{{ $t('menu.vinealovers') }}</router-link> : {{ $t('partials.dialog_welcome.paragraph_search') }}
            </li>
            <li><router-link :to="{ name: 'Agenda' }">{{ $t('menu.events') }}</router-link> : {{ $t('partials.dialog_welcome.paragraph_agenda') }}
            </li>
          </ul>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_welcome = false"
          >
            {{ $t('partials.dialog_welcome.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="GET_CGU_DIALOG" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('views.register.cgu_title') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <h4>{{ $t('global.cgu.title') }}</h4>
                <h5 class="mt-2">{{ $t('global.cgu.introduction_title') }}</h5>
                <p>{{ $t('global.cgu.introduction_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_1_title') }}</h5>
                <p>{{ $t('global.cgu.article_1_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_2_title') }}</h5>
                <p>{{ $t('global.cgu.article_2_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_3_title') }}</h5>
                <p>{{ $t('global.cgu.article_3_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_4_title') }}</h5>
                <p>{{ $t('global.cgu.article_4_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_5_title') }}</h5>
                <p>{{ $t('global.cgu.article_5_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_6_title') }}</h5>
                <p>{{ $t('global.cgu.article_6_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_7_title') }}</h5>
                <p>{{ $t('global.cgu.article_7_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_8_title') }}</h5>
                <p>{{ $t('global.cgu.article_8_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_9_title') }}</h5>
                <p>{{ $t('global.cgu.article_9_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_10_title') }}</h5>
                <p>{{ $t('global.cgu.article_10_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_11_title') }}</h5>
                <p>{{ $t('global.cgu.article_11_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_12_title') }}</h5>
                <p>{{ $t('global.cgu.article_12_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_13_title') }}</h5>
                <p>{{ $t('global.cgu.article_13_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_14_title') }}</h5>
                <p>{{ $t('global.cgu.article_14_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_15_title') }}</h5>
                <p>{{ $t('global.cgu.article_15_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_16_title') }}</h5>
                <p>{{ $t('global.cgu.article_16_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_17_title') }}</h5>
                <p>{{ $t('global.cgu.article_17_content') }}</p>
                <h5 class="mt-2">{{ $t('global.cgu.article_18_title') }}</h5>
                <p>{{ $t('global.cgu.article_18_content') }}</p>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeCGU()">{{ $t('views.register.cgu_close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="GET_PRIVACY_DIALOG" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('global.privacy.title') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <h4>{{ $t('global.privacy.title') }}</h4>
                <p>{{ $t('global.privacy.paragraph_1') }}</p>
                <p>{{ $t('global.privacy.paragraph_2') }}</p>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closePrivacy()">{{ $t('views.register.cgu_close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import VLoveMenu from '@/components/partial/Menu'
import moment from 'moment'

export default {
  name: 'app',
  components: {
    'vlove-menu': VLoveMenu
  },
  computed: {
    ...mapGetters([
      'IS_MOBILE',
      'IS_LOGGED_IN',
      'IS_INFORMATIONS_LOADED',
      'U_LOCALE',
      'GET_LAST_NOTIFICATION', 
      'IS_REQUEST_LOADING',
      'GET_WELCOME_DIALOG',
      'IS_BLOCKING_REQUEST_LOADING',
      'GET_CGU_DIALOG',
      'GET_PRIVACY_DIALOG'
    ])
  },
  data () {
    return {
      drawer: false,
      dialog_welcome: false,
      pop_notification: false,
      notification: null,
      langs: ['en', 'fr', 'it', 'es', 'el', 'ru']
    }
  },
  watch: {
    GET_WELCOME_DIALOG: function (_new_value) {
      if (_new_value) {
        this.dialog_welcome = _new_value
      }
    },
    dialog_welcome: function (_new_value) {
      if (!_new_value) {
        this.GLOBAL_HIDE_WELCOME()
      }
    },
    GET_LAST_NOTIFICATION: function (_new_value) {
      // console.log('GET_LAST_NOTIFICATION')
      if (_new_value !== null) {
        this.pop_notification = true
        this.notification = _new_value
      }
    },
    pop_notification: function (_new_value, _old_value) {
      if (!_new_value && _old_value) {
        this.GLOBAL_REMOVE_LAST_NOTIFICATION()
      }
    },
    "$i18n.locale": function (val, oldVal) {
      if (val !== oldVal) {
        moment.locale(val)
      }
    },
    IS_LOGGED_IN: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.drawer = true
      } else if (!val && val !== oldVal) {
        this.drawer = false
      }
    },
    IS_INFORMATIONS_LOADED: function (val, oldVal) {
      setTimeout(() => {
        if (this.IS_LOGGED_IN && val && !oldVal) {
          if(this.U_LOCALE && this.U_LOCALE.length > 0) {
            this.$i18n.locale = this.U_LOCALE
          } else if (!this.U_LOCALE || this.U_LOCALE <= 0) {
            this.$store.dispatch('USER_SET_CURRENT_LOCALE', {'locale': this.$i18n.locale})
          }
        }
      }, 0)
    }
  },
  methods: {
    ...mapActions({
      GLOBAL_REMOVE_LAST_NOTIFICATION: 'GLOBAL_REMOVE_LAST_NOTIFICATION', // map `this.add()` to `this.$store.dispatch('increment')`,
      GLOBAL_HIDE_WELCOME: 'GLOBAL_HIDE_WELCOME',
      GLOBAL_SHOW_CGU: 'GLOBAL_SHOW_CGU',
      GLOBAL_HIDE_CGU: 'GLOBAL_HIDE_CGU',
      GLOBAL_SHOW_PRIVACY: 'GLOBAL_SHOW_PRIVACY',
      GLOBAL_HIDE_PRIVACY: 'GLOBAL_HIDE_PRIVACY'
    }),
    updateLocale (_locale) {
      if (this.IS_LOGGED_IN) {
        this.$store.dispatch('USER_SET_CURRENT_LOCALE', {'locale': _locale})
      }
      this.$i18n.locale = _locale
    },
    closeCGU () {
      this.GLOBAL_HIDE_CGU()
    },
    closePrivacy () {
      this.GLOBAL_HIDE_PRIVACY()
    }
  },
  mounted: function () {
    this.drawer = false
    moment.locale(this.$i18n.locale)
    if (this.$route.query.show_cgu) {
      this.GLOBAL_SHOW_CGU()
    }
    if (this.$route.query.show_privacy) {
      this.GLOBAL_SHOW_PRIVACY()
    }
    setTimeout(() => {
      console.log(this.IS_MOBILE ? 'load app mobile' : 'load app web', window.cordova && window.device && window.device.platform ? window.device.platform : 'unknown platform')
    }, 1000)
    // console.log(process.env.VUE_APP_API_BASE_URL)
  }
}
</script>

<style>
#app {
}

.wraptext {
  word-break: break-word;
}
</style>
