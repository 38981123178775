<template>
  <div class="login">
    <div class="overlay-login">
    <v-container fluid>
      <v-card
        class="mx-auto mb-5"
        max-width="512"
        color="transparent"
        elevation="0"
        
      >
        <v-card-text class="text-center">
          <v-img :height="150" src="@/assets/logo.png" aspect-ratio="1" contain></v-img>
          <span class="primary--text text-center text-h6 font-weight-bold">{{ $t("views.landing.baseline") }}</span>
        </v-card-text>
      </v-card>
      <v-card
        class="mx-auto pa-5"
        max-width="512"
      >
        <!-- {{ $t('views.login.title') }} -->
      <!-- <v-card-title>
        <v-spacer></v-spacer>
        <v-select v-model="$i18n.locale" :items="langs" dense
        solo flat
          class="lang-selector"
          >
        </v-select>
      </v-card-title> -->
      <v-card-text class="pt-5 pl-5 pr-5 pb-0">
        <v-form v-model="valid">
          <v-text-field
            prepend-inner-icon="fa-user"
            v-model="email"
            :rules="emailRules"
            :label="$t('views.login.label_email')"
            required
            type="email"
            v-on:keyup.enter.exact="login"
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="fa-lock"
            v-model="password"
            :append-icon="show1 ? 'visibility_off' : 'visibility'"
            :rules="[required, min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('views.login.label_password')"
            @click:append="show1 = !show1"
            v-on:keyup.enter.exact="login"
          ></v-text-field>
          <p class="text-right"><a class="primary--text" @click.stop="dialog_password = true">{{ $t('views.login.label_lostpasswordbutton') }}</a></p>
         </v-form>
      </v-card-text>
      <!-- <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col lg="4" >
              <v-btn text class="primary--text" large :to="{name: 'register'}">{{ $t('views.login.label_switchbutton') }}</v-btn>
            </v-col>
            <v-col lg="4" >
              <v-btn
                  color="primary"
                  @click="login"
                >
                  {{ $t('views.login.label_validbutton') }}
              </v-btn>
            </v-col>
         </v-row>
        </v-container>
      </v-card-text> -->
      <v-card-actions class="flex-row pt-0 pl-5 pr-5 pb-5">
        <v-container>
          <v-row class="d-flex flex-row-reverse">
            <v-btn
            color="primary"
            class="float-right mt-2"
            @click="login"
          >
            {{ $t('views.login.label_validbutton') }}
          </v-btn>
            <v-btn text class="primary--text float-right mt-2 mr-2" large :to="{name: 'register'}">{{ $t('views.login.label_switchbutton') }}</v-btn>
          </v-row>
          <!-- <v-row dense>
            <v-spacer></v-spacer>
            <v-col 
              cols="12"
              :sm="$i18n.locale === 'fr' ? '6' : '3'"
            >
              <v-btn text class="primary--text float-right" large :to="{name: 'register'}">{{ $t('views.login.label_switchbutton') }}</v-btn>
             
            </v-col>
            <v-col 
              cols="12"
              :sm="$i18n.locale === 'fr' ? '5' : '3'"
            >
              <v-btn
                color="primary"
                class="float-right"
                @click="login"
              >
                {{ $t('views.login.label_validbutton') }}
              </v-btn>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-actions>
      <v-card-text class="text-center mb-0 pb-0">
        <hr />
        <p class="alternative-connexion-title"><span>{{ $t('views.login.label_alternativelogin') }}</span></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="loading_fb_login">{{ $t('views.login.loading_facebook') }}</div>
        <v-btn v-else large dark color="blue darken-1" disabled v-on:click="facebookLogin()">
          <v-icon>fab fa-facebook</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog_password" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ $t('views.login.reinit_password_title') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$t('views.login.reinit_password_field_label')" v-model="email" type="email" required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_password = false">{{ $t('views.login.reinit_password_close') }}</v-btn>
            <v-btn color="primary" text @click="resetPassword()">{{ $t('views.login.reinit_password_valid') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import logo from '@/assets/logo.png'

export default {
  name: 'login',
  data () {
    return {
      show1: false,
      valid: false,
      logo_vinealove: logo,
      dialog_password: false,
      langs: ['en', 'fr'],
      required: value => !!value || this.$i18n.t('views.login.hint_password_1'),
      min: v => (v && v.length >= 5) || this.$i18n.t('views.login.hint_password_2'),
      email: '',
      emailRules: [
        v => !!v || this.$i18n.t('views.login.hint_email_1'),
        v => /.+@.+/.test(v) || this.$i18n.t('views.login.hint_email_2')
      ],
      password: '',
      loading_fb_login: false
    }
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'IS_LOGGED_IN',
      'U_LAST_EMAIL',
      'IS_MOBILE'
    ])
  },
  watch: {
    IS_LOGGED_IN: function (val) {
      if (val) {
        if (this.$route.query.redirect) {
          const redirectPath = this.$route.query.redirect
          const queriesParams = this.$route.query
          // delete queriesParams.redirect
          // let urlToRedirect = ''
          // for (let key in queriesParams) {
          //   if (urlToRedirect.length > 0) {
          //     urlToRedirect += '&'
          //   }
          //   urlToRedirect += key + '=' + queriesParams[key]
          // }
          // urlToRedirect = redirectPath + '?' + urlToRedirect
          // window.location = urlToRedirect
          this.$router.push({ path: redirectPath, query: queriesParams })
          return
        }
        this.$router.push({name: 'Profil'})
      }
    }
  },
  mounted: function () {
    this.email = this.U_LAST_EMAIL
    this.initFacebook()
  },
  beforeDestroy () {
    this.unloadFacebook()
  },
  methods: {
    async login () {
      // console.log('login ' + this.username + ' ' + this.password)
      try {
        await this.$store.dispatch('USER_LOGIN', { username: this.email, password: this.password })
      } catch (e) {
        // console.log(e)
      }
    },
    initFacebook () {
      if (window && window.FB) {
        this.loading_fb_login = true
        window.FB.Event.subscribe('xfbml.render', this.facebookLoaded)
        window.FB.Event.subscribe('auth.authResponseChange', this.facebookLoginCheck)
        var appId = process.env.VUE_APP_FACEBOOK_APP || '165485438438759'
        window.FB.init({
          appId      : appId, // dev : 165485438438759 prod : 547349709528595
          status     : true,
          xfbml      : true,
          version    : 'v2.7' // or v2.6, v2.5, v2.4, v2.3
        })
      }
    },
    unloadFacebook () {
      if (window && window.FB) {
        window.FB.Event.unsubscribe('xfbml.render', this.facebookLoaded)
        window.FB.Event.unsubscribe('auth.authResponseChange', this.facebookLoginCheck)
      }
    },
    facebookLoaded () {
      this.loading_fb_login = false
    },
    facebookLogin () {
      if (window && window.FB) {
        window.FB.login(function() {
        }, {scope: 'email,public_profile'});
      }
    },
    facebookLoginCheck () {
      if (window && window.FB) {
        var refthis = this
        window.FB.getLoginStatus(function(authresponse) {
            // statusChangeCallback(response);
            // {
            //     status: 'connected', //connected //not_authorized 
            //     authResponse: {
            //         accessToken: '...',
            //         expiresIn:'...',
            //         signedRequest:'...',
            //         userID:'...'
            //     }
            // }
          console.log(authresponse)
          if (authresponse.status === 'connected') {
            window.FB.api('/me?fields=id,email,last_name,first_name', function(response) {
              console.log('Successful login for: ' + response.name);
              console.log(response)
              refthis.loginWithFacebookUid(response, authresponse)
            })
          }
        })
      }
    },
    async loginWithFacebookUid (fbpayload, authfbpayload) {
      fbpayload['accesstoken'] = authfbpayload.authResponse.accessToken
      try {
        await this.$store.dispatch('USER_LOGIN_FB', fbpayload)
      } catch (e) {
        // console.log(e)
      }
    },
    updateUsername (e) {
      this.$store.dispatch('updateUsername', { username: e })
    },
    async resetPassword () {
      await this.$store.dispatch('USER_RESET_PASSWORD', {'email': this.email})
      this.dialog_password = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login {
    background-image: url('../../assets/login-bg.jpg');
    background-size: cover;
    background-position: right center;
    min-height: 100%;/* 88vh;*/
    height: 100%;
  }
  .overlay-login {
    min-height:  100%;/* 88vh;*/
    height: 100%;
    background-color: rgba(255,255,255, 0.5);
  }
  .lang-selector {
    max-width: 5rem;
    width: 5rem;
  }
  .alternative-connexion-title {
    margin-top: -12px;
  }
  .alternative-connexion-title > span {
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
</style>
