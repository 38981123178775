<template>
  <v-row class="pa-5">
    <v-col cols="12" md="12">
      <v-img
        height="250"
        aspect-ratio="1"
        src="@/assets/couple-straight-bg.png"
        position="top center"
      ></v-img>
    </v-col>
    <v-col cols="12" md="12">
      <v-alert
        :value="status === 'success'"
        type="success"
      >
        {{ $t('partials.payment.alert_pending_payment') }}
      </v-alert>
      <v-alert
        :value="status === 'cancelled'"
        type="warning"
      >
        {{ $t('partials.payment.alert_error_payment') }}
      </v-alert>
      <v-card v-if="status !== 'success'" :elevation="0" class="pa-0">
        <v-card-title>
          <h3 class="display-2" style="word-break: normal;">{{ $t('partials.payment.title_payment_subscription') }}</h3>
        </v-card-title>
        <v-card-text>
          <p>{{ $t('partials.payment.description_payment_subscription') }}</p>
          <p>
            <em>
              <span v-if="IS_MOBILE && IS_APPLE">{{ $t('partials.payment.legal_informations_0_ios') }}</span>
              <span v-else-if="IS_MOBILE">{{ $t('partials.payment.legal_informations_0_android') }}</span>
              <span v-else>{{ $t('partials.payment.legal_informations_0_web') }}</span>
              <br />
              {{ $t('partials.payment.legal_informations_1') }} <a v-on:click="GLOBAL_SHOW_CGU()" >{{ $t('partials.payment.legal_open') }}</a><br />
              {{ $t('partials.payment.legal_informations_2') }} <a v-on:click="GLOBAL_SHOW_PRIVACY()" >{{ $t('partials.payment.legal_open') }}</a>
            </em>
          </p>
        </v-card-text>
        <v-card-text v-if="loading_plans" class="pa-0">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-else-if="AVAILABLE_PLANS" class="pa-0">
          <v-card v-for="(value, index) in AVAILABLE_PLANS" :key="index" class="mb-2">
            <v-card-title>
                <h4 class="wraptext">
                    <span v-if="value.nickname">{{ value.nickname }}</span>
                    <span v-else-if="value.interval_count && !isNaN(parseInt(value.interval_count))">
                      {{ $tc('partials.payment.offer_name', parseInt(value.interval_count), {'months': value.interval_count }) }}
                    </span>
                    <span v-if="value.description"><br />{{ value.description }}</span>
                    <br />
                    <small>
                    <span v-if="value.amount && value.interval_count && !isNaN(parseInt(value.interval_count))">
                      {{ $t('partials.payment.month_price', {'amount': Math.round(value.amount/parseInt(value.interval_count))/100 + ' ' + value.currency }) }}
                    </span>
                    </small>
                </h4>
            </v-card-title>
            <v-card-subtitle class="wraptext">
                <p>
                </p>
                <span v-if="MAX_PLANS === 0 || Math.round(100 - (Math.round(value.amount/parseInt(value.interval_count)) / MAX_PLANS * 100)) === 0">
                </span>
                <v-chip
                  v-else
                  color="primary"
                  :outlined="index !== 0"
                >
                  {{ $t('partials.payment.save_price', {'percent': Math.round(100 - (Math.round(value.amount/parseInt(value.interval_count)) / MAX_PLANS * 100))}) }}
                </v-chip>
            </v-card-subtitle>
            <v-card-text>
                    <em v-if="value.interval_count && !isNaN(parseInt(value.interval_count))">
                      {{ $tc('partials.payment.offer_duration', parseInt(value.interval_count), {'months': value.interval_count }) }}
                      <br />
                    </em>
                    <em>{{ $tc('partials.payment.months_payment', parseInt(value.interval_count), {'amount': Math.round(value.amount)/100 + ' ' + value.currency }) }}</em>
            </v-card-text>
            <v-card-text>
                    {{ $tc('partials.payment.offer_description', parseInt(value.interval_count), {'months': value.interval_count }) }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" :outlined="index !== 0" :disable="pay_disable" v-on:click="purchase(value.id)">{{ $t('partials.payment.label_validbutton') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Vuex from 'vuex'

/* eslint-disable no-undef */
// let elements = stripe.elements()
// let card

export default {
  data: function () {
    return {
      loading_plans: false,
      abo: null,
      required: value => !!value || 'Required.',
      pay_disable: false,
      error_message: null,
      success_message: null,
      status: null
    }
  },
  computed: {
    ...Vuex.mapGetters([
      'AVAILABLE_PLANS',
      'MAX_PLANS',
      'IS_MOBILE',
      'IS_APPLE'
    ])
  },
  methods: {
    ...Vuex.mapActions({
      GLOBAL_SHOW_CGU: 'GLOBAL_SHOW_CGU',
      GLOBAL_HIDE_CGU: 'GLOBAL_HIDE_CGU',
      GLOBAL_SHOW_PRIVACY: 'GLOBAL_SHOW_PRIVACY',
      GLOBAL_HIDE_PRIVACY: 'GLOBAL_HIDE_PRIVACY'
    }),
    purchase: async function (planid) {
      if (planid) {
        if (this.IS_MOBILE) {
          const isOk = await this.$store.dispatch('SUBSCRIBE_INAPP_MOBILE', {'plan_id': planid})
          if (isOk) {
            this.$emit('completedpayment', true)
            return
          }
        } else {
          await this.$store.dispatch('GENERATE_CHECKOUT_SESSION_AND_REDIRECT', {'plan_id': planid})
        }
      }
    }
  },
  mounted: function () {
    if (this.$route.query.status) {
      this.status = this.$route.query.status
    }
    if (this.status !== 'success') {
      this.loading_plans = true
      setTimeout(async () => {
        await this.$store.dispatch('LOAD_AVAILABLE_PLANS')
        this.loading_plans = false
      }, 3000)
    }
  }
}
</script>
