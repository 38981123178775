import api from '@/api/api'
import i18n from '../../i18n.js'
// import moment from 'moment'

const state = {
  liked: [],
  page_liked: 0,
  total_liked: 0,
  like_me: [],
  page_like_me: 0,
  total_like_me: 0,
  current_cheer_action_running: false
}

const getters = {
  LIKED_LIST: state => state.liked && state.liked.length > 0 ? state.liked : [],
  LIKED_PAGINATION: state => { return { 'page': state.page_liked, 'total': state.total_liked } },
  LIKE_ME_LIST: state => state.like_me && state.like_me.length > 0 ? state.like_me : [],
  LIKE_ME_PAGINATION: state => { return { 'page': state.page_like_me, 'total': state.total_like_me } },
  IS_CHEER_ACTION_RUNNING: state => state.current_cheer_action_running
}

const actions = {

  RELOAD_CURRENT_CHEERS: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_liked
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/cheers', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var liked = []
      for (let k = 0; k < response_data.items.length; k++) {
        liked.push(response_data.items[k].user_cheered)
      }
      context.commit('LOAD_CHEERS', { page: current_page, total: response_data.total, liked: liked })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.cheers.loading_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_CHEERS: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_liked
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_CHEERS(context, payload)
  },
  LOAD_PREVIOUS_CHEERS: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_liked
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_CHEERS(context, payload)
  },

  RELOAD_CURRENT_CHEERED_ME: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_like_me
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/cheeredby', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var like_me = []
      for (let k = 0; k < response_data.items.length; k++) {
        like_me.push(response_data.items[k].user_cheer)
      }
      context.commit('LOAD_CHEERED_ME', { page: current_page, total: response_data.total, like_me: like_me })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.cheers.loading_cheered_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_CHEERED_ME: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_like_me
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_CHEERED_ME(context, payload)
  },
  LOAD_PREVIOUS_CHEERED_ME: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_like_me
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_CHEERED_ME(context, payload)
  },
  CHEER_UNCHEER: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    context.commit('CHEER_START_REQUEST')
    try {
      await api.get_request(context, (payload.cheer ? '/api/cheer/' : '/api/uncheer/') + payload.uid, context.rootState.user.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title:  i18n.t(payload.cheer ? 'notifications.cheers.send_ok_cheer' : 'notifications.cheers.send_ok_uncheer'), type: 'success' })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t(payload.cheer ? 'notifications.cheers.send_error_cheer' : 'notifications.cheers.send_error_uncheer'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('CHEER_END_REQUEST')
    context.commit('GLOBAL_END_REQUEST')
  }
}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.liked = []
    state.page_liked = 0
    state.total_liked = 0
    state.like_me = []
    state.page_like_me = 0
    state.total_like_me = 0
  },
  LOAD_CHEERS: (state, payload) => {
    if (payload.liked && (payload.liked.length >= 0 || state.page_liked === payload.page)) {
      state.page_liked = payload.page
      state.liked = payload.liked
      state.total_liked = payload.total
    }
  },
  LOAD_CHEERED_ME: (state, payload) => {
    if (payload.like_me && (payload.like_me.length >= 0 || state.page_like_me === payload.page)) {
      state.page_like_me = payload.page
      state.like_me = payload.like_me
      state.total_like_me = payload.total
    }
  },
  CHEER_START_REQUEST: (state) => {
    state.current_cheer_action_running = true 
  },
  CHEER_END_REQUEST: (state) => {
    state.current_cheer_action_running = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
