<template>
  <div class="match">
    <v-container fluid >
        <v-btn
          absolute
          fab
          top
          style="margin-top: 40px !important;"
          right
          color="white secondary--text"
          dense
          v-on:click="dialog_matchlist = true"
          v-if="CURRENT_MATCH_USER"
        >
          <v-icon large dense>fa-kiss-wink-heart</v-icon>
        </v-btn>
      <v-row  
        align="center"
        justify="center" 
        class="text-center">
        <h1 v-if="CURRENT_MATCH_USER" class="primary--text text-h6 mb-1">Matching</h1>
        <v-alert v-else color="primary" dark>
          {{ $t('views.match.no_more_match_available') }}<br /><br />
          <v-btn color="secondary" v-on:click="dialog_matchlist = true">{{ $t('views.match.consult') }} <v-icon class="ml-2" large dense>fa-kiss-wink-heart</v-icon></v-btn>
          <v-img class="mt-2" :height="'75vh'" :src="'./' + illustrations[Math.max(0, Math.min(current_illustration_id, illustrations.length))]" aspect-ratio="1" contain ></v-img>
        </v-alert>
      </v-row>
      <v-row
          v-if="CURRENT_MATCH_USER"
          v-touch="{
            left: () => dislike(),
            right: () => like(),
            up: () => {},
            down: () => {}
          }"
        align="center"
        justify="center" 
        class="text-center">
          <v-card
              max-width="400px" class="pb-5 mb-5"
            style="border-radius: 24px!important;">
          <vlove-match-profile-sheet
            v-if="CURRENT_MATCH_USER"
            :picture="CURRENT_MATCH_USER.medias"
            :age="CURRENT_MATCH_USER.birthday | ageFromBirthday"
            :name="CURRENT_MATCH_USER.username"
            :moods="CURRENT_MATCH_USER.mood"
            :description="CURRENT_MATCH_USER.description"
            :gender="CURRENT_MATCH_USER.gender"
            v-on:click-details="dialog_details = true"
            ></vlove-match-profile-sheet>
          </v-card>
      </v-row>
      <v-row
        align="center"
        justify="center" >
          <v-btn class="mr-2" :large="!notLikedTo" v-if="CURRENT_MATCH_USER" fab dark v-on:click="dislike()" color="white black--text" :disabled="match_popup">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
          <v-btn class="ml-2" :large="!likedTo" v-if="CURRENT_MATCH_USER" fab dark v-on:click="like()" color="primary" :disabled="match_popup">
            <v-icon dark>fa-heart</v-icon>
          </v-btn>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog_details" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="secondary" flat>
          <v-toolbar-title v-if="CURRENT_MATCH_USER" >{{ CURRENT_MATCH_USER.username }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn v-if="CURRENT_MATCH_USER" large fab icon dark v-on:click="this.dialog_details = false; like()" color="primary" :disabled="match_popup">
              <v-icon dark>fa-heart</v-icon>
            </v-btn>
            <v-btn icon dark @click="dialog_details = false">
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <vlove-profile-informations
            v-if="CURRENT_MATCH_USER"
            :medias="CURRENT_MATCH_USER.medias"
            :name="CURRENT_MATCH_USER.username"
            :age="CURRENT_MATCH_USER.birthday | ageFromBirthday"
            :moods="CURRENT_MATCH_USER.mood"
            :description="CURRENT_MATCH_USER.description"
            :gender="CURRENT_MATCH_USER.gender"
            :looking_for="CURRENT_MATCH_USER.lookingfor"
            :size="CURRENT_MATCH_USER.size"
            :weight="CURRENT_MATCH_USER.weight"
            :hair="CURRENT_MATCH_USER.haircolor"
            :eyes="CURRENT_MATCH_USER.eyescolors"
            :silhouette="CURRENT_MATCH_USER.silhouette"
            :userstyle="CURRENT_MATCH_USER.style"
            :city="CURRENT_MATCH_USER.city"
            :distance="CURRENT_MATCH_USER.distance"
            :hobbies="CURRENT_MATCH_USER.hobbies"
            :wine_colors="CURRENT_MATCH_USER.winecolors"
            :wine_regions="CURRENT_MATCH_USER.wineregions"
            :wine_varieties="CURRENT_MATCH_USER.winevarieties"
            :professional="CURRENT_MATCH_USER.wine_professionnal"
            :love_relations="CURRENT_MATCH_USER.love_relations"
            :pro_relations="CURRENT_MATCH_USER.professionnal_relations"
            :friendship_relations="CURRENT_MATCH_USER.friendship_relations"
          ></vlove-profile-informations>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="match_popup" persistent max-width="600px" min-height="250px">
      <v-card>
        <v-card-title class="text-center">
          <span class="headline text-center">It's a match !</span>
        </v-card-title>
        <v-card-text style="min-height: 300px;" class="text-center">
          <transition name="fade" mode="out-in" :duration="{ 'enter': 100, 'leave': 1000 }" >
            <v-img v-if="animate" :height="200" src="@/assets/match.gif" aspect-ratio="1" contain></v-img>
            
          </transition>
          <transition name="fade" mode="in-out" appear :duration="{ 'enter': 100, 'leave': 1000 }">
            <div v-if="!animate && animatein && BOTH_MATCH_USER">
              <v-avatar size="200" >
                <img  :height="300" v-if="BOTH_MATCH_USER" :src="BOTH_MATCH_USER.medias | filterProfilePic(BOTH_MATCH_USER.gender)" alt="avatar" />
              </v-avatar>
            </div>
          </transition>
        </v-card-text>
        <v-card-text v-if="BOTH_MATCH_USER" class="text-center">
          <h3 class="title">You and {{ BOTH_MATCH_USER.username }} have matched!</h3>
        </v-card-text>
        <v-card-actions mt-5>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()">Continue</v-btn>
          <v-btn v-if="BOTH_MATCH_USER" color="blue darken-1" text :to="{ name: 'User', query: { uid: BOTH_MATCH_USER.id } }" >Visit profile</v-btn>
          <v-btn v-if="BOTH_MATCH_USER" color="blue darken-1" text :to="{ name: 'User', query: { uid: BOTH_MATCH_USER.id, 'tab_user': 'chat' } }" >Chat</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_matchlist" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_matchlist = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Matchs</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-matchlist v-if="dialog_matchlist"></vlove-matchlist>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vuex from 'vuex'
import VLoveMatchSheet from '@/components/partial/MatchProfileSheet'
import VLoveProfileSheet from '@/components/partial/ProfileSheet'
import VLoveMatchlist from '@/components/partial/Matchlist'
import moment from 'moment'

export default {
  name: 'match',
  components: {
    'vlove-match-profile-sheet': VLoveMatchSheet,
    'vlove-profile-informations': VLoveProfileSheet,
    'vlove-matchlist': VLoveMatchlist
  },
  computed: {
    ...Vuex.mapGetters([
      'CURRENT_MATCH_USER', 
      'BOTH_MATCH_USER'
    ])
  },
  data () {
    return {
      match_popup: false,
      animate: false,
      animatein: false,
      dialog_details: false,
      dialog_matchlist: false,
      likedTo: false,
      notLikedTo: false,
      illustrations: ['couple-straight-bg.png', 'gay-bg.png', 'kiss-straight-bg.png', 'lesbian-bg.png', 'mature-straight-bg.png', 'mixed-straight-bg.png'],
      current_illustration_id: 0
    }
  },
  mounted: function () {
    this.current_illustration_id = parseInt(parseInt((Math.random() * this.illustrations.length * 10)) % this.illustrations.length)
    console.log(this.current_illustration_id)
    console.log(this.illustrations[Math.max(0, Math.min(this.current_illustration_id, this.illustrations.length))])
    this.$store.dispatch('RELOAD_CURRENT_MATCH')
  },
  watch: {
    BOTH_MATCH_USER: function (val, oldVal) {
      if (val !== null && oldVal !== val) {
        this.match_popup = true
        this.animate = true
        this.animatein = false
        setTimeout(() => {
          this.animate = false
          setTimeout(() => {
            this.animatein = true
          }, 801)
        }, 6000)
      }
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    ageFromBirthday: function (val) {
      return moment.utc(val).fromNow(true)
    }
  },
  methods: { 
    like: async function () {
      if (this.CURRENT_MATCH_USER) {
        this.likedTo = true
        await this.$store.dispatch('MATCH_NOMATCH', {'match': true})
        await this.$store.dispatch('RELOAD_CURRENT_MATCH')
        setTimeout(() => {
          this.likedTo = false
        }, 100)
      }
    },
    dislike: async function () {
      if (this.CURRENT_MATCH_USER) {
        this.notLikedTo = true
        await this.$store.dispatch('MATCH_NOMATCH', {'match': false})
        await this.$store.dispatch('RELOAD_CURRENT_MATCH')
        setTimeout(() => {
          this.notLikedTo = false
        }, 100)
      }
    },
    close: function () {
      this.match_popup = false
      this.animate = false
      this.animatein = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes vineaheartbeat { 
  0% {
    animation-timing-function: cubic-bezier(0.1678,0.6042,0.5465,3.0859);
    transform: scale(1.3);
  }
  30% {
      animation-timing-function: cubic-bezier(0.3206,0.3435,0.6825,0.6598);
      transform: scale(1.15);
  }
  31% {
      animation-timing-function: cubic-bezier(0.2811,0.5061,0.5347,0.909);
      transform: scale(1.1458);
  }
  100% {
      transform: scale(1);
  }
}

.heartbeat-animation {
  transform-origin: 50% 50%; animation: 0.800s linear 0s infinite normal forwards running vineaheartbeat;
}
</style>
