export default {
  getValues (_translator) {
    return {
      'trendy': _translator.t('user.styles.trendy'),
      'sport': _translator.t('user.styles.sport'),
      'classic': _translator.t('user.styles.classic'),
      'bohemian': _translator.t('user.styles.bohemian'),
      'nature': _translator.t('user.styles.nature'),
      'extravagant': _translator.t('user.styles.extravagant'),
      'minimalist': _translator.t('user.styles.minimalist'),
      'other': _translator.t('user.styles.other')
    }
  }
}
