<template>
  <div class="vlove-cheer">
    <v-btn :disabled="IS_CHEER_ACTION_RUNNING" fab dark  :color="!cheers ? 'grey' : 'pink'" v-on:click="cheerUnCheer()">
      <v-icon v-if="!cheers" dark>fa-wine-glass</v-icon>
      <v-icon v-if="cheers" dark>fa-wine-glass-alt</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'vlove-cheer-button',
  props: [
    'cheers',
    'uid',
  ],
  computed: {
    ...Vuex.mapGetters([
      'IS_CHEER_ACTION_RUNNING'
    ])
  },
  mounted: function () {
  },
  methods: {
    cheerUnCheer: async function () {
      await this.$store.dispatch('CHEER_UNCHEER', {'cheer': !this.cheers, 'uid': this.uid})
      this.$emit('need-reload')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>