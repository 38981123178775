export default {
  getValues (_translator) {
    return {
      'skinny': _translator.t('user.silhouettes.skinny'),
      'slim': _translator.t('user.silhouettes.slim'),
      'athletic': _translator.t('user.silhouettes.athletic'),
      'normal': _translator.t('user.silhouettes.normal'),
      'curvaceous': _translator.t('user.silhouettes.curvaceous'),
      'curvy': _translator.t('user.silhouettes.curvy'),
      'other': _translator.t('user.silhouettes.other')
    }
  }
}
