
<template>
  <div class="vlove-friendship-button">
    <div v-if="cache_friend" @mouseover="hover_friend = true" @mouseleave="hover_friend = false">
      <v-btn v-if="hover_friend" :outlined="!hover_friend" :color="'grey lighten-1 white--text'" fab v-on:click="removeFriend()" :disabled="pending_request">
        <v-icon  dark>fa-trash-alt</v-icon>
      </v-btn>
      <v-badge
        v-else
        color="green--text text--lighten-1"
        icon="fas fa-check"
        bottom
        overlap
        avatar
        :offset-x="25"
        :offset-y="25"
      >
        <v-avatar>
          <v-icon color="grey--text text--lighten-1" large>fa-user</v-icon>
        </v-avatar>
      </v-badge>
    </div>
    <v-btn v-else-if="!cache_friend && cache_pendind_friend" fab dark :disabled="true">
      <v-badge
        color="green--text text--lighten-1"
        icon="fas fa-clock"
        bottom
        overlap
        avatar
        :offset-x="25"
        :offset-y="25"
      >
        <v-avatar>
          <v-icon color="grey--text text--lighten-1" large>fa-user</v-icon>
        </v-avatar>
      </v-badge>
    </v-btn>
    <v-btn v-else-if="!cache_friend && !cache_pendind_friend && cache_pending_invitation" fab dark v-on:click="acceptFriend()" :disabled="pending_request">
      <v-icon  dark>fa-check-circle</v-icon>
    </v-btn>
    <v-btn v-else fab dark v-on:click="askAsFriend()" :disabled="cache_friend">
      <v-icon  dark>fa-user-plus</v-icon>
    </v-btn>
    <v-btn v-if="!cache_friend && !cache_pendind_friend && cache_pending_invitation" fab dark v-on:click="refuseFriend()" :disabled="pending_request">
      <v-icon  dark>fa-times-circle</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'vlove-friendship-button',
  props: [
    'uid',
    'friend',
    'pendind_friend',
    'pending_invitation'
  ],
  data () {
    return {
      hover_friend: false,
      cache_friend: null,
      cache_pendind_friend: null,
      cache_pending_invitation: null,
      pending_request: false
    }
  },
  watch: {
    friend : function (val) {
      this.cache_friend = val
    },
    pendind_friend : function (val) {
      this.cache_pendind_friend = val
    },
    pending_invitation : function (val) {
      this.cache_pending_invitation = val
    }
  },
  mounted: function () {
      this.cache_friend = this.friend
      this.cache_pendind_friend = this.pendind_friend
      this.cache_pending_invitation = this.pending_invitation
  },
  methods: {
    askAsFriend: async function () {
      if (!this.cache_friend) {
        this.pending_request = true
        let ret = await this.$store.dispatch('ASK_AS_FRIEND', {'uid': this.uid})
        this.pending_request = false
        if (ret) {
          this.cache_pendind_friend = !this.cache_pendind_friend
        }
        this.$emit('need-reload')
      }
    },
    removeFriend: async function () {
      if (this.cache_friend) {
        this.pending_request = true
        let ret = await this.$store.dispatch('REMOVE_FRIENDSHIP', {'uid': this.uid})
        this.pending_request = false
        if (ret) {
          this.cache_friend = !this.cache_friend
        }
        this.$emit('need-reload')
      }
    },
    acceptFriend: async function () {
      if (!this.cache_friend) {
        this.pending_request = true
        let ret = await this.$store.dispatch('ACCEPT_FRIENDSHIP', {'uid': this.uid})
        this.pending_request = false
        if (ret) {
          this.cache_friend = true
          this.cache_pending_invitation = !this.cache_pending_invitation
        }
        this.$emit('need-reload')
      }
    },
    refuseFriend: async function () {
      if (!this.cache_friend) {
        this.pending_request = true
        let ret = await this.$store.dispatch('REFUSE_FRIENDSHIP', {'uid': this.uid})
        this.pending_request = true
        if (ret) {
          this.cache_friend = false
          this.cache_pending_invitation = !this.cache_pending_invitation
        }
        this.$emit('need-reload')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
