export default {
  getValues (_translator) {
    return {
      'brown': _translator.t('user.eyescolors.brown'),
      'blue': _translator.t('user.eyescolors.blue'),
      'green': _translator.t('user.eyescolors.green'),
      'noisette': _translator.t('user.eyescolors.noisette'),
      'vairons': _translator.t('user.eyescolors.vairons'),
      'grey_blue': _translator.t('user.eyescolors.grey_blue')
    }
  }
}
