<template>
  <div class="vlove-match-profile-sheet grey--text text--darken-3">
    <v-card
      max-width="400px"
      
      style="border-radius: 24px!important; height: 70vh; overflow: hidden;"
      >
      <v-layout
        column
        align-center
        justify-center
      >
        <v-card-title>
            <v-avatar
                center
                :size="300"
                aspect-ratio="1"
                color="grey lighten-4"
            class="match-avatar"
                >
                <v-img cover :src="picture | filterProfilePic(gender)" alt="avatar"></v-img>
                <v-btn
                  absolute
                  fab
                  bottom
                  left
                  color="white secondary--text"
                  dense
                  v-on:click="showDetails()"
                >
                  <v-icon large dense>fa-info</v-icon>
                </v-btn>
                <!-- <v-btn
                  absolute
                  fab
                  bottom
                  right
                  color="white amber--text text-darken-1"
                  dense
                >
                  <v-icon large dense>mdi-star</v-icon>
                </v-btn> -->
            </v-avatar>
        </v-card-title>
        <v-card-title>
          <div class="text-center grey--text text--darken-3">
            <h3 class="display-1 mb-1 text-capitalize" v-if="name">{{ name }}<small v-if="age">, {{ age }}</small></h3>
          </div>
        </v-card-title>
        <v-card-text class="text-center grey--text text--darken-3" style="text-overflow: ellipsis; overflow: hidden; height: 6.5rem;" >
          <h6 class="text-heading-6" style="height: 4rem; text-overflow: ellipsis; overflow: hidden;"><em>{{ moods | cutLength(40) }}</em></h6>
          <p style="height: 3rem; text-overflow: ellipsis; overflow: hidden;">{{ description | cutLength(100) }}</p>
        </v-card-text>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'vlove-match-profile-sheet',
  props: [
    'picture',
    'age',
    'name',
    'moods',
    'description',
    'gender'
  ],
  watch: {
  },
  data () {
    return {
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    filterDistance: function (_distance) {
      if (_distance && ("" + _distance).length > 0) {
        return Math.round(_distance) + ' Km'
        // todo conversion en miles suivant locale
      }
      return ''
    },
    cutLength: function (_word, _length) {
      return _word ? _word.substring(0, _length) : ''
    }
  },
  mounted: function () {
  },
  methods: {
    showDetails () {
      this.$emit('click-details')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.match-avatar {
  overflow: visible;
}

</style>
