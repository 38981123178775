<template>
  <div class="chatlist">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1 class="display-2">{{ $t('views.search_vinealovers.title') }}<br/><small>{{ $t('views.search_vinealovers.subtitle') }}</small></h1>
          <p>{{ $t('views.search_vinealovers.description') }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-form v-model="search_valid">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-card>
                    <v-card-title>{{ $t('views.search_vinealovers.title_part_search') }}</v-card-title>
                      <v-card-text>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" md="6">
                                <v-select
                                  :items="lookinggendersavailable"
                                  v-model="lookinggenders"
                                  chips
                                  :label="$t('views.search_vinealovers.label_lookingfor')"
                                  persistent-hint
                                  :hint="$t('views.search_vinealovers.hint_lookingfor')"
                                  v-on:change="saveCurrentSearch ()"
                                ></v-select>
                            </v-col>
                            <!-- <v-flex xs12 sm6 d-flex >
                              <v-select
                                :items="genders"
                                v-model="arelookinggenders"
                                label="Interested in ?"
                                multiple
                                chips
                              ></v-select>
                            </v-flex> -->
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <p class="mb-0 mt-2">{{ $t('views.search_vinealovers.label_people_age',{'age_min': age.min, 'age_max': age.max}) }}</p>
                              <q-range
                                v-model="age"
                                :max="99"
                                :min="18"
                                :step="1"
                                color="primary"
                                v-on:change="saveCurrentSearch ()"
                              />
                              <!-- <v-range-slider
                                :label="$t('views.search_vinealovers.label_people_age',{'age_min': age[0], 'age_max': age[1]})"
                                v-model="age"
                                :max="99"
                                :min="18"
                                :step="1"
                                thumb-color="primary"
                                v-on:change="saveCurrentSearch ()"
                              ></v-range-slider> -->
                            </v-col>
                          </v-row>
                        <!-- thumb-label="always" -->
                        </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card>
                    <v-card-text>
                      <v-checkbox v-model="location_activation" :label="$t('views.search_vinealovers.label_location_activation')" v-on:change="saveCurrentSearch ()"></v-checkbox>
                      <v-autocomplete
                        v-model="location"
                        :items="cities"
                        :loading="isLoadingCities"
                        :search-input.sync="searchCities"
                        @click.clear="location = null; cities = []"
                        auto-select-first
                        outlined
                        clearable
                        hide-selected
                        :hint="$t('views.search_vinealovers.hint_location')"
                        persistent-hint
                        :filter="v => v"
                        color="white"
                        :label="$t('views.search_vinealovers.label_location')"
                        :placeholder="$t('views.search_vinealovers.placeholder_location')"
                        v-on:change="saveCurrentSearch ()"
                      >
                      <v-icon bottom slot="prepend">fa-search-location</v-icon>
                      </v-autocomplete>
                      <!-- <v-slider
                        :disabled="!location_activation"
                        :label="$t('views.search_vinealovers.label_distance',{'range_location': range_location, 'unit': 'Km'})"
                        v-model="range_location"
                        :max="3000"
                        :min="10"
                        :step="range_location > 1900 ? 500 : (range_location > 980 ? 100 : 10)"
                        thumb-color="primary"
                        v-on:change="saveCurrentSearch ()"
                      ></v-slider> -->
                      <p class="mb-0 mt-2">{{ $t('views.search_vinealovers.label_distance',{'range_location': range_location, 'unit': 'Km'}) }}</p>
                      <q-slider 
                        :disabled="!location_activation"
                        v-model="range_location"
                        :max="3000"
                        :min="10"
                        :step="range_location > 1900 ? 500 : (range_location > 980 ? 100 : 10)"
                        v-on:change="saveCurrentSearch ()"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title v-on:click="setAdvancedOpenned()" >
                      <h3 class="subtitle-2">{{ $t('views.search_vinealovers.title_part_advancedsearch') }}</h3><v-icon color="primary">$vuetify.icons.expand</v-icon>
                    </v-card-title>
                    <v-card-text v-if="advancedsearch">
                      <v-text-field
                        v-model="search"
                        :label="$t('views.search_vinealovers.label_searchname')"
                        :hint="$t('views.search_vinealovers.hint_searchname')"
                        persistent-hint
                        required
                        v-on:change="saveCurrentSearch ()"
                      ></v-text-field>
                      <p class="mb-0 mt-2">{{ $t('views.search_vinealovers.label_size',{'min': size.min , 'max': size.max, 'unit': 'cm'}) }}</p>
                      <q-range
                        v-model="size"
                        :max="250"
                        :min="100"
                        :step="1"
                        color="primary"
                        v-on:change="saveCurrentSearch ()"
                      />
                      <p class="mb-0 mt-2">{{ $t('views.search_vinealovers.label_weight',{'min': weight.min , 'max': weight.max, 'unit': 'Kg'}) }}</p>
                      <q-range
                        v-model="weight"
                        :max="180"
                        :min="35"
                        :step="1"
                        color="primary"
                        v-on:change="saveCurrentSearch ()"
                      />
                        <!-- v-on:change="saveCurrentSearch ()" -->
                      <!-- <v-range-slider
                        :label="$t('views.search_vinealovers.label_size',{'min': size[0] , 'max': size[1], 'unit': 'cm'})"
                        v-model="size"
                        :max="250"
                        :min="100"
                        :step="1"
                        thumb-color="primary"
                        v-on:change="saveCurrentSearch ()"
                      ></v-range-slider> -->
                      <!-- <v-range-slider
                        :label="$t('views.search_vinealovers.label_weight',{'min': weight[0] , 'max': weight[1], 'unit': 'Kg'})"
                        v-model="weight"
                        :max="180"
                        :min="35"
                        :step="1"
                        thumb-color="primary"
                        v-on:change="saveCurrentSearch ()"
                      ></v-range-slider> -->
                      <v-select
                        :items="silhouettes"
                        v-model="silhouette"
                        :label="$t('views.search_vinealovers.label_silhouette')"
                        multiple
                        chips
                        v-on:change="saveCurrentSearch ()"
                      ></v-select>
                      <v-select
                        :items="styles"
                        v-model="style"
                        :label="$t('views.search_vinealovers.label_style')"
                        multiple
                        chips
                        v-on:change="saveCurrentSearch ()"
                      ></v-select>
                      <v-autocomplete
                        :items="available_hobbies"
                        v-model="loves"
                        :label="$t('views.search_vinealovers.label_hobbies')"
                        multiple
                        chips
                        v-on:change="saveCurrentSearch ()"
                      ></v-autocomplete>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn v-on:click.stop="searchUser()">{{ $t('views.search_vinealovers.label_validbutton') }}</v-btn><br />
                  <p v-if="results && results_pagination" class="mt-1"><em class="grey--text"><span >{{ $t('views.search_vinealovers.nb_results_found', {'number': results_pagination.total}) }}</span></em></p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
      <v-row v-if="!results || !results_pagination || results_pagination.total <= 0" class="text-center">
        <v-col>
          <v-img class="mt-2" :height="200" src="@/assets/logo-vinealove-noresult.png" aspect-ratio="1" contain ></v-img>
          <h4>{{ $t('views.search_vinealovers.no_results_title') }}</h4><br/>
          <p>{{ $t('views.search_vinealovers.no_results_desc') }}</p>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="12">
          <v-pagination
            v-if="results_pagination && typeof results_pagination.total !== 'undefined'"
            v-model="current_page_search"
            :length="parseInt(Math.floor(results_pagination.total / 10)) + 1"
            :total-visible="Math.min(results_pagination.total , 7)"
            circle
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="4"
          d-flex
          v-for="(user, index) in results"
          :key="index"
        >
          <vlove-small-profile-sheet
            @need-reload="searchUser()"
            :picture="user.medias | filterProfilePic"
            :name="user.username"
            :moods="user.mood"
            :hashtags="user.hashtags || []"
            :cheers="user.cheered_by_me"
            :friend="user.friendship_with_me"
            :pendind_friend="user.friendship_pending_with_me"
            :pending_invitation="user.invitation_pending_with_me"
            :distance="user.distance"
            :age="user.age"
            :gender="user.gender"
            :uid="user.id"></vlove-small-profile-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '@/api/api'
import Vuex from 'vuex'
import VLoveSmallSheet from '@/components/partial/SmallProfileSheet'
import lookinggendersconsts from '@/const/lookinggenders'
import styles from '@/const/styles'
import silhouettes from '@/const/silhouettes'
import hobbies from '@/const/hobbies'
import eyescolors from '@/const/eyescolors'
import haircolors from '@/const/haircolors'
import winecolors from '@/const/winecolors'
import wineregions from '@/const/wineregions'
import winevarieties from '@/const/winevarieties'

export default {
  name: 'chatlist',
  components: {
    'vlove-small-profile-sheet': VLoveSmallSheet
  },
  data () {
    return {
      location_activation: true,
      advancedsearch: false,
      age: {min: 18, max: 99},
      size: {min: 140, max: 220},
      weight: {min: 50, max: 150},
      search: '',
      search_valid: false,
      location: null,
      range_location: 150,
      // genders: ['man', 'woman', 'unknown'],
      lookinggendersavailable: Object.keys(lookinggendersconsts.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': lookinggendersconsts.getValues(this.$root.$i18n)[val] } }),
      lookinggenders: ['man'],
      arelookinggenders: ['woman'],
      silhouette: ['any'],
      silhouettes: Object.keys(silhouettes.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': silhouettes.getValues(this.$root.$i18n)[val] } }),
      style: ['any'],
      styles: Object.keys(styles.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': styles.getValues(this.$root.$i18n)[val] } }),
      available_hobbies: Object.keys(hobbies.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': hobbies.getValues(this.$root.$i18n)[val] } }),
      available_eyes_colors: Object.keys(eyescolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': eyescolors.getValues(this.$root.$i18n)[val] } }),
      available_hair_colors: Object.keys(haircolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': haircolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_regions: Object.keys(wineregions.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': wineregions.getValues(this.$root.$i18n)[val] } }),
      available_wine_colors: Object.keys(winecolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winecolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_varieties: Object.keys(winevarieties.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winevarieties.getValues(this.$root.$i18n)[val] } }),
      loves: [],
      cities: [],
      countCities: 0,
      isLoadingCities: false,
      searchCities: null,
      current_page_search: 1
    }
  },
  computed: {
    ...Vuex.mapGetters({
      'results': 'SEARCH_RESULT_LIST',
      'results_pagination': 'SEARCH_RESULT_PAGINATION',
      'username': 'username',
      'U_GENDER': 'U_GENDER',
      'U_LOOKING_FOR': 'U_LOOKING_FOR',
      'U_OLD_AGE': 'U_OLD_AGE',
      'U_SIZE': 'U_SIZE',
      'U_WEIGHT': 'U_WEIGHT',
      'U_SILHOUETTE': 'U_SILHOUETTE',
      'U_STYLE': 'U_STYLE',
      'U_HOBBIES': 'U_HOBBIES',
      'U_CITY': 'U_CITY',
      'U_BIRTHDAY': 'U_BIRTHDAY',
      'U_LAT': 'U_LAT',
      'U_LON': 'U_LON',
      'token': 'token',
      'PREVIOUS_SEARCH': 'PREVIOUS_SEARCH'
    })
  },
  filters: {
    filterProfilePic: function (_medias) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return null
    }
  },
  mounted: function () {
    // console.log(this.PREVIOUS_SEARCH)
    if (this.PREVIOUS_SEARCH && Object.values(this.PREVIOUS_SEARCH).length > 0) {
        // console.log(this.PREVIOUS_SEARCH)
        if (this.U_OLD_AGE) {
          this.age = {min: Math.min(Math.max((this.PREVIOUS_SEARCH['age_min'] || (this.U_OLD_AGE - 10)), 18), 99), max: Math.min(Math.max((this.PREVIOUS_SEARCH['age_max'] || (this.U_OLD_AGE + 10)), 18), 99)}
        } else {
          this.age = {min: Math.min(Math.max((this.PREVIOUS_SEARCH['age_min'] || 18), 18), 99), max: Math.min(Math.max((this.PREVIOUS_SEARCH['age_max'] || 99) + 10, 18), 99)}
        }
        this.lookinggenders = this.PREVIOUS_SEARCH['looking_for']
        this.location_activation = this.PREVIOUS_SEARCH['location_activation']
        this.range_location = this.PREVIOUS_SEARCH['distance']
        var objc = {
          'formatted_address': this.PREVIOUS_SEARCH['city'],
          'lat': this.PREVIOUS_SEARCH['latitude'],
          'lon': this.PREVIOUS_SEARCH['longitude'],
          'city': this.PREVIOUS_SEARCH['city'],
          'country': this.PREVIOUS_SEARCH['country']
        }
        this.cities = [{
          'text': objc['city'],
          'value': objc
        }]
        this.location = objc
        this.search = this.PREVIOUS_SEARCH['term']
        this.silhouette = this.PREVIOUS_SEARCH['silhouette']
        this.style = this.PREVIOUS_SEARCH['style']
        this.loves = this.PREVIOUS_SEARCH['hobbies']
        this.size = {min: Math.min(Math.max((this.PREVIOUS_SEARCH['size_min'] || 140), 100), 250), max: Math.min(Math.max((this.PREVIOUS_SEARCH['size_max'] || 140), 100), 250)}
        this.weight = {min: Math.min(Math.max((this.PREVIOUS_SEARCH['weight_min'] || 35), 35), 180), max: Math.min(Math.max((this.PREVIOUS_SEARCH['weight_max'] || 99), 35), 180)}
        this.advancedsearch = this.PREVIOUS_SEARCH['advanced_search']
    } else {
      if (this.U_OLD_AGE) {
        this.age = {min: Math.min(Math.max(this.U_OLD_AGE - 10, 18), 99), max: Math.min(Math.max(this.U_OLD_AGE + 10, 18), 99)}
      } else {
        this.age = {min: 18, max: 99}
      }
      this.range_location = 150
        var obj = {
        'formatted_address': this.U_CITY,
        'city': this.U_CITY,
        'lon': this.U_LON,
        'lat': this.U_LAT,
        'country': this.U_COUNTRY
      }
      this.cities = [{
        'text': this.U_CITY,
        'value': obj
      }]
      this.location = obj
      this.lookinggenders = this.U_LOOKING_FOR
      this.arelookinggenders = this.U_GENDER
    }
    // this.loves = Array.isArray(this.U_HOBBIES) ? this.U_HOBBIES : []
  },
  methods: {
    saveCurrentSearch () {
      // console.log('change')
      var search = {
        'age_min': this.age && this.age.min ?  this.age.min : undefined,
        'age_max': this.age && this.age.max ?  this.age.max : undefined,
        'looking_for': this.lookinggenders || undefined,
        'location_activation': this.location_activation,
        'distance': this.range_location,
        'latitude': this.location && this.location.lat ? this.location.lat : undefined,
        'longitude': this.location && this.location.lon ? this.location.lon : undefined,
        'city': this.location && this.location.city ? this.location.city : undefined,
        'country': this.location && this.location.country ? this.location.country : undefined,
        'term': this.search,
        'silhouette': this.silhouette,
        'style': this.style,
        'hobbies': this.loves,
        'size_min': this.size && this.size.min ?  this.size.min : undefined,
        'size_max': this.size && this.size.max ?  this.size.max : undefined,
        'weight_min': this.weight && this.weight.min ?  this.weight.min : undefined,
        'weight_max': this.weight && this.weight.max ?  this.weight.max : undefined,
        'advanced_search': this.advancedsearch
      }
      // console.log(search)
      this.$store.dispatch('SAVE_CURRENT_SEARCH_PARAMETERS', search)
    },
    searchUser: function () {
      this.saveCurrentSearch()
      this.current_page_search = 1
      // age: [18, 99],
      // search: '',
      // search_valid: false,
      // location: 'Montpellier, France',
      // range_location: 30,
      // silhouette: ['any'],
      // available_hobbies: ['tasting', 'trips', 'wine tourism', 'reading', 'cinema', 'theater', 'dance', 'music', 'sport', 'politics', 'economy', 'technology', 'TV series', 'farniente', 'outings', 'friends', 'gastronomy'],
      // loves: []
      var search = {
        'age_min': this.age.min,
        'age_max': this.age.max,
        'looking_for': this.lookinggenders
      }
      if (this.location_activation) {
        search['distance'] = this.range_location
        if (this.location && this.location.lat) {
          search['latitude'] = this.location.lat || undefined
        }
        if (this.location && this.location.lon) {
          search['longitude'] = this.location.lon || undefined
        }
      }
      if (this.advancedsearch) {
        if (this.search && this.search.trim().length > 0) {
          search['term'] = this.search
        }
        if (this.silhouette.length > 0 && this.silhouette.indexOf('any') === -1) {
          search['silhouette'] = this.silhouette
        }
        if (this.style.length > 0 && this.style.indexOf('any') === -1) {
          search['style'] = this.style
        }
        if (this.loves.length > 0) {
          search['hobbies'] = this.loves
        }
        if (Object.values(this.size).length > 1) {
          search['size_min'] = this.size.min
          search['size_max'] = this.size.max
        }
        if (this.weight.length > 1) {
          search['weight_min'] = this.weight.min
          search['weight_max'] = this.weight.max
        }
      }
      // 'term': payload.term || undefined,
      // 'age_min': payload.age_min || undefined,
      // 'age_max': payload.age_max || undefined,
      // 'silhouette': payload.silhouette || undefined,
      // 'style': payload.style || undefined,
      // 'hobbies': payload.hobbies || undefined,
      // 'wine_professionnal': payload.wine_professionnal || undefined,
      // 'size_min': payload.size_min || undefined,
      // 'size_max': payload.size_max || undefined,
      // 'weight_min': payload.weight_min || undefined,
      // 'weight_max': payload.weight_max || undefined,
      // 'order': payload.order || undefined,
      // 'distance': payload.distance || undefined,
      // 'latitude': payload.latitude || undefined,
      // 'longitude': payload.longitude || undefined
      search['page'] = this.current_page_search - 1;
      this.$store.dispatch('RELOAD_CURRENT_SEARCH_RESULTS', search)
    },
    changePage() {
      var search = {
        'age_min': this.age.min,
        'age_max': this.age.max,
        'looking_for': this.lookinggenders
      }
      if (this.location_activation) {
        search['distance'] = this.range_location
        if (this.location && this.location.lat) {
          search['latitude'] = this.location.lat || undefined
        }
        if (this.location && this.location.lon) {
          search['longitude'] = this.location.lon || undefined
        }
      }
      if (this.advancedsearch) {
        if (this.search && this.search.trim().length > 0) {
          search['term'] = this.search
        }
        if (this.silhouette.length > 0 && this.silhouette.indexOf('any') === -1) {
          search['silhouette'] = this.silhouette
        }
        if (this.style.length > 0 && this.style.indexOf('any') === -1) {
          search['style'] = this.style
        }
        if (this.loves.length > 0) {
          search['hobbies'] = this.loves
        }
        if (Object.values(this.size).length > 1) {
          search['size_min'] = this.size.min
          search['size_max'] = this.size.max
        }
        if (this.weight.length > 1) {
          search['weight_min'] = this.weight.min
          search['weight_max'] = this.weight.max
        }
      }
      search['page'] = this.current_page_search - 1;
      this.$store.dispatch('RELOAD_CURRENT_SEARCH_RESULTS', search)
    },
    setAdvancedOpenned () {
      this.advancedsearch = !this.advancedsearch
      this.saveCurrentSearch()
    }
  },
  watch: {
    current_page_search: function () {
      this.changePage();
    },
    searchCities: function (newval) {
      if (!(newval && (this.location === null || newval.length <= 2 || newval !== this.location.formatted_address))) {
        return
      }
      this.isLoadingCities = true
      api.get_request({}, '/api/geocode', this.token, {'term': newval})
        .then(res => {
          var arr = []
          for (let i = 0; i < res.length; i++) {
            arr.push({
              'text': res[i]['formatted_address'],
              'value': res[i]
            })
          }
          this.countCities = res.length
          this.cities = arr
        })
        .catch(() => {
          // console.log(err)
        })
        .finally(() => (this.isLoadingCities = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
