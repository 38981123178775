<template>
  <div class="register">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container fluid>
      <v-stepper v-model="complete_step">
        <v-stepper-header>

          <v-stepper-step :complete="complete_step > 1" step="1">{{ $t('views.complete_registration.tab_title_1') }}</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="complete_step > 2" step="2">{{ $t('views.complete_registration.tab_title_2') }}</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">{{ $t('views.complete_registration.tab_title_3') }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>

          <v-stepper-content step="1">
            <v-layout row>
              <vlove-payment v-if="complete_step === 1 && !overlay" v-on:completedpayment="completedPayment()"></vlove-payment>
           </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-layout row wrap>
              <v-flex xs12>
                <h2>
                  {{ $t('views.complete_registration.message_waitpayment') }}
                  
                </h2>
                <div>
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-flex>
           </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3" class="vinea-stepper">
            <div class="text-center"> 
              <h3 class="display-1">{{ $t('views.complete_registration.tab_title_3') }}</h3>
              <img class="mt-3" style="width: 128px;" src="@/assets/champagne.png" />
              <h6>{{ $t('views.complete_registration.tab_title_4') }}</h6>
            </div>
            <v-card elevation="0">
                  <v-card-text>
            <v-tabs-items v-model="tunnel" :touchless="true">
              <v-tab-item
                :value="'gender'"
              >
                <v-form ref="completeform_gender" v-model="valid['gender']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                        <!-- <p>{{ $t('views.complete_registration.tab_title_4') }}</p> -->
                        <v-select
                          required
                          outlined
                          v-model="gender"
                          :items="genders"
                          :error-messages="errors.gender"
                          :label="$t('views.complete_registration.label_gender') + ' *'"
                          :hint="$t('views.complete_registration.hint_gender')"
                        ></v-select>
                        <small class="primary--text" v-if="!valid['gender']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="#bda273"
                          text
                          
                          :disabled="!valid['gender']"
                          @click="nextStep('gender')"
                        >
                          {{ $t('views.complete_registration.label_nextstepbutton') }}
                        </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'relationprofessionnal'"
              >
                <v-form ref="completeform_relationprofessionnal" v-model="valid['relationprofessionnal']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-switch
                    class="ml-3"
                    :label="wine_professionnal ? $t('views.complete_registration.label_wineworker_ok') : $t('views.complete_registration.label_wineworker_nok')"
                    v-model="wine_professionnal"
                    :error-messages="errors.wine_professionnal"
                  ></v-switch>
                  <small class="primary--text" v-if="!valid['relationprofessionnal']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                  <p>{{ $t('views.complete_registration.label_relation') }}</p>
                  <v-checkbox v-model="love_relations" :label="$t('user.relations.love')"></v-checkbox>
                  <v-checkbox v-model="professionnal_relations" :label="$t('user.relations.professionnal')"></v-checkbox>
                  <v-checkbox v-model="friendship_relations" :label="$t('user.relations.friendship')"></v-checkbox>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                        icon
                        text
                    color="grey"
                    
                    @click="previousStep('relationprofessionnal')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    
                    :disabled="!valid['relationprofessionnal']"
                    @click="nextStep('relationprofessionnal')"
                  >
                    {{ $t('views.complete_registration.label_nextstepbutton') }} <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'looking_for'"
              >
                <v-form ref="completeform_looking_for" v-model="valid['looking_for']" v-on:input="onInput">
                  <v-card>
                    <v-card-text>
                      <v-select
                        outlined
                        v-model="looking_for"
                        :items="lookinggenders"
                        :error-messages="errors.lookingfor"
                        :label="$t('views.complete_registration.label_lookingfor')"
                        :hint="$t('views.complete_registration.hint_lookingfor')"
                      ></v-select>
                      <small class="primary--text" v-if="!valid['looking_for']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        icon
                        text
                        color="grey"
                        @click="previousStep('looking_for')"
                      >
                        <v-icon>mdi-arrow-left-thick</v-icon>
                      </v-btn>
                      <v-btn
                        color="#bda273"
                        text
                        :disabled="!valid['looking_for']"
                        @click="nextStep('looking_for')"
                      >
                        {{ $t('views.complete_registration.label_nextstepbutton') }} <v-icon>mdi-arrow-right-thick</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'birthday'"
              >
                <v-form ref="completeform_birthday" v-model="valid['birthday']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-menu
                    ref="menubirthday"
                    v-model="menubirthday"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="birthday"
                        :label="$t('views.complete_registration.label_birthday')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="birthday" 
                      :locale="$i18n.locale"
                      :max="maxdate"
                      min="1900-01-01"
                      ref="pickercomplete"></v-date-picker>
                  </v-menu>
                  <small class="primary--text" v-if="!valid['birthday']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                        icon
                        text
                    color="grey"
                    
                    @click="previousStep('birthday')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    
                    :disabled="!valid['birthday']"
                    @click="nextStep('birthday')"
                  >
                    {{ $t('views.complete_registration.label_nextstepbutton') }} <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'location'"
              >
                <v-form ref="completeform_location" v-model="valid['location']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-autocomplete
                    class="mb-3"
                    v-model="current_city"
                    :items="cities"
                    :loading="isLoadingCities"
                    :search-input.sync="searchCities"
                    auto-select-first
                    outlined
                    clearable
                    hide-selected
                    color="white"
                    :hint="$t('views.complete_registration.hint_location')"
                    :label="$t('views.complete_registration.label_location')"
                    :placeholder="$t('views.complete_registration.placeholder_location')"
                    :filter="v => v"
                    autocomplete="none"
                    Xautocomplete="off"
                    xbrowser-autocomplete="none"
                    persistent-hint
                  ></v-autocomplete>
                  <small class="primary--text" v-if="!valid['location']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                        icon
                        text
                    color="grey"
                    
                    @click="previousStep('location')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                        text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('location')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['location']"
                    @click="nextStep('location')"
                  >
                    <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'winecolors'"
              >
                <v-form ref="completeform_winecolors" v-model="valid['winecolors']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-autocomplete
                    outlined
                    v-model="winecolors"
                    :items="available_wine_colors"
                    :hint="$t('views.complete_registration.hint_winecolors')"
                    :label="$t('views.complete_registration.label_winecolors')"
                    persistent-hint
                    :error-messages="errors.winecolors"
                    multiple
                    class="mb-3"
                  ></v-autocomplete>
                  <small class="primary--text" v-if="!valid['winecolors']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                        icon
                        text
                    color="grey"
                    @click="previousStep('winecolors')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('winecolors')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['winecolors']"
                    @click="nextStep('winecolors')"
                  >
                    <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'wineregions'"
              >
                <v-form ref="completeform_wineregions" v-model="valid['wineregions']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-autocomplete
                    outlined
                    v-model="wineregions"
                    :items="available_wine_regions"
                    persistent-hint
                    :hint="$t('views.complete_registration.hint_wineregions')"
                    :label="$t('views.complete_registration.label_wineregions')"
                    :error-messages="errors.wineregions"
                    multiple
                    class="mb-3"
                  ></v-autocomplete>
                  <small class="primary--text" v-if="!valid['wineregions']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    icon
                    color="grey"
                    @click="previousStep('wineregions')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('wineregions')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['wineregions']"
                    @click="nextStep('wineregions')"
                  >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'winevarieties'"
              >
                <v-form ref="completeform_winevarieties" v-model="valid['winevarieties']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-autocomplete
                    outlined
                    v-model="winevarieties"
                    :items="available_wine_varieties"
                    persistent-hint
                    :hint="$t('views.complete_registration.hint_winevarieties')"
                    :label="$t('views.complete_registration.label_winevarieties')"
                    :error-messages="errors.wineregions"
                    multiple
                    class="mb-5"
                  ></v-autocomplete>
                  <small class="primary--text" v-if="!valid['winevarieties']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    color="grey"
                    icon
                    @click="previousStep('winevarieties')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('winevarieties')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['winevarieties']"
                    @click="nextStep('winevarieties')"
                  >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'picture'"
              >
                <v-card>
                  <div v-if="!isPictureWithCamera">
                    <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
                    <v-card-text>
                      <v-file-input accept="image/*" @click:clear="removeImage" prepend-icon="mdi-camera" name="mypicture" show-size v-model="fileinput" :rules="picrules"></v-file-input>
                    </v-card-text>
                  </div>
                  <div v-else>
                    <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
                    <v-card-text>
                      <p><v-file-input v-if="!isMobileWithGallery" prepend-icon="mdi-camera" @click:clear="removeImage" accept="image/*" show-size name="mypicture" @change="onFileChange" :rules="picrules"></v-file-input></p>
                      <p><v-btn v-on:click="getPictureFromCamera" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_camera') }}</v-btn></p>
                      <p><v-btn v-if="isMobileWithGallery" v-on:click="getPictureFromGallery" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_gallery') }}</v-btn></p>
                    </v-card-text>
                  </div>
                  <div v-if="newProfilePicture">
                    <v-img :src="newProfilePicture" height="125px" contain></v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <button @click="removeImage"><v-icon>delete_forever</v-icon></button>
                    </v-card-actions>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="grey"
                    
                    @click="previousStep('picture')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                    <v-btn 
                    color="#bda273" :disabled="!newProfilePicture" text @click="savePicture('picture')">{{ $t('views.complete_registration.label_nextstepbutton') }} <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
                  </v-card-actions>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :value="'description'"
              >
                <v-form ref="completeform_description" v-model="valid['description']" v-on:input="onInput">
                <v-card>
                  <v-card-text>
                    <v-textarea
                    class="mb-3"
                    required
                    :rules="[required]"
                    v-model="description"
                    outlined
                    :error-messages="errors.description"
                    :label="$t('views.complete_registration.label_description') + ' *'"
                    value=""
                    :placeholder="$t('views.complete_registration.placeholder_description')"
                  ></v-textarea>
                  <small class="primary--text" v-if="!valid['description']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    color="grey"
                    
                    @click="previousStep('description')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    
                    :disabled="!valid['description']"
                    @click="nextStep('description')"
                  >
                    {{ $t('views.complete_registration.label_nextstepbutton') }} <v-icon>mdi-arrow-right-thick</v-icon>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'sizeandweight'"
              >
                <v-form ref="completeform_sizeandweight" v-model="valid['sizeandweight']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-text-field
                    v-model="size"
                    :label="$t('views.complete_registration.label_size')"
                    suffix="cm"
                    mask="###"
                    outlined
                    :rules="[intRule]"
                    :error-messages="errors.size"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="weight"
                    :label="$t('views.complete_registration.label_weight')"
                    mask="###"
                    outlined
                    :rules="[intRule]"
                    suffix="kg"
                    :error-messages="errors.weight"
                    required
                  ></v-text-field>
                  <v-select
                    class="mb-3"
                    v-model="eyescolors"
                    :items="available_eyes_colors"
                    outlined
                    :error-messages="errors.eyescolors"
                    :label="$t('views.complete_registration.label_eyescolors')"
                    :hint="$t('views.complete_registration.hint_eyescolors')"
                    persistent-hint
                  ></v-select>
                  <v-select
                    class="mb-3"
                    v-model="haircolor"
                    :items="available_hair_colors"
                    outlined
                    :error-messages="errors.haircolor"
                    :label="$t('views.complete_registration.label_haircolor')"
                    :hint="$t('views.complete_registration.hint_haircolor')"
                    persistent-hint
                  ></v-select>
                  <small class="primary--text" v-if="!valid['sizeandweight']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    icon
                    color="grey"
                    
                    @click="previousStep('sizeandweight')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('sizeandweight')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['sizeandweight']"
                    @click="nextStep('sizeandweight')"
                  >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'silhouetteandstyle'"
              >
                <v-form ref="completeform_silhouetteandstyle" v-model="valid['silhouetteandstyle']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-select
                    class="mb-3"
                    v-model="silhouette"
                    :items="silhouettes"
                    outlined
                    :error-messages="errors.silhouette"
                    :label="$t('views.complete_registration.label_silhouette')"
                    :hint="$t('views.complete_registration.hint_silhouette')"
                    persistent-hint
                  ></v-select>
                  <v-select
                    class="mb-3"
                    outlined
                    v-model="style"
                    :items="styles"
                    :label="$t('views.complete_registration.label_style')"
                    :hint="$t('views.complete_registration.hint_style')"
                    :error-messages="errors.style"
                    persistent-hint
                  ></v-select>
                  <small class="primary--text" v-if="!valid['silhouetteandstyle']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    color="grey"
                    icon
                    
                    @click="previousStep('silhouetteandstyle')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('silhouetteandstyle')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['silhouetteandstyle']"
                    @click="nextStep('silhouetteandstyle')"
                  >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                  
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item
                :value="'otherhobbies'"
              >
                <v-form ref="completeform_otherhobbies" v-model="valid['otherhobbies']" v-on:input="onInput">
                  <v-card>
                      <v-card-text>
                  <v-autocomplete
                    outlined
                    v-model="hobbies"
                    :items="available_hobbies"
                    :hint="$t('views.complete_registration.hint_hobbies')"
                    :label="$t('views.complete_registration.label_hobbies')"
                    :error-messages="errors.hobbies"
                    persistent-hint
                    multiple
                    class="mb-3"
                  ></v-autocomplete>
                  <small class="primary--text" v-if="!valid['otherhobbies']"><br />{{ $t('views.complete_registration.hint_valid') }}</small>
                      </v-card-text>
                      <v-card-actions>
                  <v-btn
                    text
                    icon
                    color="grey"
                    
                    @click="previousStep('otherhobbies')"
                  >
                    <v-icon>mdi-arrow-left-thick</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="grey"
                    class="vinea-column-btn"
                    @click="skipToNextStep('otherhobbies')"
                  >
                    <v-icon>mdi-chevron-double-right</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_passbutton') }}</small></div>
                  </v-btn>
                  <v-btn
                    color="#bda273"
                    text
                    class="vinea-column-btn"
                    :disabled="!valid['otherhobbies']"
                    @click="nextStep('otherhobbies')"
                  >
                  <v-icon>mdi-arrow-right-thick</v-icon>
                    <div><small>{{ $t('views.complete_registration.label_nextstepbutton') }}</small></div>
                  </v-btn>
                      </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
                  </v-card-text>
            </v-card>
                <p class="mb-3"><small>{{ $t('views.complete_registration.tooltip_required') }}</small></p>
                    <!-- prepend-icon="mdi-database-search" -->
                    <!-- hide-no-data
                    return-object -->
                    <!-- hide-details -->
                  
                    <!-- persistent-hint -->
                    <!-- solo -->
                  <!-- <p>{{$t('views.complete_registration.label_birthday')}}</p> -->
                  <!-- <v-slider
                    v-model="age"
                    label="Age"
                    hint="Be honest"
                    min="18"
                    max="100"
                    :error-messages="errors.size"
                    thumb-label
                  ></v-slider> -->
                  <!-- <v-combobox
                    v-model="wineshashtags"
                    :items="available_wineshashtags"
                    label="What are your preferences in wine ?"
                    hint="You can type whatever you want"
                    multiple
                  ></v-combobox> -->
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import VLovePayment from '@/components/partial/Payment'
import Vuex from 'vuex'
import api from '@/api/api'
import genders from '@/const/genders'
import lookinggenders from '@/const/lookinggenders'
import styles from '@/const/styles'
import silhouettes from '@/const/silhouettes'
import eyescolors from '@/const/eyescolors'
import haircolors from '@/const/haircolors'
import hobbies from '@/const/hobbies'
import winecolors from '@/const/winecolors'
import wineregions from '@/const/wineregions'
import winevarieties from '@/const/winevarieties'

export default {
  name: 'register',
  components: {
    'vlove-payment': VLovePayment
  },
  computed: {
    ...Vuex.mapGetters([
      'USER_HAS_PAYED',
      'U_DESCRIPTION',
      'token'
    ])
  },
  data () {
    return {
      overlay: false,
      complete_step: 1,
      show1: false,
      abo: 'Monthly',
      tunnel_steps: [ 'gender', 'relationprofessionnal', 'looking_for', 'birthday', 'location', 'winecolors', 'wineregions', 'winevarieties', 'picture', 'description', 'sizeandweight', 'silhouetteandstyle', 'otherhobbies'],
      tunnel: 'gender',
      maxdate: null,
      // card: '',
      valid: {
        'gender': false,
        'looking_for': false,
        'relationprofessionnal': false,
        'birthday': false,
        'location': false,
        'winecolors': false,
        'wineregions': false,
        'winevarieties': false,
        'description': false,
        'sizeandweight': false,
        'silhouetteandstyle': false,
        'otherhobbies': false
      },
      cities: [],
      countCities: 0,
      isLoadingCities: false,
      current_city: null,
      searchCities: null,
      required: value => !!value || this.$root.$i18n.t('global.required'),
      intRule: value => /^[0-9]*$/g.test(value || '') || this.$root.$i18n.t('global.number_integer'),
      // date_validity: '',
      // cvc: '',
      genders: Object.keys(genders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': genders.getValues(this.$root.$i18n)[val] } }),
      lookinggenders: Object.keys(lookinggenders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': lookinggenders.getValues(this.$root.$i18n)[val] } }),
      silhouettes: Object.keys(silhouettes.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': silhouettes.getValues(this.$root.$i18n)[val] } }),
      styles: Object.keys(styles.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': styles.getValues(this.$root.$i18n)[val] } }),
      available_hobbies: Object.keys(hobbies.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': hobbies.getValues(this.$root.$i18n)[val] } }),
      available_eyes_colors: Object.keys(eyescolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': eyescolors.getValues(this.$root.$i18n)[val] } }),
      available_hair_colors: Object.keys(haircolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': haircolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_regions: Object.keys(wineregions.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': wineregions.getValues(this.$root.$i18n)[val] } }),
      available_wine_colors: Object.keys(winecolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winecolors.getValues(this.$root.$i18n)[val] } }),
      available_wine_varieties: Object.keys(winevarieties.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winevarieties.getValues(this.$root.$i18n)[val] } }),
      available_wineshashtags: [''],
      // name: '',
      // nickname: '',
      picrules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      description: '',
      weight: '',
      birthday: '',
      gender: 'woman',
      looking_for: 'man',
      size: '',
      silhouette: '',
      style: '',
      menubirthday: false,
      wine_professionnal: false,
      love_relations: true,
      professionnal_relations: false,
      friendship_relations: false,
      hobbies: [],
      wineshashtags: [],
      eyescolors: '',
      haircolor: '',
      wineregions: '',
      winecolors: '',
      winevarieties: '',
      newProfilePicture: null,
      preloadpic: false,
      picdialog: false,
      isPictureWithCamera: false,
      isMobileWithGallery: false,
      fileinput: null,
      errors: {
        birthday: [],
        gender: [],
        lookingfor: [],
        description: [],
        size: [],
        weight: [],
        silhouette: [],
        style: [],
        hobbies: [],
        wine_professionnal: [],
        mood: [],
        eyescolors: [],
        haircolor: [],
        wineregions: [],
        winecolors: [],
        winevarieties: []
      }
    }
  },
  watch: {
    menubirthday (val) {
      val && setTimeout(() => (this.$refs.pickercomplete.activePicker = 'YEAR'))
    },
    "$i18n.locale": function (val, oldVal) {
      if (val !== oldVal) {
        this.updateSelectors()
      }
    },
    searchCities: function (newval) {
      if (!(newval && (this.current_city === null || typeof this.current_city === 'undefined' || newval.length <= 0 || newval !== this.current_city.formatted_address))) {
        return
      }
      api.get_request({}, '/api/geocode', this.token, {'term': newval})
          .then(res => {
            var arr = []
            for (let i = 0; i < res.length; i++) {
              arr.push({
                'text': res[i]['formatted_address'],
                'value': res[i]
              })
            }
            // console.log(arr)
            this.countCities = res.length
            this.cities = arr
          })
          .catch(() => {
            // console.log(err)
          })
          .finally(() => (this.isLoadingCities = false))
    },
    fileinput: function (newval) {
      this.onFileChange(newval)
    },
    USER_HAS_PAYED: function (newval) {
      if (newval) {
        var refthis = this
        if (this.U_DESCRIPTION.length > 0) {
          if (this.$route.query.redirect) {
            const redirectPath = this.$route.query.redirect
            const queriesParams = this.$route.query
            delete queriesParams.redirect
            this.$router.push({ path: redirectPath, query: queriesParams })
            return
          }
          this.$router.push({ name: 'Matching' })
        } else {
          var tmpstep = 3
          setTimeout(function () {
            refthis.complete_step = tmpstep
          }, 200)
        }
      }
    }
  },
  mounted: function () {
    this.overlay = true
    this.birthday = moment().subtract(18, 'years').format('YYYY-MM-DD')
    this.isPictureWithCamera = window.cordova && navigator && navigator.camera
    this.isMobileWithGallery = window.cordova && window.device && window.device.platform !== 'browser' && navigator && navigator.camera
    this.maxdate = this.birthday
    var tmpstep = 1
    if (this.$route.query.status) {
      this.status = this.$route.query.status
    }
    if (this.status === 'pending') {
      tmpstep = 2
    }
    if (this.USER_HAS_PAYED) {
      if (this.U_DESCRIPTION.length > 0) {
        if (this.$route.query.redirect) {
          const redirectPath = this.$route.query.redirect
          const queriesParams = this.$route.query
          delete queriesParams.redirect
          this.$router.push({ path: redirectPath, query: queriesParams })
          return
        }
        this.$router.push({ path: '/matching' })
      } else {
        tmpstep = 3
      }
    }
    var refthis = this
    this.$store.dispatch('USER_LOAD_INFORMATIONS').then (() => {
      setTimeout(function () {
        refthis.overlay = false
      }, 200)
    }).catch(() => {
      this.$router.push({ name: 'logout' })
    })
    setTimeout(function () {
      refthis.complete_step = tmpstep
      // desactivé car défini à l'ouverture du menu
      // setTimeout(() => {
      //   refthis.$refs.pickercomplete.activePicker = 'YEAR'
      // }, 200)
    }, 200)
  },
  methods: {
    updateSelectors () {
      this.genders = Object.keys(genders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': genders.getValues(this.$root.$i18n)[val] } })
      this.lookinggenders = Object.keys(lookinggenders.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': lookinggenders.getValues(this.$root.$i18n)[val] } })
      this.silhouettes = Object.keys(silhouettes.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': silhouettes.getValues(this.$root.$i18n)[val] } })
      this.styles = Object.keys(styles.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': styles.getValues(this.$root.$i18n)[val] } })
      this.available_hobbies = Object.keys(hobbies.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': hobbies.getValues(this.$root.$i18n)[val] } })
      this.available_eyes_colors = Object.keys(eyescolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': eyescolors.getValues(this.$root.$i18n)[val] } })
      this.available_hair_colors = Object.keys(haircolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': haircolors.getValues(this.$root.$i18n)[val] } })
      this.available_wine_regions = Object.keys(wineregions.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': wineregions.getValues(this.$root.$i18n)[val] } })
      this.available_wine_colors = Object.keys(winecolors.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winecolors.getValues(this.$root.$i18n)[val] } })
      this.available_wine_varieties = Object.keys(winevarieties.getValues(this.$root.$i18n)).map(val => { return { 'value': val, 'text': winevarieties.getValues(this.$root.$i18n)[val] } })
    },
    onInput () {
      if (this.$refs.completeform) {
        this.$refs.completeform.resetValidation()
      }
    },
    completedPayment () {
      this.status === 'pending'
      this.complete_step = 2
      this.$store.dispatch('USER_LOAD_INFORMATIONS')
    },
    async nextStep (_currstep) {
      const curstep = this.tunnel_steps.findIndex(val => val === _currstep);
      if (this.$refs['completeform_' + _currstep].validate()) {
        var objtosend = {
          birthday: this.birthday,
          gender: this.gender,
          lookingfor: this.looking_for,
          description: this.description,
          size: this.size,
          weight: this.weight,
          silhouette: this.silhouette,
          style: this.style,
          hobbies: this.hobbies,
          wine_professionnal: this.wine_professionnal,
          love_relations: this.love_relations,
          professionnal_relations: this.professionnal_relations,
          friendship_relations: this.friendship_relations,
          mood: this.mood,
          address: this.current_city ? this.current_city.formatted_address : undefined,
          postalCode: this.current_city ? this.current_city.postalCode : undefined,
          city: this.current_city ? this.current_city.city : undefined,
          country: this.current_city ? this.current_city.country : undefined,
          latitude: this.current_city ? this.current_city.lat : undefined,
          longitude: this.current_city ? this.current_city.lon : undefined,
          eyescolors: this.eyescolors,
          haircolor: this.haircolor,
          wineregions: this.wineregions,
          winecolors: this.winecolors,
          winevarieties: this.winevarieties
        }
        try {
          objtosend.disable_notifications = true
          await this.$store.dispatch('USER_SET_INFORMATIONS', objtosend)
        } catch (e) {
          if (e.data.errors && e.data.errors.children) {
            for (let key in e.data.errors.children) {
              if (e.data.errors.children[key] && e.data.errors.children[key].errors) {
                this.errors[key] = e.data.errors.children[key].errors
              }
            }
          }
        }
        if (curstep + 1 < this.tunnel_steps.length) {
          const nextstep = curstep + 1 ;
          this.tunnel = this.tunnel_steps[nextstep];
        } else {
          this.$store.dispatch('GLOBAL_SHOW_WELCOME')
          this.$router.push({ path: '/profile' })
        }
      }
    },
    async skipToNextStep (_currstep) {
      const curstep = this.tunnel_steps.findIndex(val => val === _currstep);
      if (curstep + 1 < this.tunnel_steps.length) {
        const nextstep = curstep + 1 ;
        this.tunnel = this.tunnel_steps[nextstep];
      } else {
        this.$store.dispatch('GLOBAL_SHOW_WELCOME')
        this.$router.push({ path: '/profile' })
      }
    },
    async previousStep (_currstep) {
      const curstep = this.tunnel_steps.findIndex(val => val === _currstep);
      if (curstep - 1 >= 0) {
        const nextstep = curstep - 1 ;
        this.tunnel = this.tunnel_steps[nextstep];
      }
    },
    async saveInformations () {
      if (this.$refs.completeform.validate()) {
        var refthis = this
        var objtosend = {
          birthday: this.birthday,
          gender: this.gender,
          lookingfor: this.looking_for,
          description: this.description,
          size: this.size,
          weight: this.weight,
          silhouette: this.silhouette,
          style: this.style,
          hobbies: this.hobbies,
          wine_professionnal: this.wine_professionnal,
          mood: this.mood,
          address: this.current_city ? this.current_city.formatted_address : undefined,
          postalCode: this.current_city ? this.current_city.postalCode : undefined,
          city: this.current_city ? this.current_city.city : undefined,
          country: this.current_city ? this.current_city.country : undefined,
          latitude: this.current_city ? this.current_city.lat : undefined,
          longitude: this.current_city ? this.current_city.lon : undefined,
          eyescolors: this.eyescolors,
          haircolor: this.haircolor,
          wineregions: this.wineregions,
          winecolors: this.winecolors,
          winevarieties: this.winevarieties
        }
        try {
          await refthis.$store.dispatch('USER_SET_INFORMATIONS', objtosend)
          refthis.$store.dispatch('GLOBAL_SHOW_WELCOME')
          refthis.$router.push({ path: '/profile' })
        } catch (e) {
          if (e.data.errors && e.data.errors.children) {
            for (let key in e.data.errors.children) {
              if (e.data.errors.children[key] && e.data.errors.children[key].errors) {
                refthis.errors[key] = e.data.errors.children[key].errors
              }
            }
          }
        }
      }
    },

    getFileContentAsBase64 (path, callback, callbackError) {
      window.resolveLocalFileSystemURL(path, function (fileEntry) {
        fileEntry.file(function (file) {
          var reader = new FileReader()
          reader.onloadend = function () {
            var content = this.result
            callback(content)
          }
          // The most important point, use the readAsDatURL Method from the file plugin
          reader.readAsDataURL(file)
        })
      }, function () {
        callbackError()
      })
    },
    getPictureFromCamera () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.CAMERA,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE,
          correctOrientation: true
        })
        /* eslint-enable */
      }
    },
    getPictureFromGallery () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture galery error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE
        })
        /* eslint-enable */
      }
    },
    onFileChange: function (e) {
      if (!e) {
        return
      }
      this.preloadpic = true
      var files = e.target ? e.target.files : e.dataTransfer ? e.dataTransfer.files : [e]
      if (!files.length) {
        this.preloadpic = false
        return
      }
      this.createImage(files[0])
    },
    createImage: function (file) {
      // var image = new Image()
      var reader = new FileReader()

      reader.onload = (e) => {
        this.newProfilePicture = e.target.result
      }
      reader.readAsDataURL(file)
      this.preloadpic = false
    },
    removeImage: function () {
      this.newProfilePicture = ''
      this.fileinput = null
    },
    savePicture: function (_currstep) {
      if (this.newProfilePicture) {
        var payload = {}
        var refthis = this
        if (this.newProfilePicture.indexOf('data:image/') !== -1 && this.newProfilePicture.indexOf(';base64,') !== -1) {
          // Si l'image est en base64 on envoie direct
          payload.picture = this.newProfilePicture
          this.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
            refthis.picdialog = false
            refthis.newProfilePicture = null
            refthis.fileinput = null
            const curstep = refthis.tunnel_steps.findIndex(val => val === _currstep);
            if (curstep + 1 < refthis.tunnel_steps.length) {
              const nextstep = curstep + 1 ;
              refthis.tunnel = refthis.tunnel_steps[nextstep];
            } else {
              refthis.$store.dispatch('GLOBAL_SHOW_WELCOME')
              refthis.$router.push({ path: '/profile' })
            }
          })
        } else if (this.newProfilePicture.indexOf('http://') === -1 && this.announcepicture.indexOf('https://') === -1) {
          // Si l'image est une url interne on converti en base64 et on envoie
          this.getFileContentAsBase64(this.newProfilePicture, function (datafile) {
            payload.picture = datafile
            refthis.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
              refthis.picdialog = false
              refthis.newProfilePicture = null
              refthis.fileinput = null

              const curstep = refthis.tunnel_steps.findIndex(val => val === _currstep);
              if (curstep + 1 < refthis.tunnel_steps.length) {
                const nextstep = curstep + 1 ;
                refthis.tunnel = refthis.tunnel_steps[nextstep];
              } else {
                refthis.$store.dispatch('GLOBAL_SHOW_WELCOME')
                refthis.$router.push({ path: '/profile' })
              }
            })
          }, function () {
            // TODO display an internal error
          })
        }
        else {
          // l'image est une url http,
          // do nothing
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.vinea-column-btn .v-btn__content {
    display:flex !important;
    flex-direction:column !important;
}
.vinea-stepper {
  padding: 5px;
}
</style>
