import api from '@/api/api'
import moment from 'moment'
import i18n from '../../i18n.js'

const state = {
  username: '',
  email: '',
  token: '',
  last_refresh: null,
  need_to_pay: false,
  user_data: {},
  check_subscription_timer: null,
  last_notifications: null
}

const getters = {
  IS_LOGGED_IN: state => state.token && state.token.length > 0,
  IS_INFORMATIONS_LOADED: state => state.user_data && Object.values(state.user_data).length > 0,
  USER_HAS_PAYED: state => state.token && state.token.length > 0 && (!state.need_to_pay || (state.user_data && state.user_data.has_a_valid_subscription)),
  username: state => state.user_data && state.user_data.username ? state.user_data.username : state.username,
  token: state => state.token,
  U_UID: state => state.user_data && state.user_data.id ? state.user_data.id : null,
  U_LOCALE: state => state.user_data && state.user_data.locale ? state.user_data.locale : '',
  U_LAST_EMAIL: state => state.email ? state.email : '',
  U_EMAIL: state => state.user_data && state.user_data.email ? state.user_data.email : '',
  U_FIRSTNAME: state => state.user_data && state.user_data.firstname ? state.user_data.firstname : '',
  U_LASTNAME: state => state.user_data && state.user_data.lastname ? state.user_data.lastname : '',
  U_GENDER: state => state.user_data && state.user_data.gender ? state.user_data.gender : 'unknown',
  U_LOOKING_FOR: state => state.user_data && state.user_data.lookingfor ? state.user_data.lookingfor : 'unknown',
  U_IS_A_WINE_PROFESSIONNAL: state => state.user_data && state.user_data.wine_professionnal ? state.user_data.wine_professionnal : false,
  U_IS_LOOKING_FOR_LOVE_RELATIONS: state => state.user_data && state.user_data.love_relations ? state.user_data.love_relations : false,
  U_IS_LOOKING_FOR_PRO_RELATIONS: state => state.user_data && state.user_data.professionnal_relations ? state.user_data.professionnal_relations : false,
  U_IS_LOOKING_FOR_FRIEND_RELATIONS: state => state.user_data && state.user_data.friendship_relations ? state.user_data.friendship_relations : false,
  U_HOBBIES: state => state.user_data && state.user_data.hobbies ? state.user_data.hobbies : [],
  U_BIRTHDAY: state => state.user_data && state.user_data.birthday ? state.user_data.birthday : moment().subtract(18, 'years').format('YYYY-MM-DD'),
  U_OLD_AGE: state => state.user_data && state.user_data.birthday ? moment().diff(moment(state.user_data.birthday), 'years') : 18,
  U_SIZE: state => state.user_data && state.user_data.size ? state.user_data.size : '',
  U_WEIGHT: state => state.user_data && state.user_data.weight ? state.user_data.weight : '',
  U_SILHOUETTE: state => state.user_data && state.user_data.silhouette ? state.user_data.silhouette : '',
  U_STYLE: state => state.user_data && state.user_data.style ? state.user_data.style : '',
  U_CITY: state => state.user_data && state.user_data.city ? state.user_data.city : '',
  U_LAT: state => state.user_data && state.user_data.latitude ? state.user_data.latitude : '',
  U_LON: state => state.user_data && state.user_data.longitude ? state.user_data.longitude : '',
  U_COUNTRY: state => state.user_data && state.user_data.country ? state.user_data.country : '',
  U_MOOD: state => state.user_data && state.user_data.mood ? state.user_data.mood : '',
  U_DESCRIPTION: state => state.user_data && state.user_data.description ? state.user_data.description : '',
  U_MEDIAS: state => state.user_data && state.user_data.medias ? state.user_data.medias : [],
  U_EYES_COLOR: state => state.user_data && state.user_data.eyescolors ? state.user_data.eyescolors : '',
  U_HAIR_COLOR: state => state.user_data && state.user_data.haircolor ? state.user_data.haircolor : '',
  U_WINE_COLORS: state => state.user_data && state.user_data.winecolors ? state.user_data.winecolors : [],
  U_WINE_REGIONS: state => state.user_data && state.user_data.wineregions ? state.user_data.wineregions : [],
  U_WINE_VARIETIES: state => state.user_data && state.user_data.winevarieties ? state.user_data.winevarieties : [],
  U_LAST_NOTIFICATIONS: state => state.last_notifications ? state.last_notifications : []
}
const actions = {

  USER_LOGIN: async (context, payload) => {
    console.log('Login with username ', payload.username, 'password ', payload.password)
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    context.commit('USER_INIT_LOGIN')
    /* eslint-disable */
    var formData = new FormData()
    formData.append('_username', payload.username)
    formData.append('_password', payload.password)
    try {
      var response_data = await api.basic_post_request(context, '/api/login_check', null, formData)
      // console.log("DATA : ", response_data)
      if (!response_data || !response_data.token) {
        throw new Error('invalid token')
      }
      var me_data = await api.get_request(context, '/api/me', response_data.token)
      context.commit('USER_SAVE_DATA', me_data)
      context.commit('USER_LOGGED_IN', { username: payload.username, token: response_data.token })
      context.dispatch('USER_INIT_PUSH_NOTIFICATION')
      setTimeout(() => {
        context.dispatch('USER_INIT_PUSH_NOTIFICATION_APPLY_USER')
      }, 100)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.logged_in'), body: i18n.t('notifications.user.logged_in_body'), type: 'info' })
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('USER_LOGIN_FAIL', { email: payload.username })
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.logged_in_error'), body: i18n.t('notifications.error_body', { error: errmsg }), type: 'error' })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "login issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },

  USER_LOGIN_FB: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    context.commit('USER_INIT_LOGIN')
    try {
      var response_data = await api.post_request(context, "/api/login-fb", null, {
        app_user_fb: {
          username: payload.first_name,
          email: payload.email,
          firstname: payload.first_name,
          lastname: payload.last_name,
          facebook_uid: payload.id
        }
      }, {
        accesstoken: payload.accesstoken
      })
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.registered'), body: '', type: 'info' }) // 'response : ' + response_data.message
      if (!response_data || !response_data.token) {
        throw new Error('invalid token')
      }
      var me_data = await api.get_request(context, '/api/me', response_data.token)
      context.commit('USER_SAVE_DATA', me_data)
      context.commit('USER_LOGGED_IN', { username: payload.username, token: response_data.token })
      context.dispatch('USER_INIT_PUSH_NOTIFICATION')
      setTimeout(() => {
        context.dispatch('USER_INIT_PUSH_NOTIFICATION_APPLY_USER')
      }, 100)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.logged_in'), body: i18n.t('notifications.user.logged_in_body'), type: 'info' })
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
        // if (error.response.data.errors && error.response.data.errors.children) {
        //   for (let k in error.response.data.errors.children) {
        //     if (error.response.data.errors.children[k] && error.response.data.errors.children[k].errors) {
        //       for (let n = 0; n < +  error.response.data.errors.children[k].errors.length; n++) {
        //         errmsg += ' - ' +  error.response.data.errors.children[k][n]
        //       }
        //     }
        //   }
        // }
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.logged_in_fb_error'), body:  i18n.t('notifications.error_body', { error: errmsg })})
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "fb login issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
  },

  USER_REFRESH: async (context) => {
    if (context.state.last_refresh !== null && moment().isBefore(moment(context.state.last_refresh).add(5, 'minutes'))) {
      return;
    }
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, "/api/refresh", context.state.token)
      context.commit('USER_REFRESH', { token: response_data.token })
    } catch (error) {
    }
    context.commit('GLOBAL_END_REQUEST')
  },

  USER_LOGOUT: (context) => {
    context.commit('USER_LOGGED_OUT')
  },

  USER_REGISTER: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    context.commit('USER_INIT_LOGIN')
    var params = null
    if (payload.locale) {
      params = {
        "locale" : payload.locale
      }
    }
    try {
      var response_data = await api.post_request(context, "/api/register", null, {
        app_user: {
          username: payload.username,
          email: payload.email,
          plainPassword: payload.password
        }
      }, params)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.registered'), body: '', type: 'info' }) // 'response : ' + response_data.message
      context.dispatch('USER_LOGIN', {username: payload.username, password: payload.password})
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
        // if (error.response.data.errors && error.response.data.errors.children) {
        //   for (let k in error.response.data.errors.children) {
        //     if (error.response.data.errors.children[k] && error.response.data.errors.children[k].errors) {
        //       for (let n = 0; n < +  error.response.data.errors.children[k].errors.length; n++) {
        //         errmsg += ' - ' +  error.response.data.errors.children[k][n]
        //       }
        //     }
        //   }
        // }
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.registered_error'), body: i18n.t('notifications.error_body', { error: errmsg })})
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "register issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },

  USER_LOAD_INFORMATIONS: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/me', context.state.token)
      context.commit('USER_SAVE_DATA', { data: response_data, context: context })
      context.dispatch('USER_REFRESH')
      setTimeout(() => {
        context.dispatch('USER_INIT_PUSH_NOTIFICATION_APPLY_USER')
      }, 100)
    } catch (error) {
      context.commit('USER_LOGGED_OUT')
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.load_informations_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
  },



  USER_SET_INFORMATIONS: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        app_user_meeting_info: {
          birthday: payload.birthday || context.state.user_data.birthday,
          gender: payload.gender || context.state.user_data.gender,
          lookingfor: payload.lookingfor || context.state.user_data.lookingfor,
          size: payload.size || context.state.user_data.size,
          weight: payload.weight || context.state.user_data.weight,
          silhouette: payload.silhouette || context.state.user_data.silhouette,
          style: payload.style || context.state.user_data.style,
          hobbies: payload.hobbies || context.state.user_data.hobbies,
          wine_professionnal: payload.wine_professionnal !== undefined ? payload.wine_professionnal : context.state.user_data.wine_professionnal,
          love_relations: payload.love_relations !== undefined ? payload.love_relations : context.state.user_data.love_relations,
          professionnal_relations: payload.professionnal_relations !== undefined ? payload.professionnal_relations : context.state.user_data.professionnal_relations,
          friendship_relations: payload.friendship_relations !== undefined ? payload.friendship_relations : context.state.user_data.friendship_relations,
          mood: payload.mood || context.state.user_data.mood,
          description: payload.description || context.state.user_data.description,
          address: payload.address || context.state.user_data.address,
          postalCode: payload.postalCode || context.state.user_data.postal_code,
          city: payload.city || context.state.user_data.city,
          country: payload.country || context.state.user_data.country,
          latitude: payload.latitude || context.state.user_data.latitude,
          longitude: payload.longitude || context.state.user_data.longitude,
          eyescolors: payload.eyescolors || context.state.user_data.eyescolors,
          haircolor: payload.haircolor || context.state.user_data.haircolor,
          wineregions: payload.wineregions || context.state.user_data.wineregions,
          winecolors: payload.winecolors || context.state.user_data.winecolors,
          winevarieties: payload.winevarieties || context.state.user_data.winevarieties
        }
      }
      if (base_form_param['app_user_meeting_info']['birthday']) {
        base_form_param['app_user_meeting_info']['birthday'] = moment(base_form_param['app_user_meeting_info']['birthday']).format('YYYY-MM-DD')
      }
      var response_data = await api.post_request(context, "/api/me/edit/informations", context.state.token, base_form_param)
      if (!payload.disable_notifications) {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.edit_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
      }
     
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      let messagenotif = i18n.t('notifications.user.edit_error_other');
      if (error.response.code === 400) {
        messagenotif = i18n.t('notifications.user.edit_error');
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: messagenotif, body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "register issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },

  USER_SET_SETTINGS: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        app_user_info: {
          username: payload.username || context.state.username,
          firstname: payload.firstname || context.state.user_data.firstname,
          lastname: payload.lastname || context.state.user_data.lastname,
          email: payload.email || context.state.user_data.email
        }
      }
      var response_data = await api.post_request(context, "/api/me/edit/settings", context.state.token, base_form_param)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.settings_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
     
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.settings_error'), body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "register issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },

  USER_SET_PASSWORD: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        app_user_edit_password: {
          plainPassword: payload.plainPassword,
          oldPassword: payload.oldPassword
        }
      }
      var response_data = await api.post_request(context, "/api/me/edit/password", context.state.token, base_form_param)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.password_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
     
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.password_error'), body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "register issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },



  USER_EDIT_PICTURE: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        picture: payload.picture
      }
      var response_data = await api.post_request(context, "/api/me/picture/edit", context.state.token, base_form_param)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
    
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_error'), body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "register issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },
  
  
  USER_SET_DEFAULT_PICTURE: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        picture: payload.picture
      }
      await api.get_request(context, "/api/me/profile-picture/" + payload.pictureid, context.state.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
    
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_error'), body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "picture define issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },
  
  USER_REMOVE_PICTURE: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      var base_form_param = {
        picture: payload.picture
      }
      await api.get_request(context, "/api/me/picture/delete/" + payload.pictureid, context.state.token)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_ok'), body: '', type: 'info' }) // 'response : ' + response_data.message
    
      context.dispatch('USER_LOAD_INFORMATIONS')
    } catch (error) {
      let errmsg = error.message
      if (error.response && error.response.data && error.response.data.message) {
        errmsg = error.response.data.message
      }
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.user.picture_error'), body: i18n.t('notifications.error_body', { error: errmsg }) })
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      throw {"message" : "picture define issue", "data": error.response.data}
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return true
  },

  NEED_TO_PAY: async (context) => {
    context.commit('USER_NEED_TO_PAY')
  },

  NO_NEED_TO_PAY: async (context) => {
    context.commit('USER_NO_NEED_TO_PAY')
  },

  USER_GET_LAST_NOTIFICATIONS: async (context) => {

    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/notifications', context.rootState.user.token, {
        page: 0,
        limit: 10
      })
      var notifications = []
      for (let k = 0; k < response_data.items.length; k++) {
        var item = response_data.items[k]
        notifications.push(item)
      }
      context.commit('USER_LAST_NOTIFICATIONS', { notifications: notifications, context: context })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'You cannot load last notifications', body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    
  },

  USER_SET_CURRENT_LOCALE: async (context, payload) => {
    if (!context.state.token || context.state.token.length <= 0) {
      return
    }
    context.commit('GLOBAL_START_REQUEST')
    try {
      var base_form_param = {
        locale: payload.locale
      }
      var response_data = await api.post_request(context, "/api/me/edit/locale", context.state.token, base_form_param)
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'You cannot set locale', body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },

  USER_RESET_PASSWORD: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var base_form_param = {
        email: payload.email
      }
      var response_data = await api.post_request(context, "/api/forgot-password", null, base_form_param)
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'Password reset', body: i18n.t('notifications.user.reset_pwd_ok'), type: 'info' })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'You cannot reset password', body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },

  USER_INIT_PUSH_NOTIFICATION: async (context) => {
    if (!context.state.token || context.state.token.length <= 0) {
      return
    }
    if (context.getters.IS_MOBILE && window.plugins.OneSignal) {
      // version notification internes
      var notificationOpenedCallback = function(jsonData) {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      };
      // Set your iOS Settings
      var iosSettings = {};
      iosSettings["kOSSettingsKeyAutoPrompt"] = false;
      iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;
      
      window.plugins.OneSignal
        .startInit(process.env.VUE_APP_ONESIGNAL_APPID)
        .handleNotificationOpened(notificationOpenedCallback)
        .iOSSettings(iosSettings)
        .inFocusDisplaying(window.plugins.OneSignal.OSInFocusDisplayOption.Notification)
        .endInit();
      
      // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
      window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log("User accepted notifications: " + accepted);
      });
    } else if (!context.getters.IS_MOBILE && window.OneSignal) {
      window.OneSignal.push(function() {
        window.OneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL_APPID,
          autoResubscribe: true,
          notifyButton: {
            enable: true,
          },
        });
        window.OneSignal.showNativePrompt();
      });
    }
  },
  
  USER_INIT_PUSH_NOTIFICATION_APPLY_USER: async (context) => {
    if (!context.state.token || context.state.token.length <= 0 || !context.getters.U_UID) {
      return
    }
    if (window.OneSignal) {
      try {
        // console.log('Add listener ', payload.data.id)
        window.OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
          if (isEnabled) {
            window.OneSignal.setExternalUserId(context.getters.U_UID)
          }
        }).catch(() => {});
      } catch (e) {}
    } else if (window.plugins.OneSignal) {
      try {
        window.plugins.OneSignal.isPushNotificationsEnabled().then(function(isEnabled) {
          if (isEnabled) {
            window.plugins.OneSignal.setExternalUserId(context.getters.U_UID)
          }
        }).catch(() => {});
      } catch (e) {}
    }
  },

  USER_REMOVE_ACCOUNT: async (context) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    try {
      try {
        await context.dispatch('CANCEL_SUBSCRIPTION')
      } catch(errorsub) {

      }
      var response_data = await api.get_request(context, '/api/me/delete', context.state.token)
      if (response_data['ok']) {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'Account deleted', body: i18n.t('notifications.user.delete_account_ok'), type: 'info' })
        context.dispatch('USER_LOGOUT')
      } else {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'Account not deleted', body: 'error while trying to delete your account. contact us at frankie@vinealove.com', type: 'info' })
      }
    } catch (error) {
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
  },
}

const mutations = {
  USER_SET_USERNAME: (state, payload) => {
    state.username = payload
  },
  USER_INIT_LOGIN: (state) => {
    state.token = ''
    state.email = ''
    state.username = ''
    state.need_to_pay = false
  },
  USER_LOGGED_IN: (state, payload) => {
    state.username = payload.username
    state.email = payload.email
    state.token = payload.token
    state.need_to_pay = true
  },
  USER_REFRESH: (state, payload) => {
    state.token = payload.token
    state.last_refresh = new Date()
  },
  USER_LOGIN_FAIL: (state, payload) => {
    state.token = ''
    state.email = payload.email
  },
  USER_LOGGED_OUT: (state) => {
    console.log('USER LOGGED OUT')
    state.token = null
    state.user_data = {}
    if (state.check_subscription_timer !== null) {
      clearTimeout(state.check_subscription_timer)
      state.check_subscription_timer = null
    }
  },
  USER_SAVE_DATA: (state, payload) => {
    state.user_data = payload.data
    state.need_to_pay = !(state.user_data && state.user_data.has_a_valid_subscription)
    if (state.check_subscription_timer !== null) {
      clearTimeout(state.check_subscription_timer)
      state.check_subscription_timer = null
    }
    if (state.token && state.need_to_pay) {
      var localcontext = payload.context
      state.check_subscription_timer = setTimeout(5000, function () {
        localcontext.dispatch('USER_LOAD_INFORMATIONS')
      })
    } 
    // else if (payload.data && payload.data.id) {
    //   // console.log('Init push for ', payload.data.id)
    //   setTimeout(() => {
       
    //   }, 100)
    // }
  },
  USER_NEED_TO_PAY: (state, payload) => {
    state.need_to_pay = true
  },
  USER_NO_NEED_TO_PAY: (state) => {
    state.need_to_pay = false
  },
  USER_LAST_NOTIFICATIONS: (state, payload) => {
    state.last_notifications = payload.notifications
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
