<template>
  <div class="vlove-wall">
    <v-timeline
        align-top
        dense
      >
      <v-timeline-item
        small
        v-for="notif in U_LAST_NOTIFICATIONS"
        :key="notif.id"
        fill-dot
      >
          <v-alert
              :value="true"
              dense
            >
              <v-btn text x-small :to="{ name: 'User', query: { uid: notif.initiator.id  } }" v-if="notif.type === 'message'">{{ $t("notifications.message_message", {'username': notif.initiator.username}) }}</v-btn>
              <v-btn text x-small :to="{ name: 'User', query: { uid: notif.initiator.id  } }" v-else-if="notif.type === 'visit'">{{ $t("notifications.message_visit", {'username': notif.initiator.username}) }}</v-btn>
              <v-btn text x-small :to="{ name: 'User', query: { uid: notif.initiator.id  } }" v-else-if="notif.type === 'match'">{{ $t("notifications.message_match", {'username': notif.initiator.username}) }}</v-btn>
              <v-btn text x-small :to="{ name: 'User', query: { uid: notif.initiator.id  } }" v-else-if="notif.type === 'friend'">{{ $t("notifications.message_friend", {'username': notif.initiator.username}) }}</v-btn>
              <v-btn text x-small :to="{ name: 'User', query: { uid: notif.initiator.id  } }" v-else-if="notif.type === 'cheer'">{{ $t("notifications.message_cheer", {'username': notif.initiator.username}) }}</v-btn>
              <!-- {{ notif.initiator.id }} {{  }} -->
            </v-alert>
      </v-timeline-item>

      
    </v-timeline> 
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'vlove-wall',
  computed: {
    ...Vuex.mapGetters([
      'U_LAST_NOTIFICATIONS'
    ])
  },
  data () {
    return {
    }
  },
  mounted: function () {
    this.reloadLastNotifications()
  },
  methods: {
    reloadLastNotifications () {
      this.$store.dispatch('USER_GET_LAST_NOTIFICATIONS')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
