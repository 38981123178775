<template>
  <div class="register">
    <div class="overlay-register">
    <v-container fluid>
      <v-card
        class="mx-auto"
        max-width="512"
        color="transparent"
        elevation="0"
      >
        <v-card-text class="text-center">
          <v-img :height="150" src="@/assets/logo.png" aspect-ratio="1" contain></v-img>
          <span class="primary--text text-center text-h6 font-weight-bold">{{ $t("views.landing.baseline") }}</span>
        </v-card-text>
      </v-card>
      <v-card
        class="mx-auto pa-5"
        max-width="512"
      >
        <v-card-text class="mb-0 pb-0">
          <v-form ref="accountform" v-model="valid">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :error-messages="emailServerErrors"
              :label="$t('views.register.label_email')"
              type="email"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'visibility_off' : 'visibility'"
              :rules="passwordRules"
              :type="show1 ? 'text' : 'password'"
              error-count="2"
              counter
              :hint="$t('views.register.hint_password_persistent')"
              persistent-hint
              :label="$t('views.register.label_password')"
              @click:append="show1 = !show1"
            ></v-text-field>
            <v-text-field
              v-model="password2"
              :append-icon="show1 ? 'visibility_off' : 'visibility'"
              :rules="[
                v => !!v || $t('views.register.hint_repeatpassword_1'), 
                () => password === password2 || $t('views.register.hint_repeatpassword_2')]"
              :type="show1 ? 'text' : 'password'"
              :label="$t('views.register.label_repeatpassword')"
              :error-messages="passwordServerErrors"
              @click:append="show1 = !show1"
              v-on:keyup.enter.exact="register()"
            ></v-text-field>
            <v-text-field
              v-model="musername"
              :rules="usernameRules"
              :error-messages="usernameServerErrors"
              :label="$t('views.register.label_username')"
              required
            ></v-text-field>
            <v-checkbox v-model="agree" :rules="checkRules">
              <template v-slot:label>
                <div>
                  {{ $t('views.register.agree_label') }}
                  <a
                    @click.stop="showCGU()"
                  >
                    {{ $t('views.register.agree_label_2') }}
                  </a>
                </div>
              </template>
            </v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-container>
            <v-row class="d-flex flex-row-reverse">
              <v-btn
                color="primary"
                class="mt-2"
                @click="register()"
              >
                {{ $t('views.register.label_validbutton') }}
              </v-btn>
              <v-btn text class="primary--text float-right mr-3 mt-2" large :to="{name: 'login'}">{{ $t('views.register.label_switchbutton') }}</v-btn>
            </v-row>
            <!-- <v-row dense>
              <v-spacer></v-spacer>
              <v-col 
                cols="12"
                :sm="$i18n.locale === 'fr' ? '6' : '6'"
              >
                <v-btn text class="primary--text float-right mr-3" large :to="{name: 'login'}">{{ $t('views.register.label_switchbutton') }}</v-btn>
              </v-col>
              <v-col 
                cols="12"
                :sm="$i18n.locale === 'fr' ? '5' : '5'"
              >
                <v-btn
                  color="primary"
                  class="float-right"
                  @click="register()"
                >
                  {{ $t('views.register.label_validbutton') }}
                </v-btn>
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-actions>
        <v-card-text class="text-center mb-0 pb-0">
          <hr />
          <p class="alternative-connexion-title"><span>{{ $t('views.login.label_alternativelogin') }}</span></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="loading_fb_login">{{ $t('views.login.loading_facebook') }}</div>
          <v-btn v-else large dark color="blue darken-1" disabled v-on:click="facebookLogin()">
            <v-icon>fab fa-facebook</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
       
    </v-container>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {mapActions} from 'vuex'
// import VLovePayment from '@/components/partial/Payment'

export default {
  name: 'register',
  components: {
    // 'vlove-payment': VLovePayment
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'U_LAST_EMAIL',
      'IS_LOGGED_IN'
    ])
  },
  data () {
    return {
      formHasErrors: false,
      errorMessages: '',
      show1: false,
      valid: false,
      email: '',
      musername: '',
      password: '',
      password2: '',
      agree: false,
      langs: ['en', 'fr'],
      emailRules: [
        v => !!v || this.$i18n.t('views.register.hint_email_1'),
        v => /.+@.+/.test(v) || this.$i18n.t('views.register.hint_email_2')
      ],
      emailServerErrors: [],
      usernameRules: [
        v => !!v || this.$i18n.t('views.register.hint_username_1')
      ],
      usernameServerErrors: [],
      passwordRules: [
        v => !!v || this.$i18n.t('views.register.hint_password_1'),
        v => v.length >= 8 || this.$i18n.t('views.register.hint_password_2')
      ],
      checkRules: [
        v => !!v || this.$i18n.t('views.register.hint_agree_1')
      ],
      passwordServerErrors: [],
      loading_fb_login: false
    }
  },
  mounted: function () {
    this.email = this.U_LAST_EMAIL
    this.initFacebook()
    // this.musername = this.username
  },
  beforeDestroy () {
    this.unloadFacebook()
  },
  watch: {
    IS_LOGGED_IN: function (val) {
      if (val) {
        this.$router.push({name: 'register_complete'})
      }
    },
    email: function () {
      this.emailServerErrors = []
    },
    musername: function () {
      this.usernameServerErrors = []
    },
    password: function () {
      this.passwordServerErrors = []
    },
    password2: function () {
      this.passwordServerErrors = []
    }
  },
  methods: {
    ...mapActions({
      GLOBAL_SHOW_CGU: 'GLOBAL_SHOW_CGU',
      GLOBAL_HIDE_CGU: 'GLOBAL_HIDE_CGU'
    }),
    reinitFormErrors () {
      this.emailServerErrors = []
      this.passwordServerErrors = []
      this.usernameServerErrors = []
    },
    async register () {
      this.reinitFormErrors()
      if (this.valid && this.agree) {
        try {
          await this.$store.dispatch('USER_REGISTER', { username: this.musername, email: this.email, password: this.password, locale: this.$root.$i18n.locale })
        } catch (e) {
          // console.log(e)
          if (e.data.errors && e.data.errors.children && e.data.errors.children.email && e.data.errors.children.email.errors) {
            this.emailServerErrors = e.data.errors.children.email.errors
          }
          if (e.data.errors && e.data.errors.children && e.data.errors.children.username && e.data.errors.children.username.errors) {
            this.usernameServerErrors = e.data.errors.children.username.errors
          }
          if (e.data.errors && e.data.errors.children && e.data.errors.children.password && e.data.errors.children.password.errors) {
            this.passwordServerErrors = e.data.errors.children.password.errors
          }
        }
      }
    },
    initFacebook () {
      if (window && window.FB) {
        this.loading_fb_login = true
        window.FB.Event.subscribe('xfbml.render', this.facebookLoaded)
        window.FB.Event.subscribe('auth.authResponseChange', this.facebookLoginCheck)
        var appId = process.env.VUE_APP_FACEBOOK_APP || '165485438438759'
        window.FB.init({
          appId      : appId, // dev : 165485438438759 prod : 547349709528595
          status     : true,
          xfbml      : true,
          version    : 'v2.7' // or v2.6, v2.5, v2.4, v2.3
        })
      }
    },
    unloadFacebook () {
      if (window && window.FB) {
        window.FB.Event.unsubscribe('xfbml.render', this.facebookLoaded)
        window.FB.Event.unsubscribe('auth.authResponseChange', this.facebookLoginCheck)
      }
    },
    facebookLoaded () {
      this.loading_fb_login = false
    },
    facebookLogin () {
      if (window && window.FB) {
        window.FB.login(function() {
        }, {scope: 'email,public_profile'});
      }
    },
    facebookLoginCheck () {
      if (window && window.FB) {
        var refthis = this
        window.FB.getLoginStatus(function(authresponse) {
            // statusChangeCallback(response);
            // {
            //     status: 'connected', //connected //not_authorized 
            //     authResponse: {
            //         accessToken: '...',
            //         expiresIn:'...',
            //         signedRequest:'...',
            //         userID:'...'
            //     }
            // }
          console.log(authresponse)
          if (authresponse.status === 'connected') {
            window.FB.api('/me?fields=id,email,last_name,first_name', function(response) {
              console.log('Successful login for: ' + response.name);
              console.log(response)
              refthis.loginWithFacebookUid(response, authresponse)
            })
          }
        })
      }
    },
    async loginWithFacebookUid (fbpayload, authfbpayload) {
      fbpayload['accesstoken'] = authfbpayload.authResponse.accessToken
      try {
        await this.$store.dispatch('USER_LOGIN_FB', fbpayload)
      } catch (e) {
        // console.log(e)
      }
    },
    showCGU () {
      this.GLOBAL_SHOW_CGU()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .register {
    background-image: url('../../assets/login-bg.jpg');
    background-size: cover;
    min-height: 100%;/* 88vh;*/
    height: 100%;
  }
  .overlay-register {
    min-height:  100%;/* 88vh;*/
    height: 100%;
    background-color: rgba(255,255,255, 0.5);
  }
  .lang-selector {
    max-width: 5rem;
    width: 5rem;
  }
  .alternative-connexion-title {
    margin-top: -12px;
  }
  .alternative-connexion-title > span {
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
</style>
