import api from '@/api/api'
import Vue from 'vue'
import moment from 'moment'
import i18n from '../../i18n.js'

const state = {
  last_chats: [],
  last_chats_timer: null,
  chats: [],
  page_chats: 1,
  total_chats: 0,
  current_chat: {},
  current_chat_messages: [],
  page_current_chat_messages: 1,
  total_current_chat_messages: 0,
  loading_discussion: false
}

const getters = {
  LAST_CHATS: state => state.last_chats && state.last_chats.length > 0 ? state.last_chats : [],
  CHATS: state => state.chats && state.chats.length > 0 ? state.chats : [],
  CHATS_PAGINATION: state => { return { 'page': state.page_chats, 'total': state.total_chats } },
  CURRENT_CHAT_MESSAGES: state => ((state.current_chat_messages && state.current_chat_messages.length > 0) ? state.current_chat_messages : []),
  CURRENT_CHAT_MESSAGES_PAGINATION: state => { return { 'page': state.page_current_chat_messages, 'total': state.total_current_chat_messages } },
  CURRENT_MESSAGES_ARE_LOADING: state => state.loading_discussion,
  CURRENT_CHAT: state => state.current_chat ? state.current_chat : null,
  CURRENT_CHAT_IS_WRITING: (state, getters) => {
    if (!state.current_chat || !state.current_chat.user_init_chat || !state.current_chat.user_respond_chat) {
      return false;
    }
    if (state.current_chat.user_init_chat.username === getters.username) {
      return state.current_chat.date_last_writing_responder && state.current_chat.date_last_writing_responder !== null &&
        (state.current_chat.last_message_responder === null || moment(state.current_chat.date_last_writing_responder).isAfter(moment(state.current_chat.last_message_responder.date_create))) &&
        moment().subtract(1, 'minute').isBefore(moment(state.current_chat.date_last_writing_responder))
    } else {
      return state.current_chat.date_last_writing_initiator && state.current_chat.date_last_writing_initiator !== null &&
       (state.current_chat.last_message_initiator === null || moment(state.current_chat.date_last_writing_initiator).isAfter(moment(state.current_chat.last_message_initiator.date_create))) &&
        moment().subtract(1, 'minute').isBefore(moment(state.current_chat.date_last_writing_initiator))
    }
  }
}

const actions = {

  LOAD_LAST_CHATS: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/chats', context.rootState.user.token, {
        page: 0,
        limit: 5
      })
      var chats = []
      for (let k = 0; k < response_data.items.length; k++) {
        var item = response_data.items[k]
        item.interlocutor = item.user_init_chat.id === context.rootState.user.user_data.id ? item.user_respond_chat : item.user_init_chat
        chats.push(item)
      }
      context.commit('LOAD_LAST_CHATS', { chats: chats, context: context })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.loading_last_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  RELOAD_CURRENT_CHATS: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.page_chats
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 1) {
      current_page = payload.page
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/chats', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var chats = []
      for (let k = 0; k < response_data.items.length; k++) {
        chats.push(response_data.items[k])
      }
      context.commit('LOAD_CHATS', { page: current_page, total: response_data.total, chats: chats })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.loading_current_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_CHATS: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_chats
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_CHATS(context, payload)
  },
  LOAD_PREVIOUS_CHATS: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_chats
    current_page = ((current_page - 1) >= 2 ? (current_page - 1) : 1)
    payload.page = current_page
    this.RELOAD_CURRENT_CHATS(context, payload)
  },

  RELOAD_CURRENT_CHAT_MESSAGES: async (context, payload) => {
    if (!context.rootState.visits.visiting_user_data || !context.rootState.visits.visiting_user_data.id) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.wrong_chat'), body: 'No user conversation selected', type: 'error' })
      return
    }
    context.commit('GLOBAL_START_REQUEST')
    context.commit('LOADING_MESSAGES')
    var current_page = context.state.page_current_chat_messages
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 1) {
      current_page = payload.page
    }
    try {
      var response_data = await api.get_request(context, '/api/messages/' + context.rootState.visits.visiting_user_data.id, context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      var messages = []
      for (let k = 0; k < response_data.items.length; k++) {
        messages.push(response_data.items[k])
      }
      context.commit('LOAD_CURRENT_CHAT_MESSAGES', { page: current_page, total: response_data.total, messages: messages, chat: response_data.chat })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.loading_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('END_LOADING_MESSAGES')
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_CURRENT_CHAT_MESSAGES: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_current_chat_messages
    current_page = current_page + 1
    payload.page = current_page
    context.dispatch('RELOAD_CURRENT_CHAT_MESSAGES', payload)
  },
  LOAD_PREVIOUS_CURRENT_CHAT_MESSAGES: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_current_chat_messages
    current_page = ((current_page - 1) >= 2 ? (current_page - 1) : 1)
    payload.page = current_page
    context.dispatch('RELOAD_CURRENT_CHAT_MESSAGES', payload)
  },
  SEND_MESSAGE: async (context, payload) => {
    if (!context.rootState.visits.visiting_user_data || !context.rootState.visits.visiting_user_data.id) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.wrong_chat'), body: 'No user conversation selected', type: 'error' })
      return
    }
    context.commit('GLOBAL_START_REQUEST')
    try {
      var post_message = {
        send_message_type: {
          body: payload.body
        }
      }
      await api.post_request(context, '/api/message/send/' + context.rootState.visits.visiting_user_data.id, context.rootState.user.token, post_message)
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.send_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
    payload.page = 0
    context.dispatch('RELOAD_CURRENT_CHAT_MESSAGES', payload)
  },
  WRITING_MESSAGE: async (context) => {
    if (!context.rootState.visits.visiting_user_data || !context.rootState.visits.visiting_user_data.id) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.wrong_chat'), body: 'No user conversation selected', type: 'error' })
      return
    }
    try {
      await api.get_request(context, '/api/message/writing/' + context.rootState.visits.visiting_user_data.id, context.rootState.user.token)
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.chat.sending_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
  }
}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.chats = []
    state.page_chats = 0
    state.total_chats = 0
    state.current_chat_messages = []
    state.page_current_chat_messages = 0
    state.total_current_chat_messages = 0
    state.last_chats = []
    state.current_chat = {}
    if (state.last_chats_timer !== null) {
      clearTimeout(state.last_chats_timer)
      state.last_chats_timer = null
    }
  },
  LOAD_CHATS: (state, payload) => {
    if (typeof payload.chats !== 'undefined' && payload.chats.length >= 0) {
      state.page_chats = parseInt(payload.page)
      state.chats = payload.chats
      state.total_chats = parseInt(payload.total)
    }
  },
  LOAD_LAST_CHATS: (state, payload) => {
    if (typeof payload.chats !== 'undefined' && payload.chats.length >= 0) {
      state.last_chats = payload.chats
      if (state.last_chats_timer !== null) {
        clearTimeout(state.last_chats_timer)
        state.last_chats_timer = null
      }
      var localcontext = payload.context
      state.last_chats_timer = setTimeout(function () {
        localcontext.dispatch('LOAD_LAST_CHATS')
      }, 15000)
    }
  },
  LOAD_CURRENT_CHAT_MESSAGES: (state, payload) => {
    if (typeof payload.messages !== 'undefined' && payload.messages.length >= 0) {
      // state.page_current_chat_messages = payload.page
      // state.current_chat_messages = payload.messages
      // state.total_current_chat_messages = payload.total
      // state.current_chat = payload.chat || {}
      Vue.set(state, 'page_current_chat_messages', parseInt(payload.page))
      Vue.set(state, 'current_chat_messages', payload.messages.slice().reverse())
      Vue.set(state, 'total_current_chat_messages', parseInt(payload.total))
      Vue.set(state, 'current_chat', payload.chat || {})
    }
  },
  LOADING_MESSAGES: (state) => {
    state.loading_discussion = true
  },
  END_LOADING_MESSAGES: (state) => {
    state.loading_discussion = false
  },
  UNLOAD_MESSAGES: (state) => {
    state.current_chat_messages = []
    state.page_current_chat_messages = 0
    state.total_current_chat_messages = 0
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
