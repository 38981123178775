import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import user from './modules/User'
import payment from './modules/Payment'
import friends from './modules/Friendship'
import cheers from './modules/Cheers'
import visits from './modules/VisitProfile'
import search from './modules/Search'
import chat from './modules/Chat'
import calendar from './modules/Calendar'
import match from './modules/Match'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    dialog_welcome: false,
    loading_request: false,
    loading_blocking_request: false,
    dialog_cgu: false,
    dialog_privacy: false,
    notifications: []
  },
  actions,
  getters,
  mutations,
  modules: {
    user,
    payment,
    friends,
    cheers,
    search,
    visits,
    chat,
    calendar,
    match
  },
  strict: debug,
  plugins: [createPersistedState({paths: ['user', 'search']})]
//  plugins: debug ? [createLogger()] : []
})
