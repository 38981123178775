<template>
  <div class="vlove-friends">
          <v-container grid-list-sm fluid v-if="friends_pagination && typeof friends_pagination.total !== 'undefined' && friends_pagination.total > 0">
            <v-row>
              <v-pagination v-if="friends_pagination && typeof friends_pagination.total !== 'undefined'"
                v-model="current_page_friends"
                :length="parseInt(Math.floor(friends_pagination.total / 10)) + 1"
                :total-visible="Math.min(friends_pagination.total , 7)"
                circle
              ></v-pagination>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"
                d-flex
                v-for="(friendship, index) in friends"
                :key="index"
              >
                <vlove-small-profile-sheet
                  v-if="friendship && friendship.user"
                  v-on:need-reload="reloadList()"
                  :picture="friendship.user.medias | filterProfilePic"
                  :name="friendship.user.username"
                  :moods="friendship.user.mood"
                  :hashtags="friendship.user.hashtags || []"
                  :cheers="friendship.user.cheered_by_me"
                  :friend="friendship.user.friendship_with_me"
                  :pendind_friend="friendship.user.friendship_pending_with_me"
                  :pending_invitation="friendship.user.invitation_pending_with_me"
                  :uid="friendship.user.id"
                  :distance="friendship.user.distance"
                  :age="friendship.user.age"
                  :gender="friendship.user.gender"
                  ></vlove-small-profile-sheet>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else grid-list-sm fluid>
            <v-row class="text-center">
              <v-col>
                <v-img class="mt-2" :height="200" src="@/assets/logo-vinealove-noresult.png" aspect-ratio="1" contain ></v-img>
                <h4>{{ $t('partials.friendslist.no_results_title') }}</h4><br/>
                <p>{{ $t('partials.friendslist.no_results_desc') }}</p>
              </v-col>
            </v-row>
          </v-container>
  </div>
</template>

<script>
import Vuex from 'vuex'
import VLoveSmallSheet from '@/components/partial/SmallProfileSheet'

export default {
  name: 'vlove-friends',
  components: {
    'vlove-small-profile-sheet': VLoveSmallSheet
  },
  data () {
    return {
      current_page_friends: 1
    }
  },
  computed: {
    ...Vuex.mapGetters({
      'friends': 'FRIENDS_LIST',
      'friends_pagination': 'FRIENDS_PAGINATION'
    })
  },
  mounted: function () {
    this.$store.dispatch('RELOAD_CURRENT_FRIENDS')
  },
  filters: {
    filterProfilePic: function (_medias) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return null
    }
  },
  watch: {
    current_page_friends: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_FRIENDS', {'page': val})
    }
  },
  methods: {
    reloadList () {
      this.$store.dispatch('RELOAD_CURRENT_FRIENDS')
    },
    nextPage () {
      this.$store.dispatch('LOAD_NEXT_FRIENDS')
    },
    previousPage () {
      this.$store.dispatch('LOAD_NEXT_FRIENDS')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
