import api from '@/api/api'
import i18n from '../../i18n.js'
import Vue from 'vue'
// import moment from 'moment'

const state = {
  results: [],
  page_results: 0,
  total_results: 0,
  search_parameters: {}
}

const getters = {
  SEARCH_RESULT_LIST: state => state.results && state.results.length > 0 ? state.results : [],
  SEARCH_RESULT_PAGINATION: state => { return { 'page': state.page_results, 'total': state.total_results } },
  PREVIOUS_SEARCH: (state, getters, rootState) => rootState.user && rootState.user.user_data && rootState.user.user_data.id && state.search_parameters[rootState.user.user_data.id] ? state.search_parameters[rootState.user.user_data.id] : {}
}

const actions = {
  SAVE_CURRENT_SEARCH_PARAMETERS: (context, payload) => {
    var tosave = {
      'looking_for': payload.looking_for || undefined,
      'term': payload.term || undefined,
      'age_min': payload.age_min || undefined,
      'age_max': payload.age_max || undefined,
      'silhouette': payload.silhouette || undefined,
      'style': payload.style || undefined,
      'hobbies': payload.hobbies || undefined,
      'wine_professionnal': payload.wine_professionnal || undefined,
      'love_relations': payload.love_relations || undefined,
      'professionnal_relations': payload.professionnal_relations || undefined,
      'friendship_relations': payload.friendship_relations || undefined,
      'size_min': payload.size_min || undefined,
      'size_max': payload.size_max || undefined,
      'weight_min': payload.weight_min || undefined,
      'weight_max': payload.weight_max || undefined,
      'order': payload.order || undefined,
      'location_activation': payload.location_activation || undefined,
      'distance': payload.distance || undefined,
      'city': payload.city || undefined,
      'country': payload.country || undefined,
      'latitude': payload.latitude || undefined,
      'longitude': payload.longitude || undefined,
      'advanced_search': payload.advanced_search || undefined
    }
    // console.log(context.rootState.user)
    // console.log(context.rootState.user.user_data)
    // console.log(context.rootState.user.user_data.id)
    if (context.rootState.user.user_data.id) {
      context.commit('UPDATE_SEARCH_PARAMETERS', { uid: context.rootState.user.user_data.id, search_parameters: tosave })
    }
  },
  RELOAD_CURRENT_SEARCH_RESULTS: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    var current_page = context.state.page_results
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    try {
      var querydata = {
        'looking_for': payload.looking_for || undefined,
        'term': payload.term || undefined,
        'age_min': payload.age_min || undefined,
        'age_max': payload.age_max || undefined,
        'silhouette': payload.silhouette || undefined,
        'style': payload.style || undefined,
        'hobbies': payload.hobbies || undefined,
        'wine_professionnal': payload.wine_professionnal || undefined,
        'love_relations': payload.love_relations || undefined,
        'professionnal_relations': payload.professionnal_relations || undefined,
        'friendship_relations': payload.friendship_relations || undefined,
        'size_min': payload.size_min || undefined,
        'size_max': payload.size_max || undefined,
        'weight_min': payload.weight_min || undefined,
        'weight_max': payload.weight_max || undefined,
        'order': payload.order || undefined,
        'distance': payload.distance || undefined,
        'latitude': payload.latitude || undefined,
        'longitude': payload.longitude || undefined
      }
      var response_data = await api.post_request(context, '/api/search', context.rootState.user.token, querydata, {
        page: current_page,
        limit: limit
      })
      var results = []
      for (let k = 0; k < response_data.items.length; k++) {
        results.push(response_data.items[k])
      }
      context.commit('LOAD_SEARCH_RESULTS', { page: current_page, total: response_data.total, results: results })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.search.loading_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
  },
  LOAD_NEXT_SEARCH_RESULTS: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.page_results
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_SEARCH_RESULTS(context, payload)
  },
  LOAD_PREVIOUS_SEARCH_RESULTS: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.page_results
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_RESULTS(context, payload)
  }

}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.results = []
    state.page_results = 0
    state.total_results = 0
  },
  LOAD_SEARCH_RESULTS: (state, payload) => {
    if (payload.results && payload.results.length > 0) {
      state.page_results = payload.page
      state.results = payload.results
      state.total_results = payload.total
    } else {
      state.page_results = 0
      state.results = []
      state.total_results = 0
    }
  },
  UPDATE_SEARCH_PARAMETERS: (state, payload) => {
    Vue.set(state.search_parameters, payload.uid, payload.search_parameters)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
