import Vue from 'vue'
import Router from 'vue-router'
// import Landing from '@/components/Landing'
import Login from '@/components/screen/Login'
import Register from '@/components/screen/Register'
import CompleteRegistration from '@/components/screen/CompleteRegistration'
import MyProfile from '@/components/screen/MyProfile'
import Profile from '@/components/screen/Profile'
import Match from '@/components/screen/Match'
import Winelovers from '@/components/screen/Winelovers'
import Agenda from '@/components/screen/Agenda'
import Settings from '@/components/screen/Settings'
import Friends from '@/components/screen/Friends'

Vue.use(Router)

export default new Router({
  mode: process.env.VUE_APP_WEB === 1 || process.env.VUE_APP_WEB === '1' ? 'history' : 'hash',
  routes: [
    { path: '/', redirect: '/login' },
    // {
    //   path: '/',
    //   name: 'Landing',
    //   meta: { Auth: false },
    //   components: {
    //     default: Landing
    //   }
    // },
    {
      path: '/login',
      name: 'login',
      meta: { Auth: false },
      components: {
        default: Login
      }
    },
    {
      path: '/register',
      name: 'register',
      meta: { Auth: false },
      components: {
        default: Register
      }
    },
    {
      path: '/complete',
      name: 'register_complete',
      meta: { Auth: true },
      components: {
        default: CompleteRegistration
      }
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { Auth: true }
    },
    {
      path: '/profile',
      name: 'Profil',
      meta: { Auth: true },
      components: {
        default: MyProfile
      }
    },
    {
      path: '/uprofile',
      name: 'User',
      meta: { Auth: true },
      components: {
        default: Profile
      }
    },
    {
      path: '/matching',
      name: 'Matching',
      meta: { Auth: true },
      components: {
        default: Match
      }
    },
    {
      path: '/vinealovers',
      name: 'VineaLovers',
      meta: { Auth: true },
      components: {
        default: Winelovers
      }
    },
    {
      path: '/agenda',
      name: 'Agenda',
      meta: { Auth: false },
      components: {
        default: Agenda
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: { Auth: false },
      components: {
        default: Settings
      }
    },
    {
      path: '/friends',
      name: 'Friends',
      meta: { Auth: true },
      components: {
        default: Friends
      }
    }
  ]
})
