import api from '@/api/api'
import i18n from '../../i18n.js'
// import moment from 'moment'

const state = {
  current_match: null,
  both_match: null,
  matched_both_page: 0,
  matched_both_total: 0,
  matched_both: []
}


const getters = {
  CURRENT_MATCH: state => state.current_match && state.current_match ? state.current_match : null,
  CURRENT_MATCH_USER: state => state.current_match && state.current_match.matched ? state.current_match.matched : null,
  BOTH_MATCH: state => state.both_match ? state.both_match : null,
  BOTH_MATCH_USER: state => state.both_match && state.both_match.matched ? state.both_match.matched : null,
  MATCHS_PAGINATION: state => { return { 'page': state.matched_both_page, 'total': state.matched_both_total } },
  MATCHS: state => state.matched_both && state.matched_both.length > 0 ? state.matched_both : [],
}


const actions = {
  RELOAD_CURRENT_MATCH: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/match', context.rootState.user.token)
      context.commit('LOAD_MATCH', { match: response_data })
      // setTimeout(() => {
      //   context.commit('ITS_A_MATCH', { match: response_data })
      // }, 5000)
      context.dispatch('USER_REFRESH')
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.match.no_more_match'), type: 'error' })
        context.commit('LOAD_MATCH', { match: null })
      } else {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.match.loading_match_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
      }
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  MATCH_NOMATCH: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      let curmatchid = context.state.current_match.id
      var response_data = await api.get_request(context, '/api/match/' + curmatchid + (payload.match ? '/like' : '/dislike'), context.rootState.user.token)
      if (typeof response_data.its_a_match !== undefined && response_data.its_a_match) {
        context.commit('ITS_A_MATCH', { match: context.state.current_match })
      }
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.match.match_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },

  RELOAD_CURRENT_MATCHED_BOTH: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var current_page = context.state.matched_both_page
    var limit = 10
    if (payload && payload.page !== undefined && payload.page !== null && payload.page >= 0) {
      current_page = payload.page
    }
    if (isNaN(current_page)) {
      current_page = 1
    }
    if (current_page < 1) {
      current_page = 1
    }
    try {
      var response_data = await api.get_request(context, '/api/matched', context.rootState.user.token, {
        page: (current_page - 1) * limit,
        limit: limit
      })
      // var matched = []
      // for (let k = 0; k < response_data.items.length; k++) {
      //   matched.push(response_data.items[k].matched)
      // }
      context.commit('LOAD_MATCHED_BOTH', { page: current_page, total: response_data.total, matched: response_data.items })
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.match.loading_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_NEXT_MATCHED_BOTH: async (context, payload) => {
    console.log('Load next sync')
    var current_page = context.state.matched_both_page
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = current_page + 1
    payload.page = current_page
    this.RELOAD_CURRENT_MATCHED_BOTH(context, payload)
  },
  LOAD_PREVIOUS_MATCHED_BOTH: async (context, payload) => {
    console.log('Load previous sync')
    var current_page = context.state.matched_both_page
    if (isNaN(current_page)) {
      current_page = 0
    }
    current_page = ((current_page - 1) >= 0 ? (current_page - 1) : 0)
    payload.page = current_page
    this.RELOAD_CURRENT_MATCHED_BOTH(context, payload)
  },

}

const mutations = {
  USER_LOGGED_OUT: (state) => {
    state.current_match = null
    state.both_match = null
    state.matched = []
  },
  LOAD_MATCH: (state, {match}) => {
    state.current_match = match
  },
  ITS_A_MATCH: (state, {match}) => {
    state.both_match = match
  },
  LOAD_MATCHED_BOTH: (state, {page, total, matched}) => {
    state.matched_both_page = page,
    state.matched_both_total = total,
    state.matched_both = matched
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}