export default {
  getValues (_translator) {
    return {
      'still_red': _translator.t('user.winecolors.still_red'),
      'still_white': _translator.t('user.winecolors.still_white'),
      'still_white_dry': _translator.t('user.winecolors.still_white_dry'),
      'still_white_liquorous': _translator.t('user.winecolors.still_white_liquorous'),
      'still_rose': _translator.t('user.winecolors.still_rose'),
      'sparkling_white': _translator.t('user.winecolors.sparkling_white'),
      'sparkling_rose': _translator.t('user.winecolors.sparkling_rose'),
      'still_grey': _translator.t('user.winecolors.still_grey'),
      'still_yellow': _translator.t('user.winecolors.still_yellow'),
      'sparkling_red': _translator.t('user.winecolors.sparkling_red'),
      'others': _translator.t('user.winecolors.others')
    }
  }
}
