<template>
  <div class="vlove-viewed-me">
    <v-container grid-list-sm fluid v-if="viewed_pagination && typeof viewed_pagination.total !== 'undefined' && viewed_pagination.total > 0">
      <v-row>
        <v-pagination v-if="viewed_pagination && typeof viewed_pagination.total !== 'undefined'"
          v-model="current_page_viewed"
          :length="parseInt(Math.floor(viewed_pagination.total / 10)) + 1"
          :total-visible="Math.min(viewed_pagination.total , 7)"
          circle
        ></v-pagination>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          d-flex
          v-for="(user, index) in viewed"
          :key="index"
        >
        <vlove-small-profile-sheet
          v-if="user"
          v-on:need-reload="reloadList()"
          :picture="user.medias | filterProfilePic"
          :name="user.username"
          :moods="user.mood"
          :cheers="user.cheered_by_me"
          :friend="user.friendship_with_me"
          :pendind_friend="user.friendship_pending_with_me"
          :pending_invitation="user.invitation_pending_with_me"
          :distance="user.distance"
          :age="user.age"
          :gender="user.gender"
          :uid="user.id"></vlove-small-profile-sheet>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else grid-list-sm fluid>
      <v-row class="text-center">
        <v-col>
          <v-img class="mt-2" :height="200" src="@/assets/logo-vinealove-noresult.png" aspect-ratio="1" contain ></v-img>
          <h4>{{ $t('partials.viewedme.no_results_title') }}</h4><br/>
          <p>{{ $t('partials.viewedme.no_results_desc') }}</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vuex from 'vuex'
import VLoveSmallSheet from '@/components/partial/SmallProfileSheet'

export default {
  name: 'vlove-viewed-me',
  components: {
    'vlove-small-profile-sheet': VLoveSmallSheet
  },
  computed: {
    ...Vuex.mapGetters({
      'viewed': 'VISITED_ME_LIST',
      'viewed_pagination': 'VISITED_ME_PAGINATION'
    })
  },
  data () {
    return {
      current_page_viewed: 1
    }
  },
  mounted: function () {
    this.$store.dispatch('RELOAD_CURRENT_VISITED_ME')
  },
  filters: {
    filterProfilePic: function (_medias) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return null
    }
  },
  watch: {
    current_page_viewed: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_VISITED_ME', {'page': val})
    }
  },
  methods: {
    reloadList () {
      this.$store.dispatch('RELOAD_CURRENT_VISITED_ME')
    },
    nextPage () {
      this.$store.dispatch('LOAD_NEXT_VISITED_ME')
    },
    previousPage () {
      this.$store.dispatch('LOAD_PREVIOUS_VISITED_ME')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
