export default {
  getValues (_translator) {
    return {
      'tasting': _translator.t('user.hobbies.tasting'),
      'trips': _translator.t('user.hobbies.trips'),
      'winetourism': _translator.t('user.hobbies.winetourism'),
      'reading': _translator.t('user.hobbies.reading'),
      'cinema': _translator.t('user.hobbies.cinema'),
      'theater': _translator.t('user.hobbies.theater'),
      'dance': _translator.t('user.hobbies.dance'),
      'music': _translator.t('user.hobbies.music'),
      'sport': _translator.t('user.hobbies.sport'),
      'tvseries': _translator.t('user.hobbies.tvseries'),
      'politics': _translator.t('user.hobbies.politics'),
      'economy': _translator.t('user.hobbies.economy'),
      'technology': _translator.t('user.hobbies.technology'),
      'farniente': _translator.t('user.hobbies.farniente'),
      'outings': _translator.t('user.hobbies.outings'),
      'friends': _translator.t('user.hobbies.friends'),
      'gastronomy': _translator.t('user.hobbies.gastronomy'),
      'videogames': _translator.t('user.hobbies.videogames')
    }
  }
}
