import axios from 'axios'

var base_url = process.env.VUE_APP_API_BASE_URL

export default {

  get_request: async (context, uri, token, params = null) => {
    try {
      var req_headers = {}
      if (token && token.length > 0) {
        req_headers['Authorization'] = 'Bearer ' + token
      }
      var req_params = {}
      if (params) {
        req_params = params
      }
      var response = await axios.create({baseURL: base_url}).get(uri, {
        headers: req_headers,
        params: req_params
      })
      return response.data
    } catch (error) {
      if (error.request.status === 401) {
        context.dispatch('USER_LOGOUT')
      }
      if (error.request.status === 402) {
        context.dispatch('NEED_TO_PAY')
      }
      throw error
    }
  },

  post_request: async (context, uri, token, data, params = null) => {
    try {
      var req_headers = {}
      if (token && token.length > 0) {
        req_headers['Authorization'] = 'Bearer ' + token
      }
      var req_params = {}
      if (params) {
        req_params = params
      }
      var response = await axios.create({baseURL: base_url}).post(uri, data, {
        responseType: 'json',
        headers: req_headers,
        params: req_params
      })
      return response.data
    } catch (error) {
      if (error.request.status === 401) {
        context.dispatch('USER_LOGOUT')
      }
      if (error.request.status === 402) {
        context.dispatch('NEED_TO_PAY')
      }
      throw error
    }
  },

  delete_request: async (context, uri, token, data, params = null) => {
    try {
      var req_headers = {}
      if (token && token.length > 0) {
        req_headers['Authorization'] = 'Bearer ' + token
      }
      var req_params = {}
      if (params) {
        req_params = params
      }
      var response = await axios.create({baseURL: base_url}).delete(uri, data, {
        responseType: 'json',
        headers: req_headers,
        params: req_params
      })
      return response.data
    } catch (error) {
      if (error.request.status === 401) {
        context.dispatch('USER_LOGOUT')
      }
      if (error.request.status === 402) {
        context.dispatch('NEED_TO_PAY')
      }
      throw error
    }
  },

  basic_post_request: async (context, uri, token, formdata) => {
    try {
      var req_headers = {}
      if (token && token.length > 0) {
        req_headers['Authorization'] = 'Bearer ' + token
      }
      var response = await axios.create({baseURL: base_url}).post(uri, formdata, {
        responseType: 'json',
        headers: req_headers
      })
      return response.data
    } catch (error) {
      if (error.request.status === 401) {
        context.dispatch('USER_LOGOUT')
      }
      if (error.request.status === 402) {
        context.dispatch('NEED_TO_PAY')
      }
      throw error
    }
  }

}
