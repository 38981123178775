
import api from '@/api/api'

export default {
  GLOBAL_NOTIFY: (context, payload) => {
    var notification_body = {
      title: 'alert',
      body: ''
    }
    if (payload.title) {
      notification_body.title = payload.title
    }
    context.commit('GLOBAL_ADD_NOTIFICATION', notification_body)
  },
  GLOBAL_REMOVE_LAST_NOTIFICATION: (context) => {
    context.commit('GLOBAL_SHIFT_ONCE_NOTIFICATION')
  },
  GLOBAL_REPORT_MESSAGE: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var reportdata = {}
    if (payload && payload.description !== undefined) {
      reportdata['description'] = payload.description
    }
    try {
      if (payload && payload.type !== undefined && payload.id !== undefined && ['message', 'chat', 'user'].indexOf(payload.type) !== -1) {
        await api.post_request(context, '/api/report/' + payload.type +'/' + payload.id, context.rootState.user.token, reportdata)
        // var response_data = 
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'Report sended, an administrator will check the problem', body: '', type: 'success' })
      }
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: 'You cannot load liked users', body: 'error : ' + error.message, type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  GLOBAL_SHOW_WELCOME: (context) => {
    context.commit('GLOBAL_SHOW_WELCOME')
  },
  GLOBAL_HIDE_WELCOME: (context) => {
    context.commit('GLOBAL_HIDE_WELCOME')
  },
  GLOBAL_SHOW_CGU: (context) => {
    context.commit('GLOBAL_SHOW_CGU')
  },
  GLOBAL_HIDE_CGU: (context) => {
    context.commit('GLOBAL_HIDE_CGU')
  },
  GLOBAL_SHOW_PRIVACY: (context) => {
    context.commit('GLOBAL_SHOW_PRIVACY')
  },
  GLOBAL_HIDE_PRIVACY: (context) => {
    context.commit('GLOBAL_HIDE_PRIVACY')
  }
}
