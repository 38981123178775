<template>
  <div class="agenda">
    <v-container fluid>
      <h1 class="display-2">{{ $t('views.calendar.title') }}</h1>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menumin"
            v-model="menumin"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date_min"
                label="Date minimum"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_min" no-title scrollable @input="menumin = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menumax"
            v-model="menumax"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date_max"
                label="Date maximum"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_max" no-title scrollable  @input="menumax = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" v-for="(value, index) in CALENDAR_EVENTS" :key="index">
          <v-card
            color="primary"
            dark
          >
            <v-card-title class="headline">{{ value.name }}</v-card-title>
            <v-card-subtitle>{{ $t('views.calendar.date') }} {{ value.date_start | formatDateEvent }} - {{ $t('views.calendar.end') }} {{ value.date_end | formatDateEnd }}</v-card-subtitle>
            <v-card-text>
              <p v-html="value.description.replace(/\n/g, '<br />')"></p>
              <p v-if="value.location">{{ $t('views.calendar.location') }} {{ value.location }} <span v-if="value.latitude && value.longitude" >({{ value.latitude }},{{ value.longitude }})</span></p>
            </v-card-text>
            <v-card-actions>
              <v-btn v-if="value.facebook_link" :href="value.facebook_link" target="_blank" text><v-icon>fab fa-facebook-f</v-icon></v-btn>
              <v-btn v-if="value.twitter_link" :href="value.twitter_link" target="_blank" text><v-icon>fab fa-twitter</v-icon></v-btn>
              <v-btn v-if="value.location && value.latitude && value.longitude" :href="(isMobile ? 'geo:' : ('https://www.google.com/maps/search/?api=1&query=' + encodeURI(value.location) + '&center=')) + value.latitude + ',' + value.longitude" target="_blank" text><v-icon>fas fa-map-marked-alt</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vuex from 'vuex'
import moment from 'moment'

export default {
  name: 'agenda',
  computed: {
    ...Vuex.mapGetters([
      'CALENDAR_EVENTS',
      'CALENDAR_DATE_MIN',
      'CALENDAR_DATE_MAX'
    ])
  },
  data () {
    return {
      date_min: null,
      date_max: null,
      menumax: false,
      menumin: false,
      isMobile: false
    }
  },
  watch: {
    date_min: function (val) {
      if (moment(val).format('YYYY-MM-DD') !== moment(this.CALENDAR_DATE_MIN).format('YYYY-MM-DD')) {
        this.$store.dispatch('RELOAD_CURRENT_EVENTS', {'date_min': val, 'date_max': this.date_max, 'lang': this.$i18n.locale })
      }
    },
    date_max: function (val) {
      if (moment(val).format('YYYY-MM-DD') !== moment(this.CALENDAR_DATE_MAX).format('YYYY-MM-DD')) {
        this.$store.dispatch('RELOAD_CURRENT_EVENTS', {'date_min': this.date_min, 'date_max': val, 'lang': this.$i18n.locale })
      }
    },
    CALENDAR_DATE_MIN: function (val) {
      this.date_min = moment(val).format('YYYY-MM-DD')
    },
    CALENDAR_DATE_MAX: function (val) {
      this.date_max = moment(val).format('YYYY-MM-DD')
    },
    "$i18n.locale": function (val, oldVal) {
      if (val !== oldVal && oldVal !== null) {
        this.$store.dispatch('RELOAD_CURRENT_EVENTS', {'date_min': this.date_min, 'date_max': this.date_max, 'lang': val })
      }
    }
  },
  mounted: function () {
    this.date_min = moment().format('YYYY-MM-DD')
    this.date_max = moment().add(3, 'months').format('YYYY-MM-DD')
    this.isMobile = window.cordova && window.device && window.device.platform !== 'browser'
    if (this.CALENDAR_DATE_MIN) {
      this.date_min = moment(this.CALENDAR_DATE_MIN).format('YYYY-MM-DD')
    }
    if (this.CALENDAR_DATE_MAX) {
      this.date_max = moment(this.CALENDAR_DATE_MAX).format('YYYY-MM-DD')
    }
    this.$store.dispatch('RELOAD_CURRENT_EVENTS', {'date_min': this.date_min, 'date_max': this.date_max, 'lang': this.$i18n.locale })
  },
  filters: {
    formatDateEvent: function (_date) {
      let date = moment(_date, 'YYYY-MM-DD[T]HH:mm:ssZ')
      if (date.isBefore(moment().subtract(1, 'days'))) {
        return date.format('LLL')
      }
      return date.format('LLL') + ' (' + date.fromNow() + ')'
    },
    formatDateEnd: function (_date) {
      let date = moment(_date, 'YYYY-MM-DD[T]HH:mm:ssZ')
      if (date.isBefore(moment().subtract(1, 'days'))) {
        return date.format('LLL')
      }
      return date.format('LLL')
    },
    formatDurationEvent: function (_date, _endDate) {
      let date = moment(_date, 'YYYY-MM-DD[T]HH:mm:ssZ')
      let endDate = moment(_endDate, 'YYYY-MM-DD[T]HH:mm:ssZ')
      return moment.duration(endDate.diff(date)).humanize()
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
