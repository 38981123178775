<template>
  <div class="vlove-friendlist">
    <v-tabs>
      <v-tab>{{ $t('partials.friendslist.pending_invitations') }}</v-tab>
      <v-tab>{{ $t('partials.friendslist.sended_invitations') }}</v-tab>
      <v-tab-item>
        <v-list two-line>
          <template v-for="(item, index) in WAITING_LIST" :keys="index">
            <v-divider v-if="index > 0" :inset="true" :key="'_' + index" ></v-divider>
            <v-list-item :key="index">
              <v-list-item-avatar>
                <img :src="item.user.medias | filterProfilePic(item.user.gender)">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.user.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.user.mood }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn fab @click="acceptInvitation(item.user.id)"><v-icon>fa-check-circle</v-icon></v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn fab @click="refuseInvitation(item.user.id)"><v-icon>fa-times-circle</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <div class="text-center">
          <v-pagination v-if="WAITING_PAGINATION && typeof WAITING_PAGINATION.total !== 'undefined'"
            v-model="current_page_waiting"
            :length="parseInt(Math.floor(WAITING_PAGINATION.total / 10)) + 1"
            :total-visible="Math.min(WAITING_PAGINATION.total , 7)"
            circle
          ></v-pagination>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-list two-line>
          <template v-for="(item, index) in ASKED_LIST" :keys="index">
            <v-divider v-if="index > 0" :inset="true" :key="'_' + index" ></v-divider>
            <v-list-item :key="index">
              <v-list-item-avatar>
                <img :src="item.user.medias | filterProfilePic(item.user.gender)">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.user.username }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.user.mood }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn fab @click="cancelInvitation(item.user.id)"><v-icon>fa-times-circle</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
        <div class="text-center">
          <v-pagination v-if="ASKED_PAGINATION && typeof ASKED_PAGINATION.total !== 'undefined'"
            v-model="current_page_asked"
            :length="parseInt(Math.floor(ASKED_PAGINATION.total / 10)) + 1"
            :total-visible="Math.max(Math.min(ASKED_PAGINATION.total , 7), 0)"
            circle
          ></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'vlove-friendlist',
  data () {
    return {
      current_page_waiting: 1,
      current_page_asked: 1
    }
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'IS_LOGGED_IN',
      'FRIENDS_LIST',
      'FRIENDS_PAGINATION',
      'WAITING_LIST',
      'WAITING_PAGINATION',
      'ASKED_LIST',
      'ASKED_PAGINATION'
    ])
  },
  mounted: function () {
    this.$store.dispatch('RELOAD_CURRENT_INVITATIONS')
    this.$store.dispatch('RELOAD_CURRENT_INVITATIONS_SEND')
  },
  watch: {
    current_page_waiting: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_INVITATIONS', {'page': val})
    },
    current_page_asked: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_INVITATIONS_SEND', {'page': val})
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    }
  },
  methods: {
    acceptInvitation: async function (uid) {
      const refThis = this
      await refThis.$store.dispatch('ACCEPT_FRIENDSHIP', {'uid': uid})
      await refThis.$store.dispatch('RELOAD_CURRENT_INVITATIONS')
    },
    refuseInvitation: async function (uid) {
      const refThis = this
      await refThis.$store.dispatch('REFUSE_FRIENDSHIP', {'uid': uid})
      await refThis.$store.dispatch('RELOAD_CURRENT_INVITATIONS')
    },
    cancelInvitation: async function (uid) {
      const refThis = this
      await refThis.$store.dispatch('REMOVE_FRIENDSHIP', {'uid': uid})
      await refThis.$store.dispatch('RELOAD_CURRENT_INVITATIONS_SEND')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
