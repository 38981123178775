export default {
  getValues (_translator) {
    return {
      'pinot_noir': _translator.t('user.winevarieties.pinot_noir'),
      'chardonnay': _translator.t('user.winevarieties.chardonnay'),
      'cabernet_sauvignon': _translator.t('user.winevarieties.cabernet_sauvignon'),
      'merlot': _translator.t('user.winevarieties.merlot'),
      'syrah': _translator.t('user.winevarieties.syrah'),
      'grenache': _translator.t('user.winevarieties.grenache'),
      'sauvignon_blanc': _translator.t('user.winevarieties.sauvignon_blanc'),
      'mourvedre': _translator.t('user.winevarieties.mourvedre'),
      'carignan': _translator.t('user.winevarieties.carignan'),
      'riesling': _translator.t('user.winevarieties.riesling'),
      'cabernet_franc': _translator.t('user.winevarieties.cabernet_franc'),
      'gewurztraminer': _translator.t('user.winevarieties.gewurztraminer'),
      'melon_blanc': _translator.t('user.winevarieties.melon_blanc'),
      'viognier': _translator.t('user.winevarieties.viognier'),
      'marsanne': _translator.t('user.winevarieties.marsanne'),
      'roussane': _translator.t('user.winevarieties.roussane'),
      'muscat': _translator.t('user.winevarieties.muscat'),
      'ugni_blanc': _translator.t('user.winevarieties.ugni_blanc'),
      'malbec': _translator.t('user.winevarieties.malbec'),
      'others': _translator.t('user.winevarieties.others')
    }
  }
}
