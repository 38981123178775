<template>
  <div class="profil">
    <v-container fluid>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'main'">
          <v-row>
            <v-col>
              <v-card>
                <v-img
                  aspect-ratio="1"
                  contain
                  height="80vh"
                    :src="VISITING_U_MEDIAS | filterProfilePic(VISITING_U_GENDER)"
                    gradient="to bottom left, rgba(100,115,201,.10), rgba(25,32,72,.9)"
                  >
                  <v-layout
                    column
                    fill-height
                  >
                    <v-card-title>
                      <vlove-cheer-button :uid="uid" :cheers="cheers" v-on:need-reload="reloadVisited()" ></vlove-cheer-button>
                      <v-spacer></v-spacer>
                      <vlove-friendship-button :uid="uid" :friend="friend" :pendind_friend="pendind_friend" :pending_invitation="pending_invitation" v-on:need-reload="reloadVisited()"></vlove-friendship-button>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-title class="white--text pl-5 pt-5" primary-title>
                      <v-icon v-if="VISITING_U_GENDER === 'woman'" size="35" class="pl-5 pt-2">fa-female</v-icon>
                      <v-icon v-else-if="VISITING_U_GENDER === 'man'" size="35" class="pl-5 pt-2">fa-male</v-icon>
                      <v-icon v-else size="35" class="pl-5 pt-2">fa-venus-mars</v-icon>
                      <div class="display-1 pl-3 pt-5"> {{ VISITING_U_USERNAME }}
                        <span v-if="VISITING_U_OLD_AGE"> - {{ $t('views.profile.label_mainage', {'age': VISITING_U_OLD_AGE}) }}</span>
                        <span v-if="VISITING_U_DISTANCE"> - {{ $t('views.profile.label_maindistance', {'distance': VISITING_U_DISTANCE, 'unit': 'km' }) }}</span>
                        <br/>
                        <div class="subheading">{{ VISITING_U_MOOD }}</div>
                      </div>

                      <v-spacer></v-spacer>
                      <v-btn fab dark icon v-on:click="tab = 'infos'">
                        <v-icon dark>fa-info</v-icon>
                      </v-btn>
                      <v-btn fab dark icon v-on:click="tab = 'chat'">
                        <v-icon dark>fa-comment</v-icon>
                      </v-btn>
                    </v-card-title>
                  </v-layout>
                </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-tab-item>
    <v-tab-item :value="'infos'">
      <v-btn v-on:click="tab = 'main'" text >
        <v-icon>chevron_left</v-icon>
        <v-icon>fa-user</v-icon>
      </v-btn>
      <v-btn  v-on:click="tab = 'chat'" text >
        <v-icon>fa-comment</v-icon><br/>
        <v-icon>chevron_right</v-icon>
      </v-btn>
      <vlove-profile-informations
        :medias="VISITING_U_MEDIAS"
        :name="VISITING_U_USERNAME"
        :age="VISITING_U_OLD_AGE"
        :moods="VISITING_U_MOOD"
        :description="VISITING_U_DESCRIPTION"
        :gender="VISITING_U_GENDER"
        :looking_for="VISITING_U_LOOKING_FOR"
        :size="VISITING_U_SIZE"
        :weight="VISITING_U_WEIGHT"
        :hair="VISITING_U_HAIR_COLOR"
        :eyes="VISITING_U_EYES_COLOR"
        :silhouette="VISITING_U_SILHOUETTE"
        :userstyle="VISITING_U_STYLE"
        :city="VISITING_U_CITY"
        :country="VISITING_U_COUNTRY"
        :distance="VISITING_U_DISTANCE"
        :hobbies="VISITING_U_HOBBIES"
        :wine_colors="VISITING_U_WINE_COLORS"
        :wine_regions="VISITING_U_WINE_REGIONS"
        :wine_varieties="VISITING_U_WINE_VARIETIES"
        :professional="VISITING_U_IS_A_WINE_PROFESSIONNAL"
        :love_relations="VISITING_U_IS_LOOKING_FOR_LOVE_RELATIONS"
        :pro_relations="VISITING_U_IS_LOOKING_FOR_PRO_RELATIONS"
        :friendship_relations="VISITING_U_IS_LOOKING_FOR_FRIEND_RELATIONS"
      ></vlove-profile-informations>
    </v-tab-item>
    <v-tab-item :value="'chat'">
        <v-container fluid>
          <v-card>
            <v-card-title>
              <v-btn   v-on:click="tab = 'infos'" text>
                <v-icon>chevron_left</v-icon>
                <v-icon dark>fa-info</v-icon>
              </v-btn>
              <v-btn   v-on:click="tab = 'main'" text>
                <v-icon>fa-user</v-icon>
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <vlove-chat v-if="VISITING_U_USERNAME"></vlove-chat>
            </v-card-text>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  </div>
</template>

<script>
import Vuex from 'vuex'
import VLoveChat from '@/components/partial/Chat'
import VLoveProfileSheet from '@/components/partial/ProfileSheet'
import VLoveCheerButton from '@/components/partial/CheerButton'
import VLoveFriendButton from '@/components/partial/FriendButton'

export default {
  name: 'profil',
  components: {
    'vlove-chat': VLoveChat,
    'vlove-profile-informations': VLoveProfileSheet,
    'vlove-cheer-button': VLoveCheerButton,
    'vlove-friendship-button': VLoveFriendButton
  },
  computed: {
    ...Vuex.mapGetters([
      'VISITING_U_ID',
      'VISITING_U_USERNAME',
      'VISITING_U_GENDER',
      'VISITING_U_OLD_AGE',
      'VISITING_U_SIZE',
      'VISITING_U_WEIGHT',
      'VISITING_U_LOOKING_FOR',
      'VISITING_U_HAIR_COLOR',
      'VISITING_U_EYES_COLOR',
      'VISITING_U_SILHOUETTE',
      'VISITING_U_STYLE',
      'VISITING_U_HOBBIES',
      'VISITING_U_CITY',
      'VISITING_U_COUNTRY',
      'VISITING_U_MOOD',
      'VISITING_U_DESCRIPTION',
      'VISITING_U_MEDIAS',
      'VISITING_U_LIKED',
      'VISITING_U_DISTANCE',
      'VISITING_U_FRIEND',
      'VISITING_U_FRIEND_PENDING',
      'VISITING_U_FRIEND_INVITATION_PENDING',
      'VISITING_U_WINE_COLORS',
      'VISITING_U_WINE_REGIONS',
      'VISITING_U_WINE_VARIETIES',
      'VISITING_U_IS_A_WINE_PROFESSIONNAL',
      'VISITING_U_IS_LOOKING_FOR_LOVE_RELATIONS',
      'VISITING_U_IS_LOOKING_FOR_PRO_RELATIONS',
      'VISITING_U_IS_LOOKING_FOR_FRIEND_RELATIONS'
    ])
  },
  data () {
    return {
      tab: 'main',
      cheers: false,
      nosync: false,
      friend: false,
      hover_friend: false,
      pendind_friend: null,
      pending_invitation: null,
      uid: null
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    filterOthersPic: function (_medias) {
      if (_medias && _medias.length > 0) {
        return _medias.filter((val) => !val.profile)
      }
      return []
    }
  },
  watch: {
    VISITING_U_LIKED: function (val) {
      this.nosync = true
      this.cheers = val
      var refthis = this
      setTimeout(function () {
        refthis.nosync = false
      }, 500)
    },
    VISITING_U_FRIEND: function (val) {
      this.friend = val
    },
    VISITING_U_FRIEND_PENDING: function (val) {
      this.pendind_friend = val
    },
    VISITING_U_FRIEND_INVITATION_PENDING: function (val) {
      this.pending_invitation = val
    },
     '$route': 'reloadVisited'
  },
  mounted: function () {
    this.reloadVisited()
  },
  methods: { 
    reloadVisited: function () {
      this.$store.dispatch('VISIT_AND_LOAD_PROFILE', {'uid': this.$route.query.uid})
      this.uid = this.$route.query.uid
      this.friend = this.VISITING_U_FRIEND
      this.pendind_friend = this.VISITING_U_FRIEND_PENDING
      this.pending_invitation = this.VISITING_U_FRIEND_INVITATION_PENDING
      if (this.$route.query.tab_user && ['main', 'infos', 'chat'].indexOf(this.$route.query.tab_user) !== '-1') {
        this.tab = this.$route.query.tab_user
      }
    },
    askAsFriend: async function () {
      if (!this.friend) {
        await this.$store.dispatch('ASK_AS_FRIEND', {'uid': this.$route.query.uid})
        this.friend = !this.friend
      }
    },
    removeFriend: async function () {
      if (this.friend) {
        await this.$store.dispatch('REMOVE_FRIENDSHIP', {'uid': this.$route.query.uid})
        this.friend = !this.friend
      }
    },
    acceptFriend: async function () {
      if (!this.friend) {
        await this.$store.dispatch('ACCEPT_FRIENDSHIP', {'uid': this.$route.query.uid})
        this.friend = !this.friend
        this.pending_invitation = !this.pending_invitation
      }
    },
    refuseFriend: async function () {
      if (!this.friend) {
        await this.$store.dispatch('REFUSE_FRIENDSHIP', {'uid': this.$route.query.uid})
        this.pending_invitation = !this.pending_invitation
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
