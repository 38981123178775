import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Touch } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Touch
  }
})
// rgb(217, 145, 164);
// rgb(166, 60, 99);
// rgb(242, 225, 216);
// rgb(242, 206, 206);
// rgb(89, 89, 89);
// primary: '#3f51b5',
// secondary: '#b0bec5',
// accent: '#8c9eff',

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#b7214e', // '#D98BA0',
        secondary: '#c75572',
        accent: '#880E4F',
        error: '#D98BA0',
        background: '#FFFFFF'
      },
    },
  },
});
