import api from '@/api/api'
import moment from 'moment'
import i18n from '../../i18n.js'

const state = {
  plans: [],
  stripe_pk: null,
  checkout_session: null,
  subscription: {}
}

const getters = {
  AVAILABLE_PLANS: state => state.plans,
  MAX_PLANS: state => state.plans ? state.plans.reduce ((curval, valn) => (curval === null || curval < (valn.amount/valn.interval_count)) ? valn.amount/valn.interval_count : curval , null ) : 0,
  STRIPE_PUBLIC_KEY: state => state.stripe_pk,
  CHECKOUT_SESSION: state => state.checkout_session,
  CARD_BRAND: state => state.subscription && state.subscription.card_brand ? state.subscription.card_brand : '',
  CARD_LAST4: state => state.subscription && state.subscription.card_last4 ? state.subscription.card_last4 : '',
  EXP_MONTH: state => state.subscription && state.subscription.card_exp_month ? state.subscription.card_exp_month : '',
  EXP_YEAR: state => state.subscription && state.subscription.card_exp_year ? state.subscription.card_exp_year : '',
  CANCELLED: state => state.subscription && state.subscription.cancel_at_period_end ? state.subscription.cancel_at_period_end : false,
  STATUS: state => state.subscription && state.subscription.status ? state.subscription.status : null,
  PERIOD_END: state => state.subscription && state.subscription.current_period_end ? moment(state.subscription.current_period_end).toDate() : null
}

const actions = {
  LOAD_SUBSCRIPTION_INFOS: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/subscription/informations', context.rootState.user.token)
      context.commit('UPDATE_SUBSCRIPTION', response_data)
      if (!context.getters.IS_MOBILE) {
        context.commit('UPDATE_STRIPE_PUBLIC_KEY', response_data.public_token)
      }
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.api_subscription_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  LOAD_AVAILABLE_PLANS: async (context) => {
    context.commit('INIT_PLANS')
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = null;
      if (context.getters.IS_MOBILE) {
        // gestion du paiement avec vuex
        console.log('initialisation des produits')
        // 'com.disleur.disleurapp.abopremiumauto01'
        const abo_keys = context.getters.IS_APPLE ? [
          'com.vinealove.app.subscription.premium6',
          'com.vinealove.app.subscription.premium3',
          'com.vinealove.app.subscription.premium1'
        ] : [
          'com.vinealove.app.subscription.premium.6',
          'com.vinealove.app.subscription.premium.3',
          'com.vinealove.app.subscription.premium.1'
        ];
        let product_data = await window.inAppPurchase.getProducts(abo_keys)
        response_data = {}
        response_data.plans = []
        for (let i = 0; i < product_data.length; i++) {
          response_data.plans.push({
            "id": product_data[i]['productId'],
            "amount": Math.round(parseFloat(product_data[i]['priceAsDecimal']) * 10000) / 100,
            "nickname": product_data[i]['title'],
            "description": product_data[i]['description'],
            "currency": product_data[i]['currency'],
            "product": product_data[i]['productId'],
            "interval": "month",
            "interval_count": parseInt(product_data[i]['productId'].replace('com.vinealove.app.subscription.premium', '').replace('.', ''))
          })
        }
      } else {
        response_data = await api.get_request(context, '/api/plans', context.rootState.user.token)
        context.commit('UPDATE_STRIPE_PUBLIC_KEY', response_data.public_token)
      }
      // var response_data = {
      //   "plans": [
      //       {
      //           "id": "price_1HlbbgHGUyhav3TCrl08lvoe",
      //           "amount": 4999,
      //           "created": 1604933072,
      //           "currency": "eur",
      //           "interval": "month",
      //           "interval_count": 6,
      //           "product": "prod_IMKGSsJULcXuso"
      //       },
      //       {
      //           "id": "price_1HlbbgHGUyhav3TCVC1NKJWm",
      //           "amount": 3499,
      //           "created": 1604933072,
      //           "currency": "eur",
      //           "interval": "month",
      //           "interval_count": 3,
      //           "product": "prod_IMKGSsJULcXuso"
      //       },
      //       {
      //           "id": "price_1HlbbgHGUyhav3TCVYASMQ9X",
      //           "amount": 1499,
      //           "created": 1604933072,
      //           "currency": "eur",
      //           "interval": "month",
      //           "interval_count": 1,
      //           "product": "prod_IMKGSsJULcXuso"
      //       }
      //   ],
      //   "public_token": "pk_test_RzMINIdTAp5U2CAKdUbvxtA400Scus9YOF"
      // };
      context.commit('UPDATE_PLANS', response_data.plans)
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.api_plans_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
  },
  GENERATE_CHECKOUT_SESSION_AND_REDIRECT: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    var to_send = {}
    to_send.plan = payload.plan_id
    to_send.success = window.location.origin + window.location.pathname + '?status=pending'
    to_send.cancel = window.location.origin + window.location.pathname + '?status=canceled'
    try {
      var response_data = await api.post_request(context, '/api/checkout/session', context.rootState.user.token, to_send)
      context.commit('UPDATE_CHECKOUT_SESSION', response_data.checkout_session)
      if (response_data.checkout_session) {
        /* eslint-disable no-undef */
        let stripe = Stripe(context.getters.STRIPE_PUBLIC_KEY)
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response_data.checkout_session
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.session_network_error'), body: i18n.t('notifications.error_body', { error: result.error.message }) })
        })
      }
    } catch (error) {
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.session_global_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
  },
  GENERATE_UPDATE_CHECKOUT_SESSION_AND_REDIRECT: async (context) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    var to_send = {}
    to_send.success = window.location.origin + window.location.pathname + '?status=pending'
    to_send.cancel = window.location.origin + window.location.pathname + '?status=canceled'
    try {
      var response_data = await api.post_request(context, '/api/update-credit-card/session', context.rootState.user.token, to_send)
      context.commit('UPDATE_CHECKOUT_SESSION', response_data.checkout_session)
      if (response_data.checkout_session) {
        /* eslint-disable no-undef */
        let stripe = Stripe(context.getters.STRIPE_PUBLIC_KEY)
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response_data.checkout_session
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.session_network_error'), body: i18n.t('notifications.error_body', { error: result.error.message }) })
        })
      }
    } catch (error) {
      context.commit('GLOBAL_END_BLOCKING_REQUEST')
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.session_global_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
  },
  SUBSCRIBE_INAPP_MOBILE: async (context, payload) => {
    context.commit('GLOBAL_START_BLOCKING_REQUEST')
    let success = false
    // var debugvar = '1'
    var to_send = {}
    if (context.getters.IS_MOBILE) {
      // debugvar = '2'
      try {
        let transaction = await window.inAppPurchase.subscribe(payload.plan_id)
        // debugvar = '3'
        // console.log(transaction)
        // debugvar = '4'
        to_send.plan = payload.plan_id
        to_send.transaction_id = transaction
        // debugvar = '5'
        // send transaction receipt to vinealove server
		if (context.getters.IS_APPLE) {
			await api.post_request(context, '/api/subscription/check/apple', context.rootState.user.token, to_send)
		} else {
			await api.post_request(context, '/api/subscription/check/android', context.rootState.user.token, to_send)
		}
        // debugvar = '6'
        // call Apple Server to verify receipt and validate account
        success = true
      } catch (error) {
        success = false
        // context.commit('GLOBAL_ADD_NOTIFICATION', { title:  debugvar + ' ' + JSON.stringify(to_send), body: error.message + ' ' + error.name })
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.session_global_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
      }
    }
    context.commit('GLOBAL_END_BLOCKING_REQUEST')
    return success
  },
  CANCEL_SUBSCRIPTION: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/subscription/cancel', context.rootState.user.token)
      if (response_data['success']) {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.cancel_ok'), body: i18n.t('notifications.payment.cancel_ok_body') })
      }
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.cancel_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
    this.LOAD_SUBSCRIPTION_INFOS(context)
  },
  REACTIVATE_SUBSCRIPTION: async (context) => {
    context.commit('GLOBAL_START_REQUEST')
    try {
      var response_data = await api.get_request(context, '/api/subscription/reactivate', context.rootState.user.token)
      if (response_data['success']) {
        context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.reactivate_ok'), body: i18n.t('notifications.payment.reactivate_ok_body') })
      }
    } catch (error) {
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.payment.reactivate_error'), body: i18n.t('notifications.error_body', { error: error.message }) })
    }
    context.commit('GLOBAL_END_REQUEST')
    this.LOAD_SUBSCRIPTION_INFOS(context)
  }
}

const mutations = {
  INIT_PLANS: (state) => {
    state.plans = []
  },
  UPDATE_SUBSCRIPTION: (state, payload) => {
    state.subscription = payload
  },
  UPDATE_PLANS: (state, payload) => {
    state.plans = payload
  },
  UPDATE_STRIPE_PUBLIC_KEY: (state, payload) => {
    state.stripe_pk = payload
  },
  UPDATE_CHECKOUT_SESSION: (state, payload) => {
    state.checkout_session = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

