export default {
  getValues (_translator) {
    return {
      'blond': _translator.t('user.haircolors.blond'),
      'brown': _translator.t('user.haircolors.brown'),
      'red': _translator.t('user.haircolors.red'),
      'black': _translator.t('user.haircolors.black'),
      'bald': _translator.t('user.haircolors.bald')
    }
  }
}
