<template>
  <div class="settings">
    <v-container fluid>
      <h1 class="text-h2 mb-2">{{ $t('views.settings.title') }}</h1>
      <v-card>
        <v-card-title>
        <h2 class="text-h4">{{ $t('views.settings.title_part_global') }}</h2>
        </v-card-title>
        <v-card-text>
          <v-form ref="settingsform" v-model="validsettings" v-on:input="onInputSettings">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :error-messages="errors.email"
                :label="$t('views.settings.label_email')"
                required
              ></v-text-field>
              <v-text-field
                v-model="musername"
                :rules="usernameRules"
                :error-messages="errors.username"
                :label="$t('views.settings.label_username')"
                required
              ></v-text-field>
              <v-text-field
                v-model="mfirstname"
                :error-messages="errors.firstname"
                :label="$t('views.settings.label_firstname')"
                required
              ></v-text-field>
              <v-text-field
                v-model="mlastname"
                :error-messages="errors.lastname"
                :label="$t('views.settings.label_lastname')"
                required
              ></v-text-field>
              <v-btn
                color="success"
                class="mr-4"
                @click="saveInformations"
              >
                {{ $t('views.settings.label_global_validbutton') }}
              </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
        <h2 class="text-h4">{{ $t('views.settings.title_part_password') }}</h2>
        </v-card-title>
        <v-card-text>
          <v-form ref="passwordsform" v-model="validpwd" v-on:input="onInputPwd">
              <v-text-field
                v-model="oldpassword"
                :append-icon="show0 ? 'visibility_off' : 'visibility'"
                :rules="passwordRules"
                :type="show1 ? 'text' : 'password'"
                :label="$t('views.settings.label_old_password')"
                :error-messages="errors.oldPassword"
                @click:append="show0 = !show0"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'visibility_off' : 'visibility'"
                :rules="passwordRules"
                :type="show1 ? 'text' : 'password'"
                :label="$t('views.settings.label_new_password')"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-text-field
                v-model="password2"
                :append-icon="show1 ? 'visibility_off' : 'visibility'"
                :rules="[v => !!v || $t('views.settings.hint_repeatpassword_1'), () => password === password2 || $t('views.settings.hint_repeatpassword_2')]"
                :type="show1 ? 'text' : 'password'"
                :label="$t('views.settings.label_repeatpassword')"
                :error-messages="errors.plainPassword"
                @click:append="show1 = !show1"
                v-on:keyup.enter.exact="register()"
              ></v-text-field>
              <v-btn
                color="success"
                class="mr-4"
                @click="updatePassword"
              >
                {{ $t('views.settings.label_password_validbutton') }}
              </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
        <h2 class="text-h4">{{ $t('views.settings.title_part_plans') }}</h2>
        </v-card-title>
        <v-card-text>
          <div
            v-if="(STATUS !== '' && STATUS !== 'manual')"
          >
            <p>{{ $t('views.settings.current_card_label') }}<br />
            <span v-if="CARD_BRAND && CARD_LAST4">{{ CARD_BRAND }} / **** **** **** {{ CARD_LAST4 }}</span><br/>
            <span v-if="EXP_MONTH && EXP_YEAR">{{ $t('views.settings.expire_on_label') }} {{ EXP_MONTH }}/{{ EXP_YEAR }}</span></p>
            <v-btn
              color="primary"
              class="mr-4"
              @click="updateCreditCard"
            >
              {{ $t('views.settings.label_update_cb') }}
            </v-btn>
          </div>
          <div class="mt-5"
            v-if="(STATUS === 'active' || STATUS === 'trialing') && !CANCELLED">
            <p><em>{{ $t('views.settings.description_cancel_plan') }}</em></p>
            <v-btn
              color="grey"
              class="mr-4"
              @click="cancelSubscription"
            >
              {{ $t('views.settings.label_cancel_plan') }}
            </v-btn>
          </div>
          <div class="mt-5"
            v-if="(STATUS === 'active' || STATUS === 'trialing') && CANCELLED"
          >
            <v-btn
              color="grey"
              class="mr-4"
              @click="reactivateSubscription"
            >
              {{ $t('views.settings.label_reactivate_plan', {'date_expire': PERIOD_END | formatDateMessage}) }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <v-btn text small v-on:click="disclaimerRemove = true">{{ $t('views.settings.remove_account_label') }}</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="disclaimerRemove" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('views.settings.remove_account_title') }}</span>
        </v-card-title>
        <v-card-text>
          {{ $t('views.settings.remove_account_disclaimer') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-on:click.native="disclaimerRemove = !disclaimerRemove">{{ $t('views.settings.remove_account_cancel') }}</v-btn>
          <v-btn color="red darken-1" text @click="removeAccount()">{{ $t('views.settings.remove_account_validbutton') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import Vuex from 'vuex'

export default {
  name: 'settings',
  computed: {
    ...Vuex.mapGetters([
      'username',
      'U_EMAIL',
      'IS_LOGGED_IN',
      'U_FIRSTNAME',
      'U_LASTNAME',
      'CARD_BRAND',
      'CARD_LAST4',
      'EXP_MONTH',
      'EXP_YEAR',
      'CANCELLED',
      'STATUS',
      'PERIOD_END'
    ])
  },
  data () {
    return {
      validsettings: false,
      validpwd: false,
      show0: false,
      show1: false,
      disclaimerRemove: false,
      email: '',
      musername: '',
      mfirstname: '',
      mlastname: '',
      oldpassword: '',
      password: '',
      password2: '',
      emailRules: [
        v => !!v || this.$i18n.t('views.settings.hint_email_1'),
        v => /.+@.+/.test(v) || this.$i18n.t('views.settings.hint_email_2')
      ],
      usernameRules: [
        v => !!v || this.$i18n.t('views.settings.hint_username_1')
      ],
      passwordRules: [
        v => !!v || this.$i18n.t('views.settings.hint_password_1'),
        v => v.length >= 8 || this.$i18n.t('views.settings.hint_password_2')
      ],
      passwordServerErrors: [],
      oldPasswordServerErrors: [],
      errors: {
        email: [],
        username: [],
        firstname: [],
        lastname: [],
        oldPassword: [],
        plainPassword: []
      }
    }
  },
  mounted: function () {
    this.musername = this.username
    this.email = this.U_EMAIL
    this.mfirstname = this.U_FIRSTNAME
    this.mlastname = this.U_LASTNAME
    this.$store.dispatch('LOAD_SUBSCRIPTION_INFOS', {})
  },
  methods: {
    onInputSettings () {
      if (this.$refs.settingsform) {
        this.$refs.settingsform.resetValidation()
      }
    },
    onInputPwd () {
      if (this.$refs.passwordsform) {
        this.$refs.passwordsform.resetValidation()
      }
    },
    async saveInformations () {
      let payload = {}
      payload['username'] = this.musername
      payload['email'] = this.email
      payload['firstname'] = this.mfirstname
      payload['lastname'] = this.mlastname
      try {
        await this.$store.dispatch('USER_SET_SETTINGS', payload)
      } catch (e) {
        if (e.data.errors && e.data.errors.children) {
          for (let key in e.data.errors.children) {
            if (e.data.errors.children[key] && e.data.errors.children[key].errors) {
              this.errors[key] = e.data.errors.children[key].errors
            }
          }
        }
      }
      this.edit_loading = false
    },
    async updatePassword () {
      let payload = {}
      if (this.password !== this.password2) {
        this.errors.password = ['password not matching']
      }
      payload['plainPassword'] = this.password
      payload['oldPassword'] = this.oldpassword
      try {
        await this.$store.dispatch('USER_SET_PASSWORD', payload)
        this.oldpassword = ''
        this.password = ''
        this.password2 = ''
        this.$refs.passwordsform.reset()
        this.$refs.passwordsform.resetValidation()
      } catch (e) {
        if (e.data.errors && e.data.errors.children) {
          for (let key in e.data.errors.children) {
            if (e.data.errors.children[key] && e.data.errors.children[key].errors) {
              this.errors[key] = e.data.errors.children[key].errors
            }
          }
        }
      }
    },
    cancelSubscription () {
      this.$store.dispatch('CANCEL_SUBSCRIPTION', {})
    },
    reactivateSubscription () {
      this.$store.dispatch('REACTIVATE_SUBSCRIPTION', {})
    },
    updateCreditCard: function () {
      this.$store.dispatch('GENERATE_UPDATE_CHECKOUT_SESSION_AND_REDIRECT', {})
    },
    removeAccount: function () {
      this.$store.dispatch('USER_REMOVE_ACCOUNT', {})
    }
  },
  watch: {
    email: function () {
      this.emailServerErrors = []
    },
    musername: function () {
      this.usernameServerErrors = []
    },
    password: function () {
      this.passwordServerErrors = []
    },
    password2: function () {
      this.passwordServerErrors = []
    },
    oldpassword: function () {
      this.oldPasswordServerErrors = []
    }
  },
  filters: {
    formatDateMessage: function (_date) {
      let date = moment(_date)
      if (date.isBefore(moment().subtract(1, 'days'))) {
        return date.format('LLL')
      }
      return date.fromNow()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
