<template>
  <div class="vlove-menu">
    <v-list v-if="IS_LOGGED_IN">
      <v-list-item>
        <v-list-item-avatar :size="100">
          <v-img cover :src="U_MEDIAS | filterProfilePic(U_GENDER)" alt="avatar"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{ username }}</v-list-item-title>
          <v-list-item-subtitle>{{ U_MOOD }}</v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-avatar :size="100">
          <img :size="100" ratio="0.8" src="@/assets/logo-alt.png" />
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item :to="{ name: 'login' }">
        <v-list-item-title>Sign-in</v-list-item-title>
        <v-list-item-action >
          <v-icon :size="35">fa-sign-in-alt</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-list dense v-if="IS_LOGGED_IN" flat>
      <v-divider></v-divider>
      <v-list-item :to="{ name: 'Profil' }">
        <v-list-item-action>
          <v-icon :size="35">face</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.profil') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Friends' }">
        <v-list-item-action>
         <v-icon :size="30">fa-user-friends</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.friends') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'VineaLovers' }">
        <v-list-item-action>
          <v-icon :size="35">search</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.vinealovers') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'Agenda' }">
        <v-list-item-action>
          <v-icon :size="40">fa-calendar-alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.events') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item :to="{ name: 'Matching' }">
        <v-list-item-action>
          <v-icon :size="35">assignment_ind</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.matching') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="dialog_friends_invitation = !dialog_friends_invitation">
        <v-list-item-action>
         <v-icon :size="37">fa-user-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.pending_invitations') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in LAST_INVITATIONS">

        <v-divider
          :inset="false"
          :key="'invit_divider_' + index"
        ></v-divider>

        <v-list-item
          :key="'invit_' + index"
        >
          <router-link :to="{ name: 'User', query: { uid: item.user.id } }" tag="div">
            <v-list-item-avatar>
              <img :src="item.user.medias | filterProfilePic(item.user.gender)">
            </v-list-item-avatar>
          </router-link>
            <v-list-item-content>
              <router-link :to="{ name: 'User', query: { uid: item.user.id } }" tag="span">
                  <v-list-item-title >{{ item.user.username }}</v-list-item-title>
              </router-link>
            </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="pink" @click="acceptInvitation(item.user.id)" ><v-icon>fa-check-circle</v-icon></v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn icon @click="refuseInvitation(item.user.id)" ><v-icon>fa-times-circle</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list-item
          @click="dialog_chat = !dialog_chat"
        >
        <v-list-item-action>
          <v-icon class="ml-1" :size="35">forum</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.last_talks') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <template v-for="(item, index) in LAST_CHATS">

        <v-divider
          :inset="false"
          :key="'chat_divider_' +index"
        ></v-divider>

        <v-list-item
          :key="'chat_' + index"
          :to="{ name: 'User', query: { uid: item.interlocutor.id, 'tab_user': 'chat'} }"
        >
          <div v-if="item.new_message">
            <v-badge
               color="primary"
               left
               overlap
             >
               <v-icon
                 slot="badge"
                 dark
                 small
               >fa-wine-glass-alt</v-icon>
              <v-list-item-avatar>
                <img :src="item.interlocutor.medias | filterProfilePic(item.interlocutor.gender)">
              </v-list-item-avatar>
            </v-badge>
          </div>
          <div v-else>
           <v-list-item-avatar>
             <img :src="item.interlocutor.medias | filterProfilePic(item.interlocutor.gender)">
           </v-list-item-avatar>
          </div>
          <v-list-item-content>
            <v-list-item-title>{{ item.user_init_chat.id === item.interlocutor.id && typeof item.last_message_initiator !== 'undefined' ? item.last_message_initiator.body : (typeof item.last_message_responder !== 'undefined' ? item.last_message_responder.body : '') }}...</v-list-item-title>
            <v-list-item-subtitle>
              <span class='text--primary'>{{ item.interlocutor.username }}</span> &mdash; {{ item.interlocutor.mood }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      
      <v-divider></v-divider>
      <v-subheader>&nbsp;</v-subheader>
      <v-list-item :to="{ name: 'Settings' }" class="grey--text text--darken-1">
        <v-list-item-avatar>
          <v-icon :size="20">fa-cogs</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="grey--text">{{ $t('menu.settings') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{ name: 'logout' }" class="grey--text">
        <v-list-item-avatar>
            <v-icon :size="20">fa-sign-out-alt</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="grey--text">{{ $t('menu.sign_out') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-else>
      <v-list-item :to="{ name: 'Agenda' }">
        <v-list-item-action>
          <v-icon>fa-calendar-alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.events') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="dialog_chat" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_chat = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('menu.chat') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-chatlist v-if="dialog_chat"></vlove-chatlist>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_friends_invitation" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.native="dialog_friends_invitation = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('menu.pending_invitations') }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <vlove-friendslist v-if="dialog_friends_invitation"></vlove-friendslist>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VLoveChatList from '@/components/partial/Chatlist'
import VLoveFriendsList from '@/components/partial/Friendslist'
import Vuex from 'vuex'

export default {
  name: 'vlove-menu',
  components: {
    'vlove-chatlist': VLoveChatList,
    'vlove-friendslist': VLoveFriendsList
  },
  computed: {
    ...Vuex.mapGetters([
      'IS_LOGGED_IN',
      'username',
      'U_GENDER',
      'U_MOOD',
      'U_MEDIAS',
      'USER_HAS_PAYED',
      'LAST_CHATS',
      'LAST_INVITATIONS'
    ])
  },
  data () {
    return {
      dialog_chat: false,
      dialog_friends_invitation: false,
      items: []
    }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    }
  },
  watch: {
    USER_HAS_PAYED: function (val, oldVal) {
      if (val && !oldVal) {
        this.$store.dispatch('LOAD_LAST_CHATS')
        this.$store.dispatch('LOAD_LAST_INVITATIONS')
      }
    },
    IS_LOGGED_IN: function (val, oldVal) {
      if (!val && oldVal) {
        this.$router.push({name: 'logout'})
        if (this.USER_HAS_PAYED) {
          this.$store.dispatch('LOAD_LAST_CHATS')
          this.$store.dispatch('LOAD_LAST_INVITATIONS')
        }
      }
    }
  },
  mounted: function () {
    if (this.IS_LOGGED_IN && this.USER_HAS_PAYED) {
      this.$store.dispatch('LOAD_LAST_CHATS')
      this.$store.dispatch('LOAD_LAST_INVITATIONS')
    }
  },
  methods: {
    acceptInvitation: async function (uid) {
      const refThis = this
      await refThis.$store.dispatch('ACCEPT_FRIENDSHIP', {'uid': uid})
      await refThis.$store.dispatch('LOAD_LAST_INVITATIONS')
    },
    refuseInvitation: async function (uid) {
      const refThis = this
      await refThis.$store.dispatch('REFUSE_FRIENDSHIP', {'uid': uid})
      await refThis.$store.dispatch('LOAD_LAST_INVITATIONS')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-vinealove {
  position: relative;
}
.logo-title {
  position: absolute;
  top: center;
  left: center;
  font-size: 1.7rem;
  line-height: 1.5rem;
  margin-left: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
  -khtml-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
}
.logo-baseline {
  position : absolute;
  bottom: 0;
  font-size: 1.1rem;
  font-family: 'Times New Roman', Times, serif;
  left: center;
}
</style>
