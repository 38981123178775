<template>
  <div class="vlove-matchlist">
    <v-list two-line>
      <template v-for="(item, index) in MATCHS" :keys="index">
        <v-divider v-if="index > 0" :inset="true" :key="'_' + index" ></v-divider>
        <v-list-item v-if="item.user && item.user.username !== username" :to="{ name: 'User', query: { uid: item.user.id, 'tab_user': 'chat' } }" :key="index">
          <v-list-item-avatar>
            <img :src="item.user.medias | filterProfilePic(item.user.gender)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
            <v-list-item-subtitle><span class='text--primary'>{{ item.user.username }}</span> &mdash; {{ item.user.mood }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else-if="item.matched && item.matched.username !== username" :to="{ name: 'User', query: { uid: item.matched.id, 'tab_user': 'chat' } }" :key="index">
          <v-list-item-avatar>
            <img :src="item.matched.medias | filterProfilePic(item.matched.gender)">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
            <v-list-item-subtitle><span class='text--primary'>{{ item.matched.username }}</span> &mdash; {{ item.matched.mood }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-pagination v-if="MATCHS_PAGINATION && typeof MATCHS_PAGINATION.total !== 'undefined'"
      v-model="current_page_matchs"
      :length="parseInt(Math.floor(MATCHS_PAGINATION.total / 10)) + 1"
      :total-visible="Math.min(MATCHS_PAGINATION.total , 7)"
      circle
    ></v-pagination>
  </div>
</template>

<script>
import Vuex from 'vuex'

export default {
  name: 'vlove-matchlist',
  data () {
    return {
      current_page_matchs: 1
    }
  },
  computed: {
    ...Vuex.mapGetters([
      'username',
      'IS_LOGGED_IN',
      'MATCHS',
      'MATCHS_PAGINATION'
    ])
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    }
  },
  mounted: function () {
    this.$store.dispatch('RELOAD_CURRENT_MATCHED_BOTH')
  },
  watch: {
    current_page_matchs: function (val) {
      this.$store.dispatch('RELOAD_CURRENT_MATCHED_BOTH', {'page': val})
    }
  },
  methods: { }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
