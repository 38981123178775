<template>
  <div class="vlove-photos">
    <v-row>
    <v-col xs12 sm12>
      <v-card :flat="small">
        <v-container grid-list-sm fluid>
          <v-row v-if="pictures">
            <v-col
              v-for="(picture, index) in pictures"
              :key="index"
              cols="12" :sm="small ? 6 : 4" :md="small ? 6 : 3"
              class="d-flex child-flex"
            >
              <v-card class="d-inline-block mx-auto">
                <v-container>
                  <v-row justify="space-between">
                    <v-col v-on:click="current_picture = picture.url; dialogphoto = true">
                      <vlove-picture
                        :src="base_url + '/' + picture.url"
                        :lazy-src="base_url + '/' + picture.url"
                        :aspect-ratio="1"
                        class="grey lighten-2"
                        
                      >
                        <v-layout
                          slot="placeholder"
                          fill-height
                          align-center
                          justify-center
                          ma-0
                        >
                          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-layout>
                      </vlove-picture>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="text-center pl-5"
                      v-if="isMine"
                    >
                      <v-row
                        justify="center"
                      >
                        <v-col class="px-0">
                          <v-btn icon v-on:click="removePicture(picture.id)"><v-icon>fa-trash-alt</v-icon></v-btn>
                        </v-col>
                        <v-col class="px-0">
                          <v-btn icon v-on:click="setDefaultPicture(picture.id)">
                            <v-icon :color="picture.profile ? 'green' : ''" >fa-check</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
  <v-dialog v-model="dialogphoto" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.native="dialogphoto = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Photo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <vlove-pic-user-sheet v-if="dialogphoto && current_picture && current_picture.length > 0" :picture="base_url + '/' + current_picture"></vlove-pic-user-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog
        v-model="picdialog"
        max-width="500px"
        scrollable
      >
    <v-card>
      <div v-if="!this.isPictureWithCamera">
        <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
        <v-card-text>
          <v-file-input accept="image/*" @click:clear="removeImage" prepend-icon="mdi-camera" name="mypicture" show-size v-model="fileinput" :rules="rules"></v-file-input>
        </v-card-text>
      </div>
      <div v-else>
        <v-card-title>{{ $t('views.myprofile.label_addpicture') }} <v-progress-circular v-if="preloadpic" indeterminate color="primary"></v-progress-circular></v-card-title>
        <v-card-text>
          <p><v-file-input v-if="!isMobileWithGallery" prepend-icon="mdi-camera" @click:clear="removeImage" accept="image/*" show-size name="mypicture" @change="onFileChange" :rules="rules"></v-file-input></p>
          <p><v-btn v-on:click="getPictureFromCamera" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_camera') }}</v-btn></p>
          <p><v-btn v-if="isMobileWithGallery" v-on:click="getPictureFromGallery" class="primary multi-line" block large>{{ $t('views.myprofile.text_button_gallery') }}</v-btn></p>
        </v-card-text>
      </div>
      <div v-if="newProfilePicture">
        <v-img :src="newProfilePicture" height="125px" contain></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <button @click="removeImage"><v-icon>delete_forever</v-icon></button>
        </v-card-actions>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text v-on:click.native="picdialog = !picdialog">{{ $t('views.myprofile.label_edit_cancel') }}</v-btn>
        <v-btn color="blue darken-1" :disabled="!newProfilePicture" text @click="savePicture()">{{ $t('views.myprofile.label_edit_close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import VLovePicture from '@/components/partial/Picture';
import VLovePicUserSheet from '@/components/partial/PicUserSheet'
export default {
  name: 'vlove-photos',
  components: {
    'vlove-pic-user-sheet': VLovePicUserSheet,
    'vlove-picture': VLovePicture
  },
  props: {
    small: Boolean,
    isMine: Boolean,
    profile_picture: String,
    pictures: Array
  },
  data () {
    return {
      base_url: (process.env.VUE_APP_API_BASE_URL).replace('/index.php', ''),
      dialogphoto: false,
      picdialog: false,
      current_picture: null,
      newProfilePicture: null,
      preloadpic: false,
      isPictureWithCamera: false,
      isMobileWithGallery: false,
      fileinput: null,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ]
    }
  },
  mounted: function () {
    console.log(this.pictures);
  },
  methods: { 
    setDefaultPicture (_id) {
      this.$store.dispatch('USER_SET_DEFAULT_PICTURE', {'pictureid': _id})
    },
    removePicture (_id) {
      this.$store.dispatch('USER_REMOVE_PICTURE', {'pictureid': _id})
    },
    getFileContentAsBase64 (path, callback, callbackError) {
      window.resolveLocalFileSystemURL(path, function (fileEntry) {
        fileEntry.file(function (file) {
          var reader = new FileReader()
          reader.onloadend = function () {
            var content = this.result
            callback(content)
          }
          // The most important point, use the readAsDatURL Method from the file plugin
          reader.readAsDataURL(file)
        })
      }, function () {
        callbackError()
      })
    },
    getPictureFromCamera () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.CAMERA,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE,
          correctOrientation: true
        })
        /* eslint-enable */
      }
    },
    getPictureFromGallery () {
      if (this.isPictureWithCamera) {
        var refthis = this
        this.preloadpic = true
        /* eslint-disable */
        navigator.camera.getPicture(function (imageUrl) {
          console.log('navigator.camera.getPicture success', imageUrl)
          refthis.newProfilePicture = "data:image/jpeg;base64," + imageUrl
          // 'data:image/jpeg;base64,' + imageData
          refthis.preloadpic = false
        }, function (message) {
          console.log('navigator.camera.getPicture galery error', message)
        }, {
          quality: 100,
          sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,
          destinationType: Camera.DestinationType.DATA_URL,
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE
        })
        /* eslint-enable */
      }
    },
    onFileChange: function (e) {
      if (!e) {
        return
      }
      this.preloadpic = true
      var files = e.target ? e.target.files : e.dataTransfer ? e.dataTransfer.files : [e]
      if (!files.length) {
        this.preloadpic = false
        return
      }
      this.createImage(files[0])
    },
    createImage: function (file) {
      // var image = new Image()
      var reader = new FileReader()

      reader.onload = (e) => {
        this.newProfilePicture = e.target.result
      }
      reader.readAsDataURL(file)
      this.preloadpic = false
    },
    removeImage: function () {
      this.newProfilePicture = ''
      this.fileinput = null
    },
    savePicture: function () {
      if (this.newProfilePicture) {
        var payload = {}
        var refthis = this
        if (this.newProfilePicture.indexOf('data:image/') !== -1 && this.newProfilePicture.indexOf(';base64,') !== -1) {
          // Si l'image est en base64 on envoie direct
          payload.picture = this.newProfilePicture
          this.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
            refthis.picdialog = false
            refthis.newProfilePicture = null
            refthis.fileinput = null
          })
        } else if (this.newProfilePicture.indexOf('http://') === -1 && this.announcepicture.indexOf('https://') === -1) {
          // Si l'image est une url interne on converti en base64 et on envoie
          this.getFileContentAsBase64(this.newProfilePicture, function (datafile) {
            payload.picture = datafile
            refthis.$store.dispatch('USER_EDIT_PICTURE', payload).then(() => {
              refthis.picdialog = false
              refthis.newProfilePicture = null
              refthis.fileinput = null
            })
          }, function () {
            // TODO display an internal error
          })
        }
        else {
          // l'image est une url http,
          // do nothing
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
