import api from '@/api/api'
import moment from 'moment'
import i18n from '../../i18n.js' 

const state = {
  events: [],
  date_min: null,
  date_max: null
}

const getters = {
  CALENDAR_EVENTS: state => state.events && state.events.length > 0 ? state.events : [],
  CALENDAR_DATE_MIN: state => state.date_min,
  CALENDAR_DATE_MAX: state => state.date_max
}

const actions = {

  RELOAD_CURRENT_EVENTS: async (context, payload) => {
    context.commit('GLOBAL_START_REQUEST')
    var date_min = context.state.date_min
    var date_max = context.state.date_max
    var lang = 'en'
    if (payload && payload.date_min !== undefined && payload.date_max !== undefined) {
      date_min = payload.date_min
      date_max = payload.date_max
    }
    if (payload && payload.lang !== undefined) {
      lang = payload.lang
    }
    if (date_min === null) {
      date_min = moment().toDate()
    }
    if (date_max === null) {
      date_max = moment().add(3, 'month').toDate()
    }
    try {
      // console.log('load this')
      var response_data = await api.get_request(context, '/api/calendar/events', null, {
        date_min: moment(date_min).format('YYYY-MM-DD HH:mm:ss'),
        date_max: moment(date_max).format('YYYY-MM-DD HH:mm:ss'),
        locale: lang
      })
      var events = []
      for (let k = 0; k < response_data.items.length; k++) {
        events.push(response_data.items[k])
      }
      context.commit('LOAD_EVENTS', { events: events, total: response_data.total, date_min: date_min, date_max: date_max })
    } catch (error) { 
      context.commit('GLOBAL_ADD_NOTIFICATION', { title: i18n.t('notifications.calendar.loading_error'), body: i18n.t('notifications.error_body', { error: error.message }), type: 'error' })
    }
    context.commit('GLOBAL_END_REQUEST')
  }
}

const mutations = {
  // USER_LOGGED_OUT: (state) => {
  // },
  LOAD_EVENTS: (state, payload) => {
    state.events = payload.events
    state.date_min = moment(payload.date_min).toDate()
    state.date_max = moment(payload.date_max).toDate()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
