<template>
  <div class="vlove-profile-informations grey--text text--darken-3">
    <v-container>
        <v-row
        justify="center"
        no-gutters
        >
            <v-col cols="12" md="4" class="text-center">
                <v-avatar
                    center
                    :size="250"
                    aspect-ratio="1"
                    color="grey lighten-4"
                    >
                    <v-img cover :src="medias | filterProfilePic(gender)" alt="avatar"></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="12" md="4">
                <h1 class="text-h4 mb-1 text-capitalize grey--text text--darken-3">{{ name }}, {{ age }}</h1>
                <v-card min-width="100%">
                    <v-card-text>
                        <p v-if="moods" class="text-h6">{{ $t('views.profile.label_mood') }}<span class="primary--text">{{ moods }}</span></p>
                        <p v-if="description" >{{ description }}</p>
                        <p>
                            <em v-if="professional">{{ $t('views.myprofile.label_wineworker_ok') }}</em><em v-else>{{ $t('views.myprofile.label_wineworker_nok') }}</em>
                        </p>
                        <p> {{ $t('views.profile.label_relation') }}
                            <em v-if="love_relations">{{ $t('user.relations.love') }}<span v-if="pro_relations || friendship_relations">, </span></em>
                            <em v-if="pro_relations">{{ $t('user.relations.professionnal') }}<span v-if="friendship_relations">, </span></em>
                            <em v-if="friendship_relations">{{ $t('user.relations.friendship') }}</em>
                            <em v-if="!love_relations && !pro_relations && !friendship_relations">{{ $t('user.relations.unknown') }}</em>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="7">
                <h2 class="primary--text text-h6 mb-1">{{ $t('views.profile.title_profil') }}</h2>
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-list :two-line="false" avatar>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon v-if="gender === 'woman'">fa-female</v-icon>
                                    <v-icon v-else-if="gender === 'man'">fa-male</v-icon>
                                    <v-icon v-else-if="gender === 'both'">fa-transgender</v-icon>
                                    <v-icon v-else>fa-question</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t('user.genders.' + gender) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon v-if="looking_for === 'woman'">fa-female</v-icon>
                                    <v-icon v-else-if="looking_for === 'man'">fa-male</v-icon>
                                    <v-icon v-else-if="looking_for === 'both'">fa-transgender</v-icon>
                                    <v-icon v-else>fa-question</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('user.looking_for_genders.' + looking_for) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item  v-if="age && age !== '-'">
                                <v-list-item-avatar>
                                    <v-icon>fa-birthday-cake</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ age }} <span class="text-caption">{{ $t('views.profile.label_age') }}</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="size && size !== '-'">
                                <v-list-item-avatar>
                                    <v-icon>fa-arrows-alt-v</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ size }} <span class="text-caption">cm</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="weight && weight !== '-'">
                                <v-list-item-avatar>
                                    <v-icon>fa-weight</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ weight }} <span class="text-caption">Kg</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-cut</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('user.haircolors.' + (hair && hair !== '-' ? hair : 'none')) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-eye</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('user.eyescolors.' + (eyes && eyes !== '-' ? eyes : 'none')) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list :two-line="false" avatar>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-running</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('user.silhouettes.' + (silhouette && silhouette !== '-' ? silhouette : 'none')) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-tshirt</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('user.styles.' + (userstyle && userstyle !== '-' ? userstyle : 'none')) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-map-marker-alt</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ city }} <span class="text-uppercase">{{ country }}</span><span v-if="distance" class="text-caption">({{ $t('views.profile.label_distance', {'distance': distance, 'unit': 'km' }) }})</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>fa-grin-hearts</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on" v-for="(value, index) in hobbies" :key="index"><span v-if="index > 0">, </span>{{ $t('user.hobbies.' + value) }}</span>
                                            </template>
                                            <span v-for="(value, index) in hobbies" :key="index"><span v-if="index > 0">, </span>{{ $t('user.hobbies.' + value) }}</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar><img style="width: 70%;" src="@/assets/wine.svg" /></v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span  v-bind="attrs" v-on="on" v-for="(value, index) in wine_colors" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winecolors.' + value) }}</span>
                                            </template>
                                            <span  v-for="(value, index) in wine_colors" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winecolors.' + value) }}</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar><img style="width: 70%;" src="@/assets/pin.svg" /></v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on" v-for="(value, index) in wine_regions" :key="index"><span v-if="index > 0">, </span>{{ $t('user.wineregions.' + value) }}</span>
                                            </template>
                                            <span v-for="(value, index) in wine_regions" :key="index"><span v-if="index > 0">, </span>{{ $t('user.wineregions.' + value) }}</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar><img style="width: 70%;" src="@/assets/grapes.svg" /></v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on" v-for="(value, index) in wine_varieties" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winevarieties.' + value) }}</span>
                                            </template>
                                             <span v-for="(value, index) in wine_varieties" :key="index"><span v-if="index > 0">, </span>{{ $t('user.winevarieties.' + value) }}</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="5">
                <h2 class="primary--text text-h6 mb-1">Photos</h2>
                <v-card flat key="photos">
                  <v-card-text>
                    <vlove-photos :small="true" :pictures="medias | filterOthersPic" :isMine="false"></vlove-photos>
                  </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>
<script>
// import Vuex from 'vuex'
import VLovePhotos from '@/components/partial/Photos'

export default {
  name: 'vlove-profile-informations',
  components: {
    'vlove-photos': VLovePhotos
  },
  props: [
    'medias',
    'name',
    'moods',
    'description',
    'gender',
    'looking_for',
    'size',
    'weight',
    'hair',
    'eyes',
    'age',
    'silhouette',
    'userstyle',
    'city',
    'country',
    'distance',
    'hobbies',
    'wine_colors',
    'wine_regions',
    'wine_varieties',
    // hashtags: Array,
    'cheers',
    'pending',
    'friend',
    'pendind_friend',
    'pending_invitation',
    'uid',
    'hide_action',
    'professional',
    'love_relations',
    'pro_relations',
    'friendship_relations'
  ],
  data () {
      return {
        icon_glass: '@/assets/wine.svg',
        icon_grapes: '@/assets/grapes.svg',
        icon_map: '@/assets/pin.svg'
      }
  },
  filters: {
    filterProfilePic: function (_medias, _gender) {
      if (_medias && _medias.length > 0) {
        var urlobj = _medias.find((val) => val.profile)
        if (urlobj) {
          var base_url = process.env.VUE_APP_API_BASE_URL
          return base_url.replace('/index.php', '') + '/' + urlobj.url
        }
      }
      return (process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.') + (_gender === 'woman' ? '/people-woman.png' : '/people-man.png')
    },
    filterOthersPic: function (_medias) {
      if (_medias && _medias.length > 0) {
        return _medias.filter((val) => !val.profile)
      }
      return []
    }
  },
  mounted: function () {
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>