<template>
  <div class="vlove-small-profile-sheet">
    <v-card
      >
      <v-layout
        column
        align-center
        justify-center
      >
        <router-link :to="{ name: 'User', query: { uid: uid } }" tag="span" >
          <v-card-title>
            <v-spacer></v-spacer>
            <v-avatar
              center
              :size="150"
              color="grey lighten-4"
              class="elevation-2"

            >
              <v-img cover v-if="picture && picture.trim().length > 0" :src="picture" alt="avatar"></v-img>
              <img v-else :src="publicPath + (gender === 'woman' ? '/people-woman.png' : '/people-man.png')" alt="avatar">
            </v-avatar>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>
            <div class="text-center">
              <div><h3 class="title" v-if="name">{{ name }}</h3></div>
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="text-center same-full-height" >
            <div><em><span v-if="age" >{{ $t('views.profile.label_mainage', {'age': age}) }}</span> - <span v-if="distance">{{ distance | filterDistance }}</span></em></div>
            <div><em>{{ moods }}</em></div>
            <div><span v-for="(hashtag, index) in hashtags" :key="index"> {{ hashtag }}</span></div>
          </v-card-text>
        </router-link>
        <v-card-actions v-if="!hide_action">
          <vlove-cheer-button :uid="uid" :cheers="cheers" @need-reload="reloadOnNeededReload()" ml-2></vlove-cheer-button>
          <v-btn fab dark :to="{ name: 'User', query: { uid: uid } }" class="ml-5  mr-5">
            <v-icon dark>fa-user-circle</v-icon>
          </v-btn>
          <vlove-friendship-button :uid="uid" :friend="friend" :pendind_friend="pendind_friend" :pending_invitation="pending_invitation" v-on:need-reload="reloadOnNeededReload()" ml-2></vlove-friendship-button>
        </v-card-actions>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import VLoveCheerButton from '@/components/partial/CheerButton'
import VLoveFriendButton from '@/components/partial/FriendButton'

export default {
  name: 'vlove-small-profile-sheet',
  components: {
    'vlove-cheer-button': VLoveCheerButton,
    'vlove-friendship-button': VLoveFriendButton
  },
  props: [
    'picture',
    'name',
    'moods',
    // hashtags: Array,
    'cheers',
    'pending',
    'gender',
    'friend',
    'pendind_friend',
    'pending_invitation',
    'uid',
    'distance',
    'age',
    'hide_action'
  ],
  watch: {
  },
  data () {
    return {
      publicPath: process.env.VUE_APP_WEB === '1' ? process.env.BASE_URL : '.',
      hashtags: []
    }
  },
  filters: {
    filterDistance: function (_distance) {
      if (_distance && ("" + _distance).length > 0 && _distance !== 99999) {
        return Math.round(_distance) + ' Km'
        // todo conversion en miles suivant locale
      }
      return ''
    }
  },
  mounted: function () {
  },
  methods: {
    reloadOnNeededReload: async function () {
      this.$emit('need-reload')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
same-full-height {
  overflow-y: auto; 
  height:100px;
}
</style>
